import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from 'src/app/validators/validators';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import {
  containsNumbers,
  containsUppercase,
  matchValidator,
} from 'src/app/utils/utils';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
  @Input() onSave: Function;
  @Output() sendValuesForm = new EventEmitter<string>();

  updatePasswordForm: FormGroup;
  hideNewPassword: boolean = true;
  hideRePassword: boolean = true;
  errorUpdatePassword: any = {};

  constructor(private activeModal: NgbActiveModal) {}
  ngOnInit(): void {
    this.createUpdatePasswordForm();
  }

  containsNumbers(str: string) {
    return containsNumbers(str);
  }

  containsUppercase(str: string) {
    return containsUppercase(str);
  }

  createUpdatePasswordForm() {
    this.updatePasswordForm = new FormGroup({
      oldPassword: new FormControl(
        '',
        Validators.compose([Validators.required])
      ),
      password: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.ValidatePassword,
        ])
      ),
      repassword: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.ValidatePassword,
        ])
      ),      
    }, {
      updateOn: 'change'
    });

    this.updatePasswordForm.addValidators(
      matchValidator(
        this.updatePasswordForm.get('password'),
        this.updatePasswordForm.get('repassword')
      )
    );
  }

  closeModal() {
    this.activeModal.close();
  }

  updatePasswordFormSubmit() {
    if (this.updatePasswordForm.valid) {
      this.sendValuesForm.emit(this.updatePasswordForm.value.password);
      this.onSave(
        this.updatePasswordForm.get('oldPassword')?.value,
        this.updatePasswordForm.get('password')?.value
      );
    }
  }
}
