<div *ngIf="data[0]?.series.length > 1;else notEnoughPaidInvoices" >
    <p class="p4 regular gray-dark mb-4">Atualizado com as últimas 12 faturas pagas</p>
    <div #containerInvoiceChartRef class="card invoice-chart-line-container">
        <ngx-charts-line-chart [scheme]="colorScheme" [legend]="legend" [showXAxisLabel]="showXAxisLabel"
            [showYAxisLabel]="showYAxisLabel" [xAxis]="xAxis" [yAxis]="yAxis" [xAxisLabel]="xAxisLabel"
            [showGridLines]="showGridLines" [yAxisLabel]="yAxisLabel" [timeline]="timeline" [results]="data"
            [view]="[containerInvoiceChartRef.offsetWidth - 100, containerInvoiceChartRef.offsetHeight]"
            (select)="onSelect($event)"
            [xAxisTickFormatting]="xAxisTickFormatting" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
            <ng-template #tooltipTemplate let-model="model">
                <div class="tooltip-chart">
                    <p>{{model.value | currency:"BRL":"symbol":"1.2-2"}}</p>
                </div>
            </ng-template>

            <ng-template #seriesTooltipTemplate let-model="model">
                <p class="m-none">{{model[0].value | json | currency:"BRL":"symbol":"1.2-2"}}</p>
            </ng-template>
        </ngx-charts-line-chart>
    </div>
</div>

<ng-template #notEnoughPaidInvoices>
    <div>
        <p class="gray-dark regular p3">Ainda não há o mínimo de faturas pagas (2) para gerar o gráfico.</p>
    </div>
</ng-template>
