import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ExtraButtonType } from '../../../enums/extra-button-type';

@Component({
  selector: 'extra-button',
  templateUrl: './extra-button.component.html',
  styleUrls: ['./extra-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExtraButtonComponent implements OnInit {


  @Input() typeStyle: string = '';
  @Input() label: string = '';
  @Input() icon: string = '../../../../assets/svgs/download-file.svg';
  @Input() iconOrientation: string = 'start' || 'end';
  
  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  extraButtonType = ExtraButtonType


  constructor() { }

  ngOnInit(): void {
  }

  clicked() {
    this.onClick.emit()
  }
}
