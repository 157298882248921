import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { JwtTokenService } from '../services/auth/jwt-token.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public jwtTokenService: JwtTokenService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!request.headers.has('Authorization') && !request.url.startsWith('https://s3')) {
      request = request.clone({
        setHeaders: this.jwtTokenService.getHeaderAuth().headers,
      });
    }
    return next.handle(request);
  }
}
