<div *ngIf="untouch && valid" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">

  <svg-icon class="error-icon" src="../../../assets/svgs/check.svg"></svg-icon>

  <div style="padding-top: 5px;">
    <p class="regular small">
      {{message}}
    </p>
  </div>

</div>

<div *ngIf="(!untouch && !valid) || (!untouch && valid)" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">

  <svg-icon class="error-icon" src="../../../assets/svgs/check-disable.svg"></svg-icon>

  <div class="message-container">
    <p class="regular small">
      {{message}}
    </p>
  </div>

</div>


<div *ngIf="untouch && !valid" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">

  <svg-icon class="error-icon" src="../../../assets/svgs/close.svg"></svg-icon>

  <div style="padding-top: 5px;">
    <p class="regular small">
      {{message}}
    </p>
  </div>

</div>
