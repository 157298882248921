<div class="main-container" scrollPercent="97" immediateCallback="false">
  <table class="table table-bordered" fxLayout="column">
    <thead class="custom-header-bordered" [hidden]="!dataSource?.length && !loading">
      <tr fxLayoutGap="2.4vmin">
        <th fxFlex="36.1">
          <span class="header-title bold p3">{{ headerNames[0] }}</span>
        </th>
        <th fxFlex="14.3">
          <span class="header-title bold p3">{{ headerNames[1] }}</span>
        </th>
        <th fxFlex="14.3">
          <span class="header-title bold p3">{{ headerNames[2] }}</span>
        </th>
        <th fxFlex="14.3">
          <span class="header-title bold p3"> {{ headerNames[3] }} </span>
        </th>
        <th fxFlex="14.3">
          <span class="header-title bold p3"> {{ headerNames[4] }}</span>
        </th>
        <th fxFlex="13.6">
          <span class="header-title bold p3">{{ headerNames[5] }}</span>
        </th>
        <th fxFlex="10">
          <span class="header-title bold p3">{{ headerNames[6] }}</span>
        </th>
        <th fxFlex="10">
          <span class="header-title bold p3">{{ headerNames[7] }}</span>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="dataSource?.length && !loading; else noDataRegisteredYet">
      <tr fxLayoutGap="2.4vmin" *ngFor="let row of dataSource">
        <td fxLayoutAlign="start center" fxFlex="36.1" class="custom-border-left">
          <span class="name-title regular p3">{{ row.name }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="14.3" class="no-side-padding">
          <span class="name-title regular p3">{{ row.cpf }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="14.3" class="no-side-padding">
          <span class="name-title regular p3">{{ row.bond }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="14.3" class="no-side-padding">
          <span class="name-title regular p3">{{ row.plan }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="14.3" class="no-side-padding">
          <span class="name-title regular p3">{{ row.timeJoined }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="13.6" class="custom-border-left">
          <span class="name-title regular p3">{{ row.typeOperation }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="10" class="custom-border-left">
          <span class="name-title regular p3">{{ row.valueBefore }}</span>
        </td>
        <td fxLayoutAlign="start center" fxFlex="10" class="custom-border-left">
          <span class="name-title regular p3">{{ row.valueUpdated }}</span>
        </td>
      </tr>
    </tbody>

    <tbody [hidden]="!loading">
      <tr fxLayoutGap="2.4vmin" *ngFor="let _ of [].constructor(maxRowsLoaded)">
        <td fxFlex="36.1" class="custom-border-left" fxLayout="column">
          <ngx-skeleton-loader [theme]="{
              background:
                'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
              'border-radius': '6px',
              width: '70%',
              height: '68px'
            }"></ngx-skeleton-loader>
        </td>
        <td *ngFor="let _ of [1, 2]" fxFlex="14.3" class="custom-border-left" fxLayout="column">
          <ngx-skeleton-loader [theme]="{
              background:
                'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
              'border-radius': '6px',
              width: '70%',
              height: '68px'
            }"></ngx-skeleton-loader>
        </td>
        <td fxFlex="13.6" class="custom-border-left" fxLayout="column">
          <ngx-skeleton-loader [theme]="{
              background:
                'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
              'border-radius': '6px',
              width: '70%',
              height: '68px'
            }"></ngx-skeleton-loader>
        </td>

        <td *ngFor="let _ of [1, 2]" fxFlex="10" class="custom-border-left" fxLayout="column">
          <ngx-skeleton-loader [theme]="{
              background:
                'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
              'border-radius': '6px',
              width: '70%',
              height: '68px'
            }"></ngx-skeleton-loader>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #noDataRegisteredYet>
  <div *ngIf="!dataSource?.length && !loading">
    <p class="label-no-data-registered regular p3">
      Ainda não existem faturas pagas.
    </p>
  </div>
</ng-template>
