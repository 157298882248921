import { environment } from './../../environments/environment';
import { JwtTokenService } from './auth/jwt-token.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private http: HttpClient, public jwtTokenService: JwtTokenService) { }

  getContactsByCompanyId(companyId: number) {
    return this.http
      .get(`${environment.apiUrl}/api/contacts/company/${companyId}`, this.jwtTokenService.getHeaderAuth())
      .toPromise();
  }

}
