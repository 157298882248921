import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-do-not-have-access-permission',
  templateUrl: './modal-do-not-have-access-permission.component.html',
  styleUrls: ['./modal-do-not-have-access-permission.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDoNotHaveAccessPermissionComponent implements OnInit {
  show: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}
  ngOnInit(): void {}

  toggle() {
    this.show = !this.show;
  }

  onClick() {
    this.activeModal.close();
  }
}
