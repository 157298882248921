import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JwtTokenService } from './auth/jwt-token.service';
import { LogProcess } from '../models/include-spreadsheet';

@Injectable({
  providedIn: 'root',
})
export class ProcessService {
  private readonly PROCESS_URL = environment.apiUrl + '/api/process';

  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) {}

  getLogProcessByCurrentUser(): Promise<LogProcess[]> {
    return lastValueFrom<LogProcess[]>(
      this.http.get<LogProcess[]>(
        `${this.PROCESS_URL}/last-process-info`,
        this.jwtTokenService.getHeaderAuth()
      )
    );
  }
}
