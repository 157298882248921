<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="../../../../assets/svgs/attention-modal-icon.svg"></svg-icon>
</div>
<div class="margin-content company-modal-content" fxLayout="column">
  <form class="company-form-group" [formGroup]="companySearchingFormGroup" fxFlex>
    <div fxLayout="row" fxFlex>
      <search-input fxFlex [timeToDebounce]="1000" label="Pesquisar empresas do grupo" (valueChanges)="listCompanies()"
        formControlName="searchText" placeholder="Digite o nome ou CNPJ da empresa"></search-input>
    </div>
  </form>
  <div fxLayout="column" fxLayoutGap="2vmin">
    <company-switcher-table [dataSource]="companyDataSource" [loading]="isLoading" [maxRowsLoaded]="maxPerPage"
      (onRowClick)="setSelectedItem($event)"></company-switcher-table>
  </div>

  <div class="d-flex justify-content-between p-2">
    <div *ngIf="companyDataSource?.length" fxFlex fxLayoutAlign="end end">
      <default-paginator itemLabel="empresas" [(total)]="totalRows" [pageNumber]="pageNumber"
        (onPageChange)="pageChanged($event)" [maxPerPage]="maxPerPage"></default-paginator>
    </div>
  </div>

  <default-button [disabled]="!this.selectedItem" label="Selecionar empresa" (onClick)="companySelect()">
  </default-button>

</div>