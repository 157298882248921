<table class="table table-bordered" fxLayout="column">
    <thead class="custom-header-bordered" [hidden]="!dataSource.length && !loading">
        <tr>
            <th fxFlex="15" sortable="competence" (sort)="onSort($event)" fxLayout="column" fxLayoutGap="1vmin"
                fxLayoutAlign="start start">
                <span class="header-title bold p3">{{headerNames[0]}}</span>
            </th>
            <th fxFlex="15" class="no-side-padding" sortable="dueDate" (sort)="onSort($event)" fxLayout="column"
                fxLayoutGap="1vmin" fxLayoutAlign="start start">
                <span class="header-title bold p3">{{headerNames[1]}}</span>
            </th>
            <th fxFlex="15" class="no-side-padding" fxLayout="row" sortable="value" (sort)="onSort($event)"
                fxLayoutGap="1vmin" fxLayoutAlign="start start">
                <span class="header-title bold p3">{{headerNames[2]}}</span>
            </th>
            <th class="no-side-padding" fxFlex="25" fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="start start">
                <span class="header-title bold p3"></span>
            </th>
            <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="start start">
                <span class="header-title bold p3"></span>
            </th>
            <th class="no-side-padding" fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="center center">
                <span class="header-title bold p3"></span>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="dataSource.length && !loading;else noDataRegisteredYet">
        <tr *ngFor="let row of dataSource">
            <td class="custom-border-left" fxFlex="15" fxLayoutAlign="start center">
                <span class="name-title semi-bold p3">{{row.competence}}</span>
            </td>
            <td class="no-side-padding" fxFlex="15" fxLayout="row" fxLayoutGap="1.5vmin" fxLayoutAlign="start center">
                <span class="name-title regular p2" [ngClass]="{'payment-due-color bold': tableMode === invoiceHistoriceTableMode.PAYMENT_DUE}">{{tableMode === invoiceHistoriceTableMode.PAID ? row.paidDate : row.dueDate}}</span>
            </td>
            <td class="no-side-padding" fxFlex="15" fxLayout="row" fxLayoutGap="1.5vmin" fxLayoutAlign="start center">
                <span class="name-title regular p2"
                    [ngClass]="{'payment-late-color bold': tableMode === invoiceHistoriceTableMode.PAYMENT_LATE}">{{row.value}}</span>
            </td>
            <td class="no-side-padding" fxFlex="25" fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start center">
                <div *ngIf="tableMode === invoiceHistoriceTableMode.PAYMENT_LATE && row.canDownloadBankSlip" fxLayout="row" fxLayoutGap="1.5vmin" fxLayoutAlign="start center" class="clickable" (click)="getPaymentSlipCopyClick(row.canDownloadBankSlip)">
                    <svg-icon class="invoice-historic-icon" src="../../../../assets/svgs/export-icon.svg"></svg-icon>
                    <span class="regular p3 name-title">Gerar 2ª via do boleto</span>
                </div>

                <div *ngIf="tableMode === invoiceHistoriceTableMode.PAYMENT_DUE && row.canDownloadBankSlip" xLayout="row" fxLayoutGap="1.5vmin" fxLayoutAlign="start center" class="clickable" (click)="createPaymentSlip(row.canDownloadBankSlip)">
                    <svg-icon class="invoice-historic-icon" src="../../../../assets/svgs/download-file.svg"></svg-icon>
                    <span class="regular p3 name-title">Baixar boleto</span>
                </div>
            </td>
            <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start center">
                <div fxLayout="row" *ngIf="row.canShowInvoice" fxLayoutGap="1.5vmin" fxLayoutAlign="start center" class="clickable" (click)="checkInvoice(row.id)">
                    <svg-icon class="invoice-historic-icon" src="../../../../assets/svgs/spreadsheet-icon.svg"></svg-icon>
                    <span class="regular p3 name-title">Ver fatura</span>
                </div>
            </td>
            <td class="no-side-padding custom-border-right" fxFlex fxLayout="row" fxLayoutGap="3.65vmin"
                fxLayoutAlign="center center">
                <div fxLayout="row" *ngIf="row.canDownloadTaxInvoice" fxLayoutGap="1.5vmin" fxLayoutAlign="start center" class="clickable" (click)="downloadNFe(row.canDownloadTaxInvoice)">
                    <svg-icon class="invoice-historic-icon" src="../../../../assets/svgs/download-file.svg"></svg-icon>
                    <span class="regular p3 name-title">Baixar NFSe</span>
                </div>
            </td>
        </tr>
    </tbody>

    <tbody [hidden]="!loading">
        <tr *ngFor="let i of [].constructor(maxRowsLoaded)">
            <td fxFlex="15" class="custom-border-left" fxLayout="column">
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }"></ngx-skeleton-loader>
            </td>
            <td fxFlex="15" class="no-side-padding">
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }">
                </ngx-skeleton-loader>
            </td>
            <td fxFlex="15" class="no-side-padding">
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }">
                </ngx-skeleton-loader>
            </td>
            <td fxFlex="25" class="no-side-padding">

            </td>
            <td fxFlex class="no-side-padding">

            </td>
            <td fxFlex class="custom-border-right">

            </td>
        </tr>
    </tbody>
</table>

<ng-template #noDataRegisteredYet>
    <div *ngIf="!dataSource.length && !loading;">
        <p class="label-no-data-registered regular p3">Ainda não existem faturas pagas.</p>
    </div>
</ng-template>