import { Component, OnInit } from '@angular/core';
import { MixpanelService } from './services/mixpanel.services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [':host { display: block; }']
})
export class AppComponent implements OnInit {

  constructor(private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
    this.mixpanelService.init();
  }

}
