import { Component, EventEmitter, Output, ViewEncapsulation, Input, ChangeDetectionStrategy } from '@angular/core';
import { CollaboratorTableMode } from 'src/app/enums/collaborator-table-mode';
import { CardsTableItem } from 'src/app/models/collaborator-card-table-item.model';

@Component({
  selector: 'tooltip-cancel',
  templateUrl: './tooltip-cancel.component.html',
  styleUrls: ['./tooltip-cancel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent {

  @Input() text: string;
  @Input() actionMode: CollaboratorTableMode
  collaboratorTableMode = CollaboratorTableMode;

}
