<div
  fxLayout="row"
  fxLayoutAlign="space-evenly center"
  class="span-warning-container default-border-radius"
>
  <div class="icon-container">
    <svg-icon src="../../../assets/svgs/warning-icon.svg"></svg-icon>
  </div>
  <p class="regular small span-text">
    <span class="semi-bold">{{ disclaimer }}</span
    >&nbsp;{{ description }}
  </p>
</div>
