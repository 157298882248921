<svg-icon class="close-button" src="../../../../assets/svgs/close-modal-icon.svg"
  (click)="this.closeModal()"></svg-icon>
<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="../../../../assets/svgs/review-icon.svg"></svg-icon>
</div>
<div class="margin-content" *ngIf="type === 'FACE'">
  <p [innerHtml]="title" class="bold text-center"></p>
  <form [formGroup]="reviewEmojiFormGroup" class="description-container">
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div [ngClass]="{'selectedReview': reviewEmojiFormGroup?.get('reviewValue').value === 1}"
        formControlName="reviewValue" (click)="setReviewValue(1)" fxLayout="column" fxLayoutAlign="start center"
        class="face-container">
        <svg-icon src="../../../../assets/svgs/harder-review-face.svg"></svg-icon>
        <span class="face-description regular small">
          Muito mais difícil do que eu imaginava
        </span>
      </div>
      <div [ngClass]="{'selectedReview': reviewEmojiFormGroup?.get('reviewValue').value === 2}"
        formControlName="reviewValue" (click)="setReviewValue(2)" fxLayout="column" fxLayoutAlign="start center"
        class="face-container">
        <svg-icon class="svgIcon" src="../../../../assets/svgs/hard-review-face.svg"></svg-icon>
        <span class="face-description regular small">
          Mais difícil do que eu imaginava
        </span>
      </div>
      <div [ngClass]="{'selectedReview': reviewEmojiFormGroup?.get('reviewValue').value === 3}"
        formControlName="reviewValue" (click)="setReviewValue(3)" fxLayout="column" fxLayoutAlign="start center"
        class="face-container">
        <svg-icon class="svgIcon" src="../../../../assets/svgs/regular-review-face.svg"></svg-icon>
        <span class="face-description regular small">Como esperado</span>
      </div>
      <div [ngClass]="{'selectedReview': reviewEmojiFormGroup?.get('reviewValue').value === 4}"
        formControlName="reviewValue" (click)="setReviewValue(4)" fxLayout="column" fxLayoutAlign="start center"
        class="face-container">
        <svg-icon class="svgIcon" src="../../../../assets/svgs/easy-review-face.svg"></svg-icon>
        <span class="face-description regular small">
          Mais fácil do que eu imaginava
        </span>
      </div>
      <div [ngClass]="{'selectedReview': reviewEmojiFormGroup?.get('reviewValue').value === 5}"
        formControlName="reviewValue" (click)="setReviewValue(5)" fxLayout="column" fxLayoutAlign="start center"
        class="face-container">
        <svg-icon class="svgIcon" src="../../../../assets/svgs/easier-review-face.svg"></svg-icon>
        <span class="face-description regular small">
          Muito mais fácil do que eu imaginava
        </span>
      </div>
    </div>
  </form>
  <default-button (onClick)="nextStep()" [disabled]="reviewEmojiFormGroup?.invalid"
    label="Próximo"></default-button>
</div>

<div class="margin-content" *ngIf="type === 'RADIO_GROUP'">
  <p [innerHtml]="title" class="bold text-center"></p>
  <form [formGroup]="reviewRadioFormGroup" class="description-container">
    <div class="review-container" fxLayout="column">
      <review-radio-group (selectChange)="setReviewRadioValue($event,'reviewValuePaymentSlipDownload')"
        [firstReview]="true" label="Baixar boleto"></review-radio-group>
      <review-radio-group (selectChange)="setReviewRadioValue($event,'reviewValueCheckInvoice')"
        label="Checar fatura"></review-radio-group>
      <review-radio-group (selectChange)="setReviewRadioValue($event,'reviewValueInvoiceHistory')" [lastReview]="true"
        label="Ver histórico de faturas"></review-radio-group>
    </div>
    <default-button (onClick)="nextStep()" [disabled]="reviewRadioFormGroup?.invalid"
      label="Próximo"></default-button>
  </form>
</div>
