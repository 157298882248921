import { AfterContentChecked, AfterContentInit, Component, ContentChildren, EventEmitter, Input, Output, QueryList, ViewEncapsulation } from '@angular/core';
import { delay, map, Observable, startWith } from 'rxjs';
import { DefaultTabItemComponent } from './../default-tab-item/default-tab-item.component';

@Component({
  selector: 'default-tabs',
  templateUrl: './default-tabs.component.html',
  styleUrls: ['./default-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultTabsComponent implements AfterContentInit, AfterContentChecked {

  @ContentChildren(DefaultTabItemComponent)
  tabs: QueryList<DefaultTabItemComponent>;

  tabItems$: Observable<DefaultTabItemComponent[]>;

  activeTabItem: DefaultTabItemComponent;

  @Input() activeTab: number = 0;
  @Output() activeTabChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnChanges(changes: any) {
    if(changes?.activeTab && !changes?.activeTab?.firstChange){
      this.selectTab(this.tabs?.toArray()[changes?.activeTab?.currentValue]);
    }
  }

  ngAfterContentInit(): void {
    this.tabItems$ = this.tabs.changes
      .pipe(startWith(""))
      .pipe(delay(0))
      .pipe(map(() => this.tabs.toArray()));
  }

  ngAfterContentChecked() {
    if (!this.activeTabItem) {
      Promise.resolve().then(() => {
        this.selectTab(this.tabs.toArray()[this.activeTab]);
      });
    }
  }

  selectTab(tabItem: DefaultTabItemComponent) {
    if (this.activeTabItem === tabItem) {
      return;
    }

    if (this.activeTabItem) {
      this.activeTabItem.isActive = false;

    }

    this.activeTabItem = tabItem;
    tabItem.isActive = true;
    this.activeTabChange.emit(this.tabs.toArray().findIndex(tab => this.activeTabItem === tab))
  }

}
