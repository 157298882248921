import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators as ValidatorsForm,
} from '@angular/forms';
export interface Validators {
  validator: ValidatorFn;
  message: string;
}
export function FormValidator(validatorsArray: Array<Validators>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    var error: any = null;
    validatorsArray.forEach((validator) => {
      const validatorResult = validator.validator(control);
      if (validatorResult && !error && (control.touched || control.dirty)) {
        error = { ...validatorResult, message: validator.message };
      }
    });
    return error;
  };
}

export function trimmedEmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {

    const emailValidator = ValidatorsForm.email(control);
    if (!control.value || !emailValidator) {
      return null;
    }

    const emailWithoutWhitespace = control.value.trim();

    const emailValidatorWihioutSpace = ValidatorsForm.email(new FormControl(emailWithoutWhitespace))

    if (control.value !== emailWithoutWhitespace) {
      control.setValue(emailWithoutWhitespace)
    }

    return emailValidatorWihioutSpace;
  };
}
