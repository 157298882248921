export enum CooperatorTab {
  GENERAL_VIEW = 0,
  INCLUSION = 1,
  EXCLUSION = 2,
}

export enum FinancialTabs {
  ACTUAL_INVOICE = 0,
  INVOICE_HISTORIC = 1
}
