export enum FileType {
  MP4 = 'mp4',
  PNG = 'png',
  JPEG = 'jpeg',
  PDF = 'pdf',
  DOCS = 'docx',
  CSV = 'csv',
  XLSX = 'xlsx',
  XLS = 'xlsx'
}
