import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { MixpanelService } from 'src/app/services/mixpanel.services';
import { SideBarService } from 'src/app/services/sidebar.service';

@Component({
  selector: 'quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickAccessComponent implements OnInit {
  constructor(
    private sidebarService: SideBarService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {}

  navigate(url: string): void {
    const path = `/home/${url}`;
    this.sidebarService.getSideBarMenu().navigate(path);

    switch (url) {
      case 'beneficiarios/1':
        this.mixpanelService.track(MixpanelEvents.ACCESS_COOPERATOR_INCLUSION, {
          Origem: 'Acesso rápido',
        });
        break;
      case 'beneficiarios/2':
        this.mixpanelService.track(MixpanelEvents.ACCESS_COOPERATOR_EXCLUSION, {
          Origem: 'Acesso rápido',
        });
        break;
    }
  }
}
