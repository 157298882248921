<header label="Carteirinhas">
</header>

<div class="cards-page-container" fxLayout="row" fxLayoutAlign="start center">

    <div fxLayout="column" fxFlex>
        <form [formGroup]="cardsFormGroup" fxFlex>

            <div fxFlex>
                <div fxLayout="column">
                    <search-input [timeToDebounce]="1000" label="Pesquisar beneficiário" (valueChanges)="listCollaboratorsWithCards()"
                        formControlName="colaborator" placeholder="Digite o nome ou CPF do beneficiário"></search-input>
                </div>
            </div>
        </form>

        <div class="cards-page-colaborator-table" fxLayout="column">
            <div>
                <cards-table [dataSource]="filteredCardsTableItems" [(loading)]="onLoading" [searchString]="cardsFormGroup.get('colaborator')?.value"
                    [maxRowsLoaded]="5"
                    (onBenefitClicked)="getRowBenefitWasClicked($event)"></cards-table>
            </div>

            <div  *ngIf="filteredCardsTableItems.length" fxLayout="row" fxLayoutAlign="end center" [ngClass]="{'offset-paginator-blipchat-opened': !!blipService.checkIfHasBlipChatOpened()}">
                <default-paginator itemLabel="beneficiários" [(total)]="totalRows" [pageNumber]="pageNumber"
                    (onPageChange)="pageChanged($event)"
                    [maxPerPage]="maxPerPage"></default-paginator>
            </div>
        </div>

    </div>

</div>
