import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ThemeSettingsModule } from '../vendor/libs/theme-settings/theme-settings.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AuthGuard } from './guards/auth-guard.service';

import { LayoutModule } from './layout/layout.module';
import { MixpanelService } from './services/mixpanel.services';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CompanyIntercetor } from './interceptors/company.interceptor';
import { ApiPrefixInterceptor } from './interceptors/api-prefix.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { StorageService } from './services/storage.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    ThemeSettingsModule,
    HttpClientModule,
    SharedModule,
    ToastrModule.forRoot({
      progressBar: true,
      closeButton: true,
      autoDismiss: false,
      progressAnimation: 'decreasing',
      tapToDismiss: false,
      positionClass: 'toast-top-center',
      timeOut: 5000,
      newestOnTop: false,
    }),
  ],
  providers: [
    AppService,
    MixpanelService,
    StorageService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CompanyIntercetor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
