import { BuscarPlanoModel } from 'src/app/models/buscar-plano.model';
import { EstrategiaDeValidacao } from '../../../interfaces/EstrategiaValidacao';

export class EstrategiaDeValidacaoDeStatus
  implements EstrategiaDeValidacao<BuscarPlanoModel>
{
  validar(empresa: BuscarPlanoModel): boolean {
    const SITUACAO_ATIVA = 1;
    const isValid = empresa.situacao === SITUACAO_ATIVA;
    if (isValid) {
      // TODO: implementar o winston
      // console.log('Validação de status bem-sucedida para a empresa:', empresa);
    }
    return isValid;
  }
}
