import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'protocol-table',
  templateUrl: './protocol-table.component.html',
  styleUrls: ['./protocol-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProtocolTableComponent implements OnInit {
  readonly skeletonTheme = {
    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
    'border-radius': '6px',
    'width': '150px',
    'height': '25px'
    }
  @Input() searchString: string = '';
  @Input() loading: boolean = true;
  @Input() dataSource: any[] = [];
  @Input() maxRowsLoaded: number = 5;

  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();

  detailedCrmData: any;

  constructor() { }

  ngOnInit(): void {
  }

  rowClicked(row: any) {

    this.onRowClick.emit(row)
  }

  situationTextColor(situation: string) {
    switch (situation) {
      case 'Em andamento':
        return '#1ABC77'
      case 'Finalizado':
        return '#ADAFB4'
      case 'Aberto':
        return '#F2AB27'
      default:
        return '#ADAFB4'
    }
  }

}
