import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-invoice-code',
  templateUrl: './modal-invoice-code.component.html',
  styleUrls: ['./modal-invoice-code.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalInvoiceCodeComponent implements OnInit {

  @Input() icon: string = '';
  @Input() code: string = '';
  @Input() buttonLabel: string = '';
  @Input() copyCodeCb: Function;
  hasCopiedCode: boolean = false;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  copyCodeClipboard() {    
    this.copyCodeCb(this.code)
    this.hasCopiedCode = true;
  }

  closeModal(redirectTo: boolean) {
    this.activeModal.close(redirectTo);
  }
}