import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  /**
   * Initialize with user id.
   *
   * @param {string} cpf
   * @memberof MixpanelService
   */
  init(): void {
    mixpanel.init(environment.mixpanelToken, { ignore_dnt: true });
  }

  /**
* setup mixpannel
*
*/
  setup() {
    // mixpanel.loggingEnabled = false;
    this.setSuperProperties({ Platform: 'web' });
  }


  /**
  * setSuperProperties
  *
  * @param {object} properties
  */
  setSuperProperties(properties: Object) {
    mixpanel.register(properties);
  }


  register(props: any[]): void {
    mixpanel.register({
      props
    })
  }

  /**
  * Identify user profile on mixpanel.
  *
  * @param {string} userId
  * @memberof MixpanelService
  */

  unregister(prop: string): void {
    mixpanel.unregister(prop)
  }

  /**
 * Push new action to mixpanel.
 *
 * @param {string} id Name of the action to track.
 * @param {*} [action={}] Actions object with custom properties.
 * @memberof MixpanelService
 */
  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  /**
 * Identify user profile on mixpanel.
 *
 * @param {string} userId
 * @memberof MixpanelService
 */
  identify(userId: string | undefined = undefined): void {
    mixpanel.identify(userId);
  }

  /**
 * Create user profile on mixpanel with user data.
 *
 * @param {any} user
 * @memberof MixpanelService
 */
  createUserProfile(user: any) {
    mixpanel.people.set(user)
  }

  /**
 * Clear login info of logged user.
 *
 * @memberof MixpanelService
 */
  clearLoggedUser() {
    mixpanel.reset()
  }
}
