export enum BeneficiaryType {
  COLLABORATOR = 'Colaborador',
  DEPENDENT = 'Dependente',
  HOLDER = 'Titular'
}

export enum BeneficiaryTypeOptions {
  COLLABORATOR = 0,
  DEPENDENT = 1,
}
