import { AccrualDate } from '../../models/accrualDate.model';
import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RadioInputComponent implements OnInit {

  @Input() title: string
  @Input() subtitle: string;
  @Input() options: AccrualDate[];
  @Input() fxLayout: string = 'row';

  @Input() selected: any;

  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  selectChanged(option: any) {
    this.options.forEach(singleOption => singleOption.checked = false)
    this.selected = option.value;
    option.checked = true
    this.selectChange.emit(option.value);
    this.optionSelected.emit(option);
  }

  clearSelected() {
    this.options.forEach(singleOption => singleOption.checked = false)
    this.selected = null;
  }

}
