import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

export type InputType = 'email' | 'password' | 'text';
@Component({
  selector: 'default-input',
  templateUrl: './default-input.component.html',
  styleUrls: ['./default-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultInputComponent implements AfterViewInit, OnDestroy {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: InputType = 'text';
  @Input() disabled: boolean = false;
  @Input() value: any = '';
  @Input() mask: string = '';
  @Input() prefix: string = '';
  @Input() errorMessage: string = '';
  @Input() dropSpecialCharacters?: boolean = true;
  @Input() informativeLabel?: string = '';
  @Input() thousandSeparator?: string;
  @Input() inactiveSuffixIcon: string = '';
  @Input() activeSuffixIcon: string = '';
  @Input() activeSuffixState: boolean;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSuffixIconClicked: EventEmitter<any> = new EventEmitter<any>();


  @ViewChild('passwordField') passwordField?: ElementRef<HTMLInputElement>;
  

  hide: boolean;
  capsLockMessage: string | undefined;
  showCapsLockInfo: boolean;
  focused: boolean;

  onChange: any = () => {
    this.valueChanges.emit(this.value);
  };

  onChangeValue(value: any) {
    this.valueChanges.emit(value);
  };

  onTouched: any = () => {};

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled'] && this.ngControl?.control) {
      changes['disabled'].currentValue || this.disabled
        ? this.ngControl?.control?.disable()
        : this.ngControl?.control?.enable();
    }
  }

  ngAfterViewInit() {
    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent)) {
        return;
      }
      if (event.getModifierState('CapsLock')) {
        this.capsLockMessage = 'CAPS Lock ativado';
      } else {
        this.capsLockMessage = undefined;
      }
      this.cdRef.markForCheck();
    };

    this.passwordField?.nativeElement?.addEventListener('keyup', eventHandler);
    this.passwordField?.nativeElement?.addEventListener(
      'mousedown',
      eventHandler
    );
  }

  ngOnDestroy() {
    this.passwordField?.nativeElement?.removeAllListeners?.('keyup');
    this.passwordField?.nativeElement?.removeAllListeners?.('mousedown');
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeFocus({ type }: FocusEvent) {
    this.setShowCapsLockInfo();
    this.focused = type === 'focus';
  }

  setShowCapsLockInfo() {
    this.showCapsLockInfo = !this.showCapsLockInfo;
  }

  iconClicked(event: any) {
    this.onSuffixIconClicked.emit({event, value: this.value})
  }
}
