import { InvoiceHistoricStatus } from '../../../enums/invoice-historic-table-mode';
import { InvoiceHistoric } from '../../../models/invoice-historic-table-item.model';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  Output,
  ViewChildren,
  EventEmitter,
  QueryList,
} from '@angular/core';
import {
  compare,
  NgbdSortableHeader,
  SortEvent,
} from '../sortable-header-directive';

@Component({
  selector: 'financial-invoices-historic-table',
  templateUrl: './financial-invoices-historic-table.component.html',
  styleUrls: ['./financial-invoices-historic-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinancialInvoicesHistoricTableComponent implements OnInit {
  @Input() dataSource: any[] = [];
  @Input() loading: boolean = true;
  @Input() maxRowsLoaded: number = 5;
  @Input() tableMode: number = InvoiceHistoricStatus.PAID;
  invoiceHistoriceTableMode = InvoiceHistoricStatus;
  headerNames: string[] = ['Competência', 'Pago em', 'Valor pago'];

  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGetPaymentSlipCopyClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreatePaymentSlipClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadNFeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCheckInvoice: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor() {}

  ngOnInit(): void {
    this.setHeadersNameByTableMode(this.tableMode)
  }

  rowClicked(row: InvoiceHistoric) {
    console.log(row);
  }


  getPaymentSlipCopyClick(url: string) {
    this.onGetPaymentSlipCopyClick.emit(url);
  }

  createPaymentSlip(url: string) {
    this.onCreatePaymentSlipClick.emit(url);
  }

  downloadNFe(url: string) {
    this.onDownloadNFeClick.emit(url);
  }

  checkInvoice(invoiceId: number) {
    this.onCheckInvoice.emit(invoiceId);
  }

  setHeadersNameByTableMode(tableMode: InvoiceHistoricStatus) {
    switch (tableMode) {
      case InvoiceHistoricStatus.PAID:
        this.headerNames = ['Competência', 'Pago em', 'Valor pago'];
        break;
      case InvoiceHistoricStatus.PAYMENT_DUE:
        this.headerNames = ['Competência', 'Vence em', 'Valor a pagar'];
        break;
      case InvoiceHistoricStatus.PAYMENT_LATE:
        this.headerNames = ['Competência', 'Venceu em', 'Valor a pagar'];
        break;
    }
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.dataSource = this.dataSource;
    } else {
      this.dataSource = [...this.dataSource].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
}
