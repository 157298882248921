import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { share } from 'rxjs/operators';
import { CompanyInfo } from '../models/user-token.model';

@Injectable()
export class StorageService implements OnDestroy {

    private onSubject = new Subject<{ key: string, value: any }>();
    public changes = this.onSubject.asObservable().pipe(share());

    constructor() {
        this.start();
    }

    ngOnDestroy() {
        this.stop();
    }

    public setItem(key: string, data: any): void {
        localStorage.setItem(key, JSON.stringify(data));
        this.onSubject.next({ key: key, value: data })
    }

    public getItem(key: string): CompanyInfo {
        return JSON.parse(localStorage.getItem(key) || '') || {};
    }

    public clear(key: string) {
        localStorage.removeItem(key);
        this.onSubject.next({ key: key, value: null });
    }

    private start(): void {
        window.addEventListener("storage", this.storageEventListener.bind(this));
    }

    private stop(): void {
        window.removeEventListener("storage", this.storageEventListener.bind(this));
        this.onSubject.complete();
    }

    private storageEventListener(event: StorageEvent) {
        if (event.storageArea == localStorage) {
            let parsedValue;
            try { parsedValue = JSON.parse(event.newValue ?? 'null'); }
            catch (e) { parsedValue = event.newValue; }
            this.onSubject.next({ key: event.key ?? 'null', value: parsedValue });
        }
    }


}
