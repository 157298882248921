import { Component, OnInit, Output, Input, ViewEncapsulation, EventEmitter } from '@angular/core';

@Component({
  selector: 'card-help-chat',
  templateUrl: './card-help-chat.component.html',
  styleUrls: ['./card-help-chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardHelpChatComponent implements OnInit {

  @Input() buttonIcon: string = '';
  @Input() buttonLabel: string = '';
  @Input() helpLinkLabel: string = '';
  @Input() helpLabel: string = '';

  @Output() onLinkClick = new EventEmitter<any>();
  @Output() onButtonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  clickLink() {
    this.onLinkClick.emit();
  }

  clickButton() {
    this.onButtonClick.emit();
  }

}
