<header label="Fale conosco"></header>

<div>
  <div class="contact-us-page-container" fxLayout="column" fxLayoutGap="2vmin">
    <default-tabs [(activeTab)]="activeTab" (activeTabChange)="showActiveTab()">
      <default-tab-item>
        <default-tab-label>Atendimento</default-tab-label>
        <default-tab-body>
          <div class="container-two-columns" fxLayoutAlign="space-between stretch" fxLayout="row" fxLayoutGap="15vmin">
            <div fxLayout="column" fxLayoutAlign="space-between start" class="column-divider">
              <p class="subtitle">
                Precisando de ajuda? Entre em contato com o nosso Atendimento agora mesmo no chat.
              </p>
              <default-button (onClick)="contactUsOnChat()" class="button-contact-us" label="Fale com a gente no chat"
                icon="../../../assets/svgs/chat-icon.svg"></default-button>

              <div class="contact-us-separator"></div>
              <div class="contact-us-message">
                <p class="semi-bold p3">
                  Caso haja dúvidas com alguma funcionalidade, você pode assistir os vídeos tutoriais sobre a
                  plataforma.
                  <br />
                  <a (click)="sendMetric()" href="https://mkt.planoclin.com.br/plataforma-rh-primeiros-passos"
                    class="p3 bold contact-us-link">
                    Acesse aqui.
                  </a>
                </p>
              </div>
            </div>

            <div class="vertical-separator"></div>

            <div fxLayout="column" class="column-divider">
              <p class="subtitle">Fale diretamente com o SAC ou representante</p>

              <div class="container-cards">
                <card-contact *ngFor="let contact of contacts" [title]="contact.title" [sac]="contact.sac"
                  [contacts]="contact?.contacts"></card-contact>
              </div>
            </div>
          </div>
        </default-tab-body>
      </default-tab-item>

      <default-tab-item>
        <default-tab-label>Chamados</default-tab-label>
        <default-tab-body>
          <div *ngIf="isTableView; then table else detailed_crm"></div>
          <ng-template #table>
            <div *ngIf="hasOnGoingCrm" class="info-container" fxLayout="row" fxLayoutAlign="center center">
              <svg-icon src="../../../assets/svgs/info-icon.svg"></svg-icon>
              <p class="semi-bold p3 info-text">Acompanhe diariamente os chamados em andamento para não perder as
                atualizações!</p>
            </div>
            <div fxLayout="row" class="search-bar-and-button-container" fxLayoutAlign="space-between center"
              fxLayoutGap="2vmin">
              <form [formGroup]="protocolFormGroup" fxFlex fxFlexFill>
                <search-input [timeToDebounce]="1000" label="Pesquisar chamado" (valueChanges)="listProtocols()"
                  formControlName="protocol" placeholder="Digite o protocolo ou o assunto do chamado"></search-input>
              </form>
              <div>
                <default-button class="fix-height" (onClick)="openCRM()" label="Abrir novo chamado"
                  icon="../../../assets/svgs/crm-icon.svg"></default-button>
              </div>
            </div>
            <div fxLayout="column">
              <protocol-table [searchString]="searchString" [dataSource]="crms" [loading]="isLoadingTable"
                (onRowClick)="onRowClick($event)"></protocol-table>
              <div *ngIf="crms.length" fxFlex fxLayoutAlign="end end">
                <default-paginator [itemLabel]="crmTotal > 1? 'chamados': 'chamado'" [(total)]="crmTotal"
                  [pageNumber]="page" (onPageChange)="pageChanged($event)"></default-paginator>
              </div>
            </div>
          </ng-template>
          <ng-template #detailed_crm>
            <div class="crm-form-group" fxLayout="column">
              <quarternary-link-button label="Voltar para a lista" icon="../../../assets/svgs/arrow_back.svg"
                (onClick)="backToTableView()"></quarternary-link-button>

              <div class="card-crm">
                <div fxLayout="row" class="external-container-crm">
                  <div class="container-divide" fxFlex="50" fxLayout="column">
                    <div fxLayout="column">
                      <p class="title-label-crm">Protocolo {{detailedCrmData?.protocolNumber}}</p>

                      <div class="row-info-crm" style="margin-top: 15px" fxLayout="row">
                        <p class="bold-label-crm-info">Situação: </p>
                        <p class="bold-value-crm-info" [style.color]="situationTextColor(detailedCrmData?.situation)"
                          style="font-weight: 600 !important;">
                          {{detailedCrmData?.situation | titlecase}}
                        </p>
                      </div>

                      <div class="row-info-crm" fxLayout="row">
                        <p class="bold-label-crm-info">Aberto em: </p>
                        <p class="bold-value-crm-info">{{detailedCrmData?.openAt}}</p>
                      </div>

                      <div class="row-info-crm" fxLayout="row">
                        <p class="bold-label-crm-info">Nome de quem solicitou: </p>
                        <p class="bold-value-crm-info">{{detailedCrmData?.requesterName | titlecase}}</p>
                      </div>

                      <div class="row-info-crm" fxLayout="row">
                        <p class="bold-label-crm-info">E-mail de quem solicitou: </p>
                        <p class="bold-value-crm-info">{{detailedCrmData?.requesterEmail }}</p>
                      </div>

                      <div class="row-info-crm" fxLayout="row">
                        <p class="bold-label-crm-info">Assunto: </p>
                        <p class="bold-value-crm-info">{{detailedCrmData?.subject | titlecase}}</p>
                      </div>

                      <div class="row-info-crm" fxLayout="row">
                        <p class="bold-label-crm-info">Assunto detalhado: </p>
                        <p class="bold-value-crm-info">{{detailedCrmData?.detailedSubject | titlecase}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="container-divide" fxFlex="50" fxLayout="column">
                    <div fxLayout="column" style="height:100%;">
                      <p class="title-label-crm">Última atualização</p>
                      <div fxLayout="column" style="height:100%;" fxLayoutAlign="space-between none">
                        <div class="last-message-container" fxLayout="column">
                          <div style="padding: 16px">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                              <p class="bold small secondary-text-color">{{detailedCrmData?.messages[0].sender}}</p>
                              <p class="semi-bold small gray-dark-text-color">{{detailedCrmData?.messages[0].date}}</p>
                            </div>
                            <p class="regular small secondary-light-text-color" style="margin-top: 8px">
                              {{detailedCrmData?.messages[0].message}}</p>
                          </div>
                        </div>

                        <tertiary-button style="margin-top: 37px" label="Enviar nova mensagem"
                          (onClick)="openChatHistory()"></tertiary-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </ng-template>
        </default-tab-body>
      </default-tab-item>
    </default-tabs>
  </div>
</div>

<ng-template #stepperContactUs>
  <stepper-generic #cdkStepperContactUs id="cdkStepperContactUs" [linear]="isLinear">
    <cdk-step>
      <form [formGroup]="frmStepperOne">
        <div fxLayout="column">
          <h1 class="title-label">Abrir chamado</h1>

          <default-input formControlName="name" label="Nome de quem está solicitando" placeholder="Informe seu nome">
          </default-input>

          <default-input formControlName="email" type="email" label="E-mail de quem está solicitando"
            placeholder="Informe seu e-mail"></default-input>

          <default-dropdown formControlName="subject" label="Assunto" placeholder="Selecione o assunto do chamado"
            [options]="subjectOptions" (selectedChange)="subjectSelect()"></default-dropdown>

          <default-dropdown formControlName="subjectDetail" label="Detalhamento do assunto"
            placeholder="Selecione o detalhamento do assunto escolhido" [options]="subjectDetailOptions">
          </default-dropdown>

          <div fxLayout="row" fxLayoutAlign="end" class="footer">
            <default-button fxFlex="48" [disabled]="frmStepperOne.invalid" label="Avançar" (onClick)="nextStep()">
            </default-button>
          </div>
        </div>
      </form>
    </cdk-step>
    <cdk-step>
      <form [formGroup]="frmStepperTwo">
        <div fxLayout="column">
          <h1 class="title-label">Abrir chamado</h1>

          <default-text-area formControlName="message" label="Mensagem" placeholder="Relate aqui o motivo do chamado">
          </default-text-area>

          <drag-and-drop-files label="Anexar arquivo"
            description="Formatos JPEG, PNG, MP4, PDF, docx, Excel ou CSV e com até 100mb"
            [allowedFilesExtension]="ACCEPT_FILES_EXTENSIONS" [allowedFileSizeInMB]="ALLOW_FILE_SIZE"
            [(files)]="filesList" (unsupportedFile)="unsupportedFile($event)" (onDelete)="deleteFile($event)">
          </drag-and-drop-files>

          <div fxLayout="row" fxLayoutAlign="space-between stretch" class="footer">
            <tertiary-button fxFlex="48" label="Voltar" (onClick)="closeCrm()"></tertiary-button>
            <default-button fxFlex="48" [disabled]="frmStepperTwo.invalid || isLoading" label="Abrir chamado"
              (onClick)="createCrm()"></default-button>
          </div>
        </div>
      </form>
    </cdk-step>
  </stepper-generic>
</ng-template>
