import { Component, OnInit, ViewEncapsulation, EventEmitter, Input, Output, ViewChildren, QueryList } from '@angular/core';
import { NgbdSortableHeader, SortEvent, compare } from '../sortable-header-directive';

declare interface TableItem {
  name: string;
  cpf: string;
  dateOperation: string;    
  type: string;
  value: string;
}

@Component({
  selector: 'financial-operations-table',
  templateUrl: './financial-operations-table.component.html',
  styleUrls: ['./financial-operations-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FinancialOperationsTableComponent implements OnInit {
  
  @Input() dataSource: any[] = [];      
  @Input() loading: boolean = true;
  @Input() maxRowsLoaded: number = 5;

  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor() { }

  ngOnInit(): void { }

  rowClicked(row: TableItem) {
    console.log(row)
  }

  clickButton(row: TableItem) {      
    this.onButtonClicked.emit(row)
  }

  onSort({column, direction}: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '') {
      this.dataSource = this.dataSource;
    } else {
      this.dataSource = [...this.dataSource].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
      }
  }
}
