import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InformativeService } from 'src/app/services/informative.service';
import { SetInformativeDTO } from './set-read-informative.dto';

@Component({
  selector: 'app-modal-informative',
  templateUrl: './modal-informative.component.html',
  styleUrls: ['./modal-informative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalInformativeComponent {
  show: boolean = false;
  informativeId: number;
  title: string;
  message: string[];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly informativeService: InformativeService,
  ) {}

  toggle() {
    this.show = !this.show;
  }

  public setData(params: SetInformativeDTO) {
    this.message = String(params?.message)
      .split(/\.\s*/g)
      .map((line) => line.trim() + '.')
      .filter((content) => content !== '.');
    this.informativeId = params.id;
    this.title = params.title;
  }

  onClick() {
    this.informativeService.setReadInformative(this.informativeId);
    this.activeModal.close();
  }
}
