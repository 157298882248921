import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtTokenService } from './auth/jwt-token.service';
import { environment } from 'src/environments/environment';
import { Review } from '../models/review.model';

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  private readonly REVIEW_URL = environment.apiUrl + '/api/evaluation';
  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) { }

  insertReview(review: Review) {
    return lastValueFrom(this.http
      .post(`${this.REVIEW_URL}/create`,
        review,
        {
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }))

  }
}
