import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

import { fromEvent } from 'rxjs';
import {
  bufferCount,
  exhaustMap,
  filter,
  map,
  startWith,
} from 'rxjs/operators';
import { ScrollPosition } from '../models/scrollPosition.model';

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
  sH: 0,
  sT: 0,
  cH: 0,
};

@Directive({
  selector: '[InfiniteScroller]',
})
export class InfiniteScrollerDirective implements AfterViewInit {
  private scrollEvent$: any;
  private userScrolledDown$: any;
  private requestStream$: any;
  private requestOnScroll$: any;

  @Input() scrollCallback: any;
  @Input() immediateCallback: any;
  @Input() scrollPercent = 70;

  constructor(private elm: ElementRef) {}

  ngAfterViewInit() {
    this.registerScrollEvent();
    this.streamScrollEvents();
    this.requestCallbackOnScroll();
  }

  private registerScrollEvent() {
    this.scrollEvent$ = fromEvent(this.elm.nativeElement, 'scroll');
  }

  private streamScrollEvents() {
    this.userScrolledDown$ = this.scrollEvent$.pipe(
      map(
        (e: any): ScrollPosition => ({
          sH: e.target.scrollHeight,
          sT: e.target.scrollTop,
          cH: e.target.clientHeight,
        })
      ),
      bufferCount(2, 1),
      filter(
        (positions: any) =>
          this.isUserScrollingDown(positions) &&
          this.isScrollExpectedPercent(positions[1])
      )
    );
  }

  private requestCallbackOnScroll() {
    this.requestOnScroll$ = this.userScrolledDown$;

    if (this.immediateCallback) {
      this.requestOnScroll$ = this.requestOnScroll$.pipe(
        startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION])
      );
    }

    this.requestOnScroll$
      .pipe(
        exhaustMap(() => {
          return this.scrollCallback();
        })
      )
      .subscribe(() => {});
  }

  private isUserScrollingDown = (positions: any) => {
    return positions[0].sT < positions[1].sT;
  };

  private isScrollExpectedPercent = (position: any) => {
    return (position.sT + position.cH) / position.sH > this.scrollPercent / 100;
  };
}
