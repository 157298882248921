import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DetailedInvoiceHeader } from '../models/detailed-invoice-header.model';
import { JwtTokenService } from './auth/jwt-token.service';
import { DetailedInvoiceOperation } from '../models/detailed-invoice-operation.model';
import { Pagination } from '../models/pagination.model';
import { InvoiceInfo } from '../models/invoice-info.model';
import { PaidInvoicesTableInformation } from '../models/paid-invoices-table-information.model copy';
import { DueAndLateInvoicesTableInformation } from '../models/due-and-late-invoices-table-information.model';

@Injectable({
  providedIn: 'root',
})
export class FinancialService {
  private readonly FINANCIAL_URL = environment.apiUrl + '/api/finances';

  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) {}

  listOperationsByCompanyId(companyId: number, page: number, offset: number) {
    return lastValueFrom(this.http
      .post(
        `${this.FINANCIAL_URL}/company/${companyId}/operations`,
        {
          page,
          offset,
        },
        this.jwtTokenService.getHeaderAuth()
      ))
  }

  getInvoiceInformationByCompanyId(companyId: number) {
    return this.http
      .get<InvoiceInfo>(
        this.FINANCIAL_URL + '/company/' + companyId + '/invoice-information',
        this.jwtTokenService.getHeaderAuth()
      ).toPromise()
  }

  getLastPaidInvoicesByCompanyId(companyId: number) {
    return lastValueFrom(this.http
      .get(
        `${this.FINANCIAL_URL}/company/${companyId}/last-paid-invoices`,
        this.jwtTokenService.getHeaderAuth()
      ))
  }

  getPaidInvoicesTableInformation(companyId: number, page: number, offset: number) {
    return this.http
      .post<PaidInvoicesTableInformation>(
        this.FINANCIAL_URL + '/company/' + companyId + '/paid-invoices-table-information', {
          page,
          offset
        },
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  getDueAndLateInvoicesTableInformation(companyId: number) {
    return this.http
      .get<DueAndLateInvoicesTableInformation>(
        this.FINANCIAL_URL + '/company/' + companyId + '/due-and-late-invoices-table-information',
        this.jwtTokenService.getHeaderAuth()
      ).toPromise()
  }

  getHistoricInvoiceStatusByCompanyId(companyId: number) {
    return lastValueFrom(this.http
      .get(
        `${this.FINANCIAL_URL}/company/${companyId}/historic-invoice-status`,
        this.jwtTokenService.getHeaderAuth()
      ))
  }

  getDetailedInvoicePDF(companyId: number, invoiceId?:number) {
    const apiUrl = invoiceId ? `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-pdf/${invoiceId}` : `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-pdf`
    return firstValueFrom(
      this.http.get(
        apiUrl,
        {
          responseType: 'arraybuffer',
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
    );
  }

  getDetailedInvoiceSpreadsheet(companyId: number, invoiceId?:number) {
    const apiUrl = invoiceId ? `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-spreadsheet/${invoiceId}` : `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-spreadsheet`
    return firstValueFrom(
      this.http.get(
        apiUrl,
        {
          observe: 'response',
          responseType: 'arraybuffer',
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
    );
  }
  getDetailedInvoiceHeader(companyId: number, invoiceId?: number) {
    const apiUrl = invoiceId ? `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-header/${invoiceId}` : `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-header`
    return lastValueFrom(
      this.http.get<DetailedInvoiceHeader>(
        apiUrl,
        {
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
    );
  }
  getDetailedInvoiceOperations(
    companyId: number,
    { page, offset }: Pagination,
    invoiceId?: number
  ) {
    const apiUrl = invoiceId ? `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-operations/${invoiceId}` : `${this.FINANCIAL_URL}/company/${companyId}/detailed-invoice-operations`
    return lastValueFrom(
      this.http.post<DetailedInvoiceOperation[]>(
        apiUrl,
        {
          page,
          offset,
        },
        {
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
    );
  }
}
