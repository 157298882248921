<header label="Meu perfil">  
</header>

<div class="main-container">
  <div class="separator-border">
    <div fxLayout="column" fxFlex="50">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="title-container" fxLayout="column" fxLayoutAlign="space-between none">
          <h2 class="bold">Dados gerais</h2>
          <p class="regular p3">Mantenha seus dados atualizados.</p>
        </div>
        <div class="edit-button-container">
          <default-button label="Editar" *ngIf="!isEditing" (onClick)="editForm()"
            icon="../../../assets/svgs/edit-icon.svg">
          </default-button>
        </div>
      </div>

      <form class="form-container" fxLayout="column" [formGroup]="myProfileFormGroup">
        <default-input class="margin-bottom" label="Nome da empresa" formControlName="companyName"></default-input>

        <default-input class="margin-bottom" label="CNPJ" mask="CPF_CNPJ" formControlName="cnpj"></default-input>

        <default-input class="margin-bottom" label="E-mail" formControlName="email" 
          [disabled]="!isEditing"
          (input)="onEnableButton()"></default-input>

        <default-input label="Telefone" [mask]="getMask()" formControlName="phoneNumber"
          [disabled]="!isEditing"
          (input)="onEnableButton()"></default-input>
      </form>
      <div *ngIf="isEditing" class="margin-top" fxLayout="row" fxLayoutAlign="space-between">
        <tertiary-button (mousedown)="cancelEditForm()" fxFlex="48" label="Cancelar"></tertiary-button>
        <default-button (onClick)="updateInformation()" fxFlex="48" label="Salvar"
          [disabled]="disableSaveButton || myProfileFormGroup.invalid"></default-button>
      </div>
    </div>

    <div class="vertical-separator"></div>
    <div fxFlex="50">
      <div class="title-container" fxLayout="column" fxLayoutAlign="space-between none">
        <h2 class="bold">Alterar a senha</h2>
        <p class="regular p3">
          Para alterar a sua senha, clique no botão abaixo.
        </p>
      </div>
      <div class="change-password-button-container">
        <tertiary-button (onClick)="openModal()" label="Alterar a senha"
          icon="../../../assets/svgs/change-password-button-icon.svg"></tertiary-button>
      </div>
    </div>
  </div>
</div>
