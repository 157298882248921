import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

export interface Option {
  label: string,
  checked: boolean[],
  enabled: boolean
}
@Component({
  selector: 'review-radio-group',
  templateUrl: './review-radio-group.component.html',
  styleUrls: ['./review-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ReviewRadioGroupComponent {
  readonly grades: string[] = ['Muito<br/>difícil', 'Difícil', 'Neutro', 'Fácil', 'Muito<br/>fácil']
  @Input() label: string;
  @Input() selected: number;
  @Input() checked: boolean[] = [false, false, false, false, false]
  @Input() values: number[] = [1, 2, 3, 4, 5]
  @Input() enabled: boolean = true
  @Input() firstReview: boolean = false;
  @Input() lastReview: boolean = false;

  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  selectChanged(value: number) {
    this.checked.forEach((_: any, index: number) => {
      this.checked[index] = false
    })
    this.selected = value
    this.checked[value] = true
    this.selectChange.emit(this.selected);
    this.optionSelected.emit(value);
  }
}
