<div
  class="position-logo"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <svg-icon src="../../../../assets/svgs/success-modal-icon.svg"></svg-icon>
</div>
<div class="margin-content">
  <p class="bold text-center">Deseja sair da plataforma?</p>
  <div class="buttons-container" fxLayoutAlign="space-between center" fxLayout="row">
    <tertiary-button
      fxFlex="48"
      (onClick)="goBack()"
      label="Voltar"
    ></tertiary-button>
    <default-button
      fxFlex="48"
      (onClick)="logout()"
      label="Sair"
    ></default-button>
  </div>
</div>
