import { HttpEvent, HttpEventType, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FileType } from 'src/app/enums/file-type';
import { bytesToMegaBytes } from 'src/app/utils/utils';

@Component({
  selector: 'drag-and-drop-files',
  templateUrl: './drag-and-drop-files.component.html',
  styleUrls: ['./drag-and-drop-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DragAndDropFilesComponent {

  @Input() alert: string = '';
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() isOptional: boolean = true;

  @Input()
  uploadLabel: string = `Arraste e solte o arquivo aqui<br /><strong><u>ou procure no computador</u></strong>`;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();

  @Input() allowedFilesExtension: FileType[] | any[] = [];
  @Input() allowedFileSizeInMB: number = 100;
  @Output() unsupportedFile: EventEmitter<any> = new EventEmitter<any>();

  @Input() files: any[] = [];
  @Output() filesChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileField') fileField: ElementRef;

  form: FormGroup;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
      files: [null],
    });
  }

  async upload(e: any) {
    const fileListAsArray: any[] = Array.from(e);

    for await (const item of fileListAsArray) {
      const elementToInsert = Object.assign({
        file: item,
        metaData: { finished: false, error: false },
      });
      const filenameParts = item.name.split('.');
      const extFile = filenameParts[filenameParts.length - 1];

      const errorType = {
        errorFileExt: !this.allowedFilesExtension.includes(extFile),
        errorFileSize: bytesToMegaBytes(item.size) > this.allowedFileSizeInMB,
      };

      if (errorType.errorFileExt || errorType.errorFileSize) {
        const error = {
          ...errorType,
          filename: filenameParts[0],
          sizeInMb: bytesToMegaBytes(item.size),
        };

        this.unsupportedFile.emit(error);
        return;
      }
      this.files.push(elementToInsert);
    }
    this.filesChange.emit(this.files);

    this.form.patchValue({
      files: this.files,
    });
    this.form.get('files')?.updateValueAndValidity();

    // const filesToUpload = this.files?.filter((el) => !el?.metaData?.finished);
  }

  onClear() {
    this.form.get('files')?.reset();
  }
  deleteClicked(file: any) {
    this.onDelete.emit(file);
  }
}
