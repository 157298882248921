import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'default-pill',
  templateUrl: './default-pill.component.html',
  styleUrls: ['./default-pill.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultPillComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() label: string =  '';

  @Output() onClick:EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  buttonClick(){
    if(!this.disabled) {
      this.onClick.emit();
    }    
  }

}