import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ModalDetailedInvoiceComponent } from 'src/app/components/modals/modal-detailed-invoice/modal-detailed-invoice.component';
import { ModalWithOneButtonComponent } from 'src/app/components/modals/modal-with-one-button/modal-with-one-button.component';
import { ActionsType } from 'src/app/enums/action-type';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { FinancialTabs } from 'src/app/enums/tabs';
import { ActionService } from 'src/app/services/actions.service';
import { MixpanelService } from 'src/app/services/mixpanel.services';
import { paginate } from 'src/app/utils/utils';
import { InvoiceHistoricStatus } from '../../enums/invoice-historic-table-mode';
import { InvoiceChartModel } from '../../models/invoice-chart-model';
import { InvoiceHistoric } from '../../models/invoice-historic-table-item.model';
import { InvoiceInfo } from '../../models/invoice-info.model';
import { BlipService } from '../../services/blip.service';
import { FinancialService } from '../../services/financial.service';
import {
  InvoiceStatus,
  InvoiceStatusTranslated,
} from './../../enums/invoice-status';
import { OpenUrlFilePageOrigin } from 'src/app/enums/open-url-file-page-origin';
import { CompanyService } from '../../services/company.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinancialComponent implements OnInit, AfterViewInit {
  limitDateForMoving: string = '';
  @ViewChild('limitDateTooltip') limitDateTooltip: NgbTooltip;

  activeTab: number = 0;

  userInfo: any;

  invoice: InvoiceInfo;

  operations: any[] = [];
  operationsRaw: any[] = [];

  maxPerPage: number = 5;
  pageNumber: number = 1;
  totalRows: number = 0;

  invoiceChartDatasource: InvoiceChartModel[] = [
    { name: '', series: [{ name: '', value: 0 }] },
  ];

  dueInvoices: InvoiceHistoric[] = [];
  lateInvoices: InvoiceHistoric[] = [];
  paidInvoices: InvoiceHistoric[] = [];

  readonly openUrlFilePageOrigin = OpenUrlFilePageOrigin;

  readonly TOOLTIP_TIMER = 4000;
  readonly URL_SECOND_INVOICE_SLIP =
    'https://www.itau.com.br/servicos/boletos/atualizar';

  isLoading: boolean = true;
  isLoadingPaidInvoices = true;
  isLoadingDueAndLateInvoices = true;

  invoiceHistoricStatusEnum = InvoiceHistoricStatus;
  invoiceHistoricStatus: number = 0;
  modalRefDetailedInvoice: NgbModalRef;

  constructor(
    private blipService: BlipService,
    private cd: ChangeDetectorRef,
    private financialService: FinancialService,
    private modalService: NgbModal,
    private mixpanelService: MixpanelService,
    private actionService: ActionService,
    private companyService: CompanyService,
    private storageService: StorageService
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('user') || '');

  }

  ngAfterViewInit(): void {
    this.displayTooltipOnInit();
    this.loadActualInvoiceInfo();
    this.storageService.changes.subscribe((result) => {
      this.operationsRaw = [];
      this.operations = [];
      this.dueInvoices = [];
      this.lateInvoices = [];
      this.paidInvoices = [];      
      this.loadActualInvoiceInfo();
      this.getInvoicesTable()
    });
  }

  ngOnInit(): void { }

  get companyId(): number {
    this.userInfo = JSON.parse(localStorage.getItem('user') || '');
    return this.userInfo.companyId;
  }

  loadActualInvoiceInfo(sendMetric = true) {
    this.isLoading = true;
    this.financialService
      .getInvoiceInformationByCompanyId(this.companyId)
      .then((invoiceInfo: any) => {
        const {
          invoiceStatus,
          totalValue,
          referralMonthYear,
          dueDate,
          availableDate,
          valueWithoutInterest,
          interest,
          id,
          canDownloadSecondPaymentSlip,
          canDownloadTaxInvoice,
          canDownloadInvoice,
          dateOperation,
          invoiceId,
        } = invoiceInfo;
        if (sendMetric) {
          this.mixpanelService.track(
            MixpanelEvents.CURRENT_INVOICE_ENDPOINT_STATUS,
            { status: InvoiceStatusTranslated[invoiceStatus] }
          );
          this.mixpanelService.track(
            MixpanelEvents.HISTORIC_INVOICE_ENDPOINT_STATUS,
            {
              faturaAtrasadaNoHistorico:
                this.invoiceHistoricStatus === InvoiceStatus.PAYMENT_DELAYED,
            }
          );
        }
        this.limitDateForMoving = dateOperation;

        this.invoice = {
          invoiceStatus,
          totalValue,
          referralMonthYear,
          dueDate,
          availableDate,
          valueWithoutInterest,
          interest,
          id,
          invoiceId,
          invoiceHistoricStatus: this.invoiceHistoricStatus,
          canDownloadSecondPaymentSlip,
          canDownloadTaxInvoice,
          canDownloadInvoice,
        };
        this.mixpanelService.track(
          MixpanelEvents.CURRENT_INVOICE_ENDPOINT_SUCCESS,
          { sucesso: true }
        );
        this.listOperationsTable();
      })
      .catch(() =>
        this.mixpanelService.track(
          MixpanelEvents.CURRENT_INVOICE_ENDPOINT_SUCCESS,
          { sucesso: true }
        )
      )
      .finally(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }

  loadHistoricInfoInvoice() {
    this.financialService
      .getLastPaidInvoicesByCompanyId(this.companyId)
      .then((res: any) => {
        this.invoiceChartDatasource = [
          {
            name: 'Faturas',
            series: res
              .map((invoiceInfo: any) => {
                return { name: invoiceInfo.name, value: invoiceInfo.value };
              })
              .reverse(),
          },
        ];
        this.mixpanelService.track(
          MixpanelEvents.COMPARATIVE_CHART_ENDPOINT_STATUS,
          { Sucesso: true }
        );
        this.getInvoicesTable();
      })
      .catch((err) => {
        this.mixpanelService.track(
          MixpanelEvents.COMPARATIVE_CHART_ENDPOINT_STATUS,
          { Sucesso: false }
        );
        console.log(err);
      });
  }

  reloadMovimentationList() {
    this.getHistoricInvoiceStatus();
    this.resetTablePage();
    switch (this.activeTab) {
      case FinancialTabs.ACTUAL_INVOICE:
        this.loadActualInvoiceInfo(false);
        break;
      case FinancialTabs.INVOICE_HISTORIC:
        this.loadHistoricInfoInvoice();
        this.actionService.createAction({
          action: ActionsType.ACCESS_INVOICE_HISTORY_TAB,
          companyIdS4e: this.companyId,
        });
        break;
    }
  }

  displayTooltipOnInit() {
    const firstAccess = !!this.userInfo?.firstAccessFinanceiro;
    if (!firstAccess) {
      this.userInfo.firstAccessFinanceiro = true;
      localStorage.setItem('user', JSON.stringify(this.userInfo));

      this.limitDateTooltip.open();
      setTimeout(() => {
        this.limitDateTooltip.close();
      }, this.TOOLTIP_TIMER);
    }
  }
  openBlip() {
    this.blipService.openBlip('Financeiro');
    this.mixpanelService.track(MixpanelEvents.CHAT_ACCESS, {
      Origem:
        this.activeTab === FinancialTabs.ACTUAL_INVOICE
          ? 'Fatura Atual'
          : 'Histórico de faturas',
      Status: InvoiceStatusTranslated[this.invoice.invoiceStatus],
    });
  }

  openDetailedInvoice(invoiceId?: number) {
    if (
      this.invoice?.invoiceStatus === InvoiceStatus.WAITING_CLOSING &&
      this.activeTab === 0
    ) {
      this.mixpanelService.track(
        MixpanelEvents.ACCESS_SIMULATED_INVOICE_FINANCIAL
      );
    }

    if (
      this.invoice?.invoiceStatus !== InvoiceStatus.WAITING_CLOSING &&
      this.activeTab === 0
    ) {
      this.mixpanelService.track(MixpanelEvents.ACCESS_VIEW_INVOICE_FINANCIAL, {
        Competencia: this.invoice?.referralMonthYear,
      });
    }

    if (this.activeTab === 1 && this.paidInvoices?.length && invoiceId) {
      this.mixpanelService.track(MixpanelEvents.ACCESS_VIEW_INVOICE_FINANCIAL, {
        Competencia: this.paidInvoices.find(({ id }) => id === invoiceId)
          ?.competence,
      });
    }

    this.modalRefDetailedInvoice = this.modalService.open(
      ModalDetailedInvoiceComponent,
      {
        centered: true,
        windowClass: 'detailedInvoiceModalClass',
      }
    );

    if (
      (this.invoice?.invoiceStatus === InvoiceStatus.WAITING_CLOSING || this.invoice?.invoiceStatus === InvoiceStatus.INVOICE_CLOSED) &&
      this.activeTab === 0
    ) {
      this.modalRefDetailedInvoice.componentInstance.invoiceId = null;
    } else {
      this.modalRefDetailedInvoice.componentInstance.invoiceId = invoiceId
        ? invoiceId
        : null;
    }

    this.modalRefDetailedInvoice.componentInstance.getDetailedInvoiceSpreadsheet =
      (invoiceId: number) => {
        this.getDetailedInvoiceSpreadsheet(invoiceId);
      };
    this.modalRefDetailedInvoice.componentInstance.getDetailedInvoicePDF = (
      invoiceId: number
    ) => {
      this.getDetailedInvoicePDF(invoiceId);
    };
    this.actionService.createAction({
      action: ActionsType.CHECK_INVOICE,
      companyIdS4e: this.companyId,
    });
  }

  resetTablePage() {
    this.pageNumber = 1;
    this.totalRows = 0;
  }

  copyCodeToClipboard(code: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
    }
  }

  getHistoricInvoiceStatus() {
    this.financialService
      .getHistoricInvoiceStatusByCompanyId(this.companyId)
      .then((historicStatus: any) => {
        this.invoiceHistoricStatus = historicStatus.codigo;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getDetailedInvoicePDF(invoiceId?: number) {
    this.modalRefDetailedInvoice.componentInstance.isDownloadingPDF = true;
    this.financialService
      .getDetailedInvoicePDF(this.companyId, invoiceId)
      .then((res: any) => {
        this.mixpanelService.track(MixpanelEvents.DOWNLOAD_PDF_FINANCIAL, {
          Sucesso: true,
        });
        const blob = new Blob([res], { type: 'application/pdf' });
        window.open(URL.createObjectURL(blob));
      })
      .catch((err) => {
        this.showGenericErrorModal();
        this.mixpanelService.track(MixpanelEvents.DOWNLOAD_PDF_FINANCIAL, {
          Sucesso: false,
        });
      })
      .finally(() => {
        this.modalRefDetailedInvoice.componentInstance.setDownloadingPDFDisable();
      });
  }

  getDetailedInvoiceSpreadsheet(invoiceId?: number) {
    this.financialService
      .getDetailedInvoiceSpreadsheet(this.companyId, invoiceId)
      .then((res: any) => {
        this.mixpanelService.track(
          MixpanelEvents.DOWNLOAD_SPREADSHEET_FINANCIAL,
          {
            Sucesso: true,
          }
        );
        if (res.body) {
          const filename = res.headers
            .get('content-disposition')
            ?.split('filename=')[1];
          if (filename) {
            var a: HTMLAnchorElement = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            var url = window.URL.createObjectURL(new Blob([res.body]));
            a.href = url;
            a.download = filename ? filename : '';
            a.click();
            window.URL.revokeObjectURL(url);
          }
        }
      })
      .catch((err) => {
        this.showGenericErrorModal();
        this.mixpanelService.track(
          MixpanelEvents.DOWNLOAD_SPREADSHEET_FINANCIAL,
          {
            Sucesso: false,
          }
        );
      });
  }

  pageChangedOperationsTable(data: any) {
    this.pageNumber = data.pageNumber;
    this.listOperationsTable(data.cb);
  }

  pageChangedInvoicesTable(data: any) {
    this.pageNumber = data.pageNumber;
    this.getPaidInvoicesTable(data.cb);
  }

  async getInvoicesTable() {
    await this.getDueAndLateInvoicesTable();
    await this.getPaidInvoicesTable();
  }

  getPaidInvoicesTable(pageChangedCb?: Function) {
    this.isLoadingPaidInvoices = true;
    this.financialService
      .getPaidInvoicesTableInformation(
        this.companyId,
        this.pageNumber,
        this.maxPerPage
      )
      .then((invoices: any) => {
        this.paidInvoices = invoices.paidInvoices.rows;
        this.totalRows = invoices.paidInvoices.count;
        this.mixpanelService.track(
          MixpanelEvents.DUE_AND_LATE_INVOICES_ENDPOINT_STATUS,
          { Sucesso: true }
        );

        if (pageChangedCb) {
          pageChangedCb(this.pageNumber);
        }
      })
      .catch(() =>
        this.mixpanelService.track(
          MixpanelEvents.DUE_AND_LATE_INVOICES_ENDPOINT_STATUS,
          { Sucesso: false }
        )
      )
      .finally(() => {
        this.isLoadingPaidInvoices = false;
        this.cd.detectChanges();
      });
  }

  getDueAndLateInvoicesTable() {
    this.isLoadingDueAndLateInvoices = true;
    this.financialService
      .getDueAndLateInvoicesTableInformation(this.companyId)
      .then((invoices: any) => {
        this.dueInvoices = invoices.dueInvoices;
        this.lateInvoices = invoices.lateInvoices;
        this.mixpanelService.track(
          MixpanelEvents.DUE_AND_LATE_INVOICES_ENDPOINT_STATUS,
          { Sucesso: true }
        );
        this.mixpanelService.track(
          MixpanelEvents.ACCESS_INVOICE_HISTORIC_FINANCIAL,
          {
            'Faturas atrasadas': invoices.lateInvoices.length,
            'Outras Faturas em Aberto': invoices.dueInvoices.length,
          }
        );
      })
      .catch(() =>
        this.mixpanelService.track(
          MixpanelEvents.DUE_AND_LATE_INVOICES_ENDPOINT_STATUS,
          { Sucesso: false }
        )
      )
      .finally(() => {
        this.isLoadingDueAndLateInvoices = false;
        this.cd.detectChanges();
      });
  }

  listOperationsTable(pageChangedCb?: Function) {
    // TODO: remoção da paginação
    if (this.operationsRaw.length > 0) {
      this.operations = paginate(
        this.operationsRaw,
        this.maxPerPage,
        this.pageNumber
      );
      if (pageChangedCb) {
        pageChangedCb(this.pageNumber);
      }
      this.mixpanelService.track(
        MixpanelEvents.MOVIMENTATION_LIST_ENDPOINT_STATUS,
        { Sucesso: true }
      );
      return;
    }

    this.isLoading = true;
    this.financialService
      .listOperationsByCompanyId(
        this.companyId,
        this.pageNumber,
        this.maxPerPage
      )
      .then((result: any) => {
        this.operationsRaw = result.rows;
        this.operations = paginate(this.operationsRaw, 5, this.pageNumber);
        this.totalRows = result.count;

        if (pageChangedCb) {
          pageChangedCb(this.pageNumber);
        }
        this.mixpanelService.track(
          MixpanelEvents.MOVIMENTATION_LIST_ENDPOINT_STATUS,
          { Sucesso: true }
        );
      })
      .catch(() =>
        this.mixpanelService.track(
          MixpanelEvents.MOVIMENTATION_LIST_ENDPOINT_STATUS,
          { Sucesso: false }
        )
      )
      .finally(() => {
        this.isLoading = false;
        this.cd.detectChanges();
      });
  }

  async checkInvoice(companyId: number) {
    let validInvoice = false;
    await this.companyService
      .checkBankSlip(this.invoice.invoiceId)
      .then(() => {
        validInvoice = true;
      })
      .catch((error: { error: { statusCode: number; message: string } }) => {
        const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = `${error.error.message}`;
        modalRef.componentInstance.icon =
          '../../../assets/svgs/attention-modal-icon.svg';
        modalRef.componentInstance.buttonLabel = 'Tente novamente mais tarde';
      });
    return validInvoice;
  }

  async openUrlFilePage(url: string, origin: number) {
    const validInvoice = await this.checkInvoice(this.companyId);
    if (!validInvoice) {
      return;
    }
    if (url) {
      const hasHttpsOrHttp = new RegExp('https://|http://', 'g');
      window.open(url.match(hasHttpsOrHttp) ? url : `http://${url}`);
      this.actionService.createAction({
        action: ActionsType.DOWNLOAD_BANK_SLIP,
        companyIdS4e: this.companyId,
      });
    }

    switch (origin) {
      case this.openUrlFilePageOrigin.DOWNLOAD_NF_ON_CURRENT_INVOICE:
        this.mixpanelService.track(MixpanelEvents.DOWNLOAD_NF_FINANCIAL, {
          Competencia: this.invoice?.referralMonthYear,
        });
        break;
      case this.openUrlFilePageOrigin
        .DOWNLOAD_PAYMENT_INVOICE_ON_CURRENT_INVOICE:
        this.mixpanelService.track(
          MixpanelEvents.DOWNLOAD_PAYMENT_INVOICE_FINANCIAL,
          {
            Origem: 'Fatura atual',
            Competencia: this.invoice?.referralMonthYear,
          }
        );
        break;
      case this.openUrlFilePageOrigin.EMIT_SECOND_INVOICE_ON_CURRENT_INVOICE:
        this.mixpanelService.track(
          MixpanelEvents.DOWNLOAD_PAYMENT_INVOICE_FINANCIAL,
          {
            'Data de corte': this.invoice?.availableDate,
            Sucesso: true,
          }
        );
        break;
      case this.openUrlFilePageOrigin.DOWNLOAD_NF_ON_LATE_INVOICES:
        this.mixpanelService.track(MixpanelEvents.DOWNLOAD_NF_FINANCIAL, {
          Competencia: this.invoice?.referralMonthYear,
        });
        break;
      case this.openUrlFilePageOrigin.GET_PAYMENT_SLIP_ON_LATE_INVOICES:
        this.mixpanelService.track(
          MixpanelEvents.GENERATE_SECOND_PAYMENT_INVOICE_FINANCIAL,
          {
            Origem: 'Histórico de faturas',
            Competencia: this.invoice?.referralMonthYear,
          }
        );
        break;
      case this.openUrlFilePageOrigin.DOWNLOAD_NF_ON_DUE_INVOICES:
        this.mixpanelService.track(MixpanelEvents.DOWNLOAD_NF_FINANCIAL, {
          Competencia: this.invoice?.referralMonthYear,
        });
        break;
      case this.openUrlFilePageOrigin.CREATE_PAYMENT_SLIP_ON_DUE_INVOICES:
        this.mixpanelService.track(
          MixpanelEvents.GENERATE_SECOND_PAYMENT_INVOICE_FINANCIAL,
          {
            Origem: 'Histórico de faturas',
            Competencia: this.invoice?.referralMonthYear,
          }
        );
        break;
      case this.openUrlFilePageOrigin.DOWNLOAD_NF_ON_PAID_INVOICES:
        this.mixpanelService.track(MixpanelEvents.DOWNLOAD_NF_FINANCIAL, {
          Competencia: this.invoice?.referralMonthYear,
        });
        break;
      default:
        break;
    }
  }

  showGenericErrorModal() {
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Ocorreu um erro';
    modalRef.componentInstance.description =
      'Ocorreu um erro ao buscar as informações. Por favor, tente novamente.';
    modalRef.componentInstance.buttonLabel = 'Tentar novamente';
    modalRef.result.then((result) => { });
  }
}
