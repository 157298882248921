<div>
  <div class="invoice-header-container" fxLayoutAlign="space-between center" fxLayout="row">
    <div>
      <div fxLayout="row">
        <h1 class="bold">
          Fatura detalhada {{ headerInformation?.competence }} -
        </h1>
        <h1 class="regular">&nbsp;{{ headerInformation?.companyName }}</h1>
      </div>

      <div fxLayout="row">
        <div *ngIf="!!invoiceId" class="spacing-subtitle" fxLayout="row">
          <p class="p4 bold">Código da mensalidade:</p>
          <p class="p4 regular">&nbsp;{{ invoiceId }}</p>
        </div>
        <div class="spacing-subtitle" fxLayout="row">
          <p class="p4 bold">CNPJ:</p>
          <p class="p4 regular">&nbsp;{{ headerInformation?.docNumber }}</p>
        </div>
        <div class="spacing-subtitle" fxLayout="row">
          <p class="p4 bold">Nº do contrato:</p>
          <p class="p4 regular">&nbsp;{{ headerInformation?.contractNumber }}</p>
        </div>
        <div class="spacing-subtitle" fxLayout="row">
          <p class="p4 bold">Serviços contratados:</p>
          <p class="p4 regular">&nbsp;{{ headerInformation?.benefits }}</p>
        </div>
      </div>
    </div>
    <div class="agiben-logo-container">
      <svg-icon src="../../../../assets/svgs/agiben-logo-white-point.svg"></svg-icon>
    </div>
    <svg-icon (click)="onClickCloseButton()" class="close-button" src="../../../../assets/svgs/close-modal-icon.svg">
    </svg-icon>
  </div>
  <hr class="header-hr" />
  <div class="summary-container" fxLayout="row" fxLayoutAlign="space-between stretch">
    <div fxLayout="row">
      <div>
        <p class="p2 bold spacing-between-topics">Resumo da fatura</p>
        <div fxLayout="row" class="spacing-between-topics">
          <p class="p4 bold spacing-between-texts">
            Vencimento:<span class="p4 regular">&nbsp;{{ headerInformation?.dueDate }}</span>
          </p>
          <p class="p4 bold spacing-between-texts">
            Valor:<span class="p4 regular">&nbsp;{{ headerInformation?.value }}</span>
          </p>
        </div>
        <div fxLayout="row">
          <p class="p4 bold spacing-between-texts">
            Acréscimos:<span class="p4 regular spacing-between-texts">&nbsp;{{ headerInformation?.extra }}</span>
          </p>
          <p class="p4 bold spacing-between-texts">
            Descontos:<span class="p4 regular">&nbsp;{{ headerInformation?.discounts }}</span>
          </p>
          <p class="p4 bold">
            Valor total:<span class="p4 regular">&nbsp;{{ headerInformation?.totalValue }}</span>
          </p>
        </div>
      </div>
      <div class="hr-vertical"></div>
      <div fxLayout="row">
        <div>
          <p class="p2 bold spacing-between-topics">Movimentações</p>
          <div fxLayout="row" class="spacing-between-topics">
            <p class="p4 bold spacing-between-texts">
              Inclusões:<span class="p4 regular">&nbsp;{{ operations?.inclusions }}</span>
            </p>
            <p class="p4 bold">
              Cancelamentos:<span class="p4 regular">&nbsp;{{ operations?.canceled }}</span>
            </p>
          </div>
          <div fxLayout="row" class="spacing-between-topics">
            <p class="p4 bold spacing-between-texts">
              Migrações:<span class="p4 regular">&nbsp;{{ operations?.migrations }}</span>
            </p>
            <p class="p4 bold">
              Total de beneficiários:<span class="p4 regular">&nbsp;{{ operations?.total }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start end">
      <button *ngIf="isDownloadingPDF" class="btn btn-sm btn-primary outlined spacing-between-topics" disabled="true"
        type="button">
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
      </button>
      <svg-icon *ngIf="!isDownloadingPDF" (click)="getDetailedInvoicePDF(invoiceId)"
        class="spacing-between-topics buttons-detailed-invoice" src="../../../../assets/svgs/pdf-button.svg"></svg-icon>
      <svg-icon (click)="getDetailedInvoiceSpreadsheet(invoiceId)" class="buttons-detailed-invoice"
        src="../../../../assets/svgs/excel-button.svg"></svg-icon>
    </div>
  </div>
  <hr class="hr-horizontal" />
  <!-- <financial-detailed-invoices-table
    [dataSource]="dataSource"
    [scrollCallbackToBind]="scrollCallbackToBind"
  ></financial-detailed-invoices-table> -->
  <financial-detailed-invoices-table [dataSource]="dataSource">
  </financial-detailed-invoices-table>
</div>
