<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="../../../../assets/svgs/attention-modal-icon.svg"></svg-icon>
</div>
<div class="margin-content">
  <p class="bold text-center">
    Acesso bloqueado
  </p>
  <div class="description-container">
    <p class="p3 regular text-center padding-bottom">
      Você não tem permissão para acessar <br/> esta funcionalidade.
    </p>
  </div>
  <default-button (onClick)="onClick()" [disabled]="false" label="Voltar"></default-button>
</div>
