<div class="form-group">
  <label class="label-default-text-area semi-bold" [ngClass]="{ 'disabled-label': disabled }">{{ label }}</label>

  <ng-select
    class="disable-clear-all"
    [formControl]="ngControl?.control"
    [items]="options"
    bindLabel="label"
    bindValue="value"
    [(ngModel)]="selected"
    [placeholder]="placeholder"
    (change)="selectChanged()"
    [disabled]="disabled">
  </ng-select>

</div>
