import { Component, OnInit, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { CollaboratorTableMode } from '../../../enums/collaborator-table-mode';

declare interface TableItem {
  nome: string;
  cpf: string;
  plano: string;
}

@Component({
  selector: 'dependent-table',
  templateUrl: './dependent-table.component.html',
  styleUrls: ['./dependent-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DependentTableComponent implements OnInit {

  icon:string = ''
  collaboratorTableMode = CollaboratorTableMode;
  @Input() dataSource: any[] = [];
  @Input() actionMode: CollaboratorTableMode;
  @Input() labelCancelRequest: string = 'Cancelamento solicitado em';
  @Input() searchString: string = '';
  @Input() loading: boolean = true;
  @Input() maxRowsLoaded: number = 5;

  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.icon = this.actionMode === 'default' ? '../../../../assets/svgs/next-circle.svg' : '../../../../assets/svgs/cancel-circle.svg'
  }

  rowClicked(row: TableItem) {
    console.log(row)
  }

  clickButton(row: TableItem) {
    this.onButtonClicked.emit(row)
  }

}
