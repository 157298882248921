import { CdkStepper } from '@angular/cdk/stepper';
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'stepper-generic',
  templateUrl: './stepper-generic.component.html',
  styleUrls: ['./stepper-generic.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperGenericComponent }],
  encapsulation: ViewEncapsulation.None
})
export class StepperGenericComponent extends CdkStepper {

  @Input() activeClass = 'active';

  isNextButtonHidden() {
    return !(this.steps.length === this.selectedIndex + 1);
  }

}
