import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self, ViewEncapsulation
} from '@angular/core';
import { NgControl } from '@angular/forms';

export interface Options {
  value: any;
  label: string;
}

@Component({
  selector: 'default-dropdown',
  templateUrl: './default-dropdown.component.html',
  styleUrls: ['./default-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultDropdownComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';

  @Input() disabled: boolean = false;

  @Input() options: Array<Options> = [];

  @Input() selected: any;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  private onChanged!: Function;
  private onTouched!: Function;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: any) {
    if (changes?.disabled && this.ngControl?.control) {
      changes?.disabled?.currentValue || this.disabled
        ? this.ngControl?.control?.disable()
        : this.ngControl?.control?.enable();
    }
  }

  selectChanged() {
    this.selectedChange.emit(this.selected);
  }

  ngOnInit(): void {}

  writeValue(value: any): void {
    this.selected = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
