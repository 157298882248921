import { SubscriberMenuFinanceiro } from './../../services/subscriber-menu-financeiro.service';
import { Subscription } from 'rxjs';
import { SideBarService } from './../../services/sidebar.service';
import { Router } from '@angular/router';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { MixpanelService } from './../../services/mixpanel.services';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Contact } from 'src/app/components/cards/card-contact/card-contact.component';
import { Options } from 'src/app/components/inputs/default-dropdown/default-dropdown.component';
import { JwtTokenService } from 'src/app/services/auth/jwt-token.service';
import { ModalGenericStepComponent } from './../../components/modals/modal-generic-step/modal-generic-step.component';
import { ModalWithOneButtonComponent } from './../../components/modals/modal-with-one-button/modal-with-one-button.component';
import { StepperGenericComponent } from './../../components/steppers/stepper-generic/stepper-generic.component';
import { BlipService } from './../../services/blip.service';
import { ContactsService } from './../../services/contacts.service';
import { CrmService } from './../../services/crm.service';
import { FileType } from 'src/app/enums/file-type';
import { CustomValidators } from 'src/app/validators/validators';
import { capitalizeFirstLetter } from '../../utils/utils';
import { User } from 'src/app/models/user.model';
import { ModalChatComponent } from 'src/app/components/modals/modal-chat/modal-chat.component';
import { StorageService } from 'src/app/services/storage.service';

export interface ArrayOfContacts {
  title: String;
  sac: String;
  contacts?: Array<Contact>;
}

@Component({
  selector: 'contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactUsComponent implements OnInit {
  readonly ACCEPT_FILES_EXTENSIONS: FileType[] = [
    FileType.CSV,
    FileType.DOCS,
    FileType.JPEG,
    FileType.MP4,
    FileType.PDF,
    FileType.PNG,
    FileType.XLSX,
  ];
  readonly ALLOW_FILE_SIZE = 100;
  readonly CLASS_NAME_MODAL = 'crmModalClass';
  readonly maxRowsLoaded: number = 5;
  page = 1;

  activeTab: number = 1;

  isLoading: boolean = false;

  protocolFormGroup: FormGroup = new FormGroup({
    protocol: new FormControl({ value: '', disabled: false }),
  });

  contacts: ArrayOfContacts[] = [
    {
      title: 'Agiben',
      sac: 'SAC 0800 9406 333',
      contacts: [],
    },
    { title: 'Clin', sac: 'SAC 0800 0062 254' },
    { title: 'Api Saúde', sac: 'SAC 0800 9406 888' },
  ];

  subjectOptions: Options[] = [];

  subjectDetailOptions: Options[] = [];

  @ViewChild('stepperContactUs') stepper: any;
  @ViewChild('cdkStepperContactUs') cdkStepper: StepperGenericComponent;

  frmValues: object = {};

  frmStepperOne: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl({ value: null, disabled: false }, [
      Validators.required,
    ]),
    subjectDetail: new FormControl({ value: null, disabled: true }, [
      Validators.required,
    ]),
  });

  frmStepperTwo: FormGroup = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  filesList: any[] = [];
  crms: any = [];
  crmTotal: number;
  isLoadingTable = true;
  hasOnGoingCrm: boolean;
  searchString: string = '';
  isTableView: boolean = true;
  detailedCrmData: any;
  private resetMenuSubscription: Subscription;
  crmModalRef: NgbModalRef;

  constructor(
    private route: Router,
    private blipService: BlipService,
    private modalService: NgbModal,
    private crmService: CrmService,
    private contactService: ContactsService,
    private jwtService: JwtTokenService,
    private mixpanelService: MixpanelService,
    private sideBarService: SideBarService,
    private subscriberMenuFinanceiro: SubscriberMenuFinanceiro,
    private storageService: StorageService
  ) {
    this.resetMenuSubscription = this.subscriberMenuFinanceiro.data$.subscribe(
      (data) => {
        this.activeTab = data;
      }
    );
  }

  get companyId(): string {
    const { companyId } = JSON.parse(localStorage.getItem('user') ?? '') as User;
    return companyId.toString();
  }

  ngOnInit(): void {
    this.loadData()
  }

  ngAfterViewInit(): void {
    this.storageService.changes.subscribe((result) => {
      this.contacts[0].contacts = []
      this.subjectOptions = [];
      this.crmTotal = 0;
      this.crms = [];
      this.hasOnGoingCrm = false;
      this.loadData()
    });
  }

  ngOnDestroy(): void {
    this.resetMenuSubscription.unsubscribe();
  }

  loadData() {
    this.loadContactData()
    this.loadingCrms();
    this.loadCrmData()
  }

  loadContactData() {
    this.contactService
      .getContactsByCompanyId(this.jwtService.getCompanyId())
      .then((res: any) => {
        this.contacts[0].contacts = res;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  loadCrmData() {
    this.crmService
      .getAllSubjects()
      .then((res: any) => {
        this.subjectOptions = res?.map((element: any) => {
          return {
            label: capitalizeFirstLetter(element.description),
            value: element.id,
          };
        });
      })
      .catch((err: any) => {
        console.log(err);
        this.genericModal();
      });
  }

  loadingCrms() {
    this.crmService
      .listCrmsByCompany({
        companyId: this.companyId,
        page: this.page,
        offset: this.maxRowsLoaded,
      })
      .then(({ crms, crmTotal, hasOnGoingCrm }: any) => {
        this.crmTotal = crmTotal;
        this.crms = crms;
        this.isLoadingTable = false;
        this.hasOnGoingCrm = hasOnGoingCrm;
      })
      .catch((err: any) => console.log(err));
  }

  getLabelByValue(options: Options[], value: any): string | undefined {
    const index = options?.findIndex((el: Options) => el.value === value);
    if (index == -1) return undefined;
    return options[index]?.label;
  }

  openCRM() {
    this.mixpanelService.track(MixpanelEvents.CLICK_START_CRM_OPEN);

    this.crmModalRef = this.modalService.open(ModalGenericStepComponent, {
      centered: true,
      windowClass: this.CLASS_NAME_MODAL,
    });
    this.crmModalRef.componentInstance.icon =
      '../../../assets/svgs/modal-icon-edit.svg';
    this.crmModalRef.componentInstance.content = this.stepper;

    this.crmModalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  resetForms() {
    this.filesList = [];
    this.frmStepperOne.reset()
    this.frmStepperTwo.reset()
    this.loadingCrms()
  }

  subjectSelect() {
    this.frmStepperOne.get('subjectDetail')?.setValue(null);
    this.frmStepperOne.get('subjectDetail')?.disable();

    const subjectId: number = this.frmStepperOne.get('subject')?.value;
    this.crmService
      .getDetailedSubjects(subjectId)
      .then((res: any) => {
        this.subjectDetailOptions = res?.map((element: any) => {
          return {
            label: capitalizeFirstLetter(element.description),
            value: element.id,
          };
        });
        this.frmStepperOne.get('subjectDetail')?.enable();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  deleteFile(file: any) {
    this.filesList.splice(this.filesList.indexOf(file), 1);
  }

  async createCrm() {
    this.isLoading = true;

    for (const key in this.filesList) {
      const file = this.filesList[key];
      if (!file?.metaData?.finished || !file?.metaData.filenameBucket) {
        try {
          const response: any = await this.crmService.getSignedUrlFile(
            file?.file?.name
          );
          await this.crmService
            .uploadFileBySignedUrl(
              response?.url,
              response?.novoNomeArquivo,
              file?.file
            )
            .toPromise();

          this.filesList[key].metaData.filenameBucket =
            response?.novoNomeArquivo;
          this.filesList[key].metaData.finished = true;
          this.filesList[key].metaData.error = false;
        } catch (error) {
          this.filesList[key].metaData.error = true;
          this.filesList[key].metaData.finished = false;
        }
      }
    }

    const { email, name, subject, subjectDetail } = this.frmStepperOne?.value;
    const { message } = this.frmStepperTwo?.value;

    const filesFinisheds = this.filesList
      ?.filter((el: any) => el?.metaData?.finished)
      ?.map((files: any) => decodeURI(files?.metaData?.filenameBucket));
    try {
      const response: any = await this.crmService.createCrm({
        requesterName: name,
        requesterMail: email,
        reasonId: subject,
        reason: this.getLabelByValue(this.subjectOptions, subject),
        reasonDetailedId: subjectDetail,
        reasonDetailed: this.getLabelByValue(
          this.subjectDetailOptions,
          subjectDetail
        ),
        companyId: this.jwtService.getCompanyId(),
        description: message,
        nameFiles: filesFinisheds,
      });
      this.mixpanelService.track(MixpanelEvents.OPEN_CRM, {
        Assunto: this.getLabelByValue(this.subjectOptions, subject),
        AssuntoDetalhado: this.getLabelByValue(
          this.subjectDetailOptions,
          subjectDetail
        ),
        Nome: name,
        Erro: false,
        Descrição: message,
        'Arquivo em anexo': !!filesFinisheds.length,
        'Número CRM': response?.dados.protocolo,
      });
      this.resetForms()
      this.sucessCrm();
    } catch (error) {
      this.mixpanelService.track(MixpanelEvents.OPEN_CRM, {
        Assunto: this.getLabelByValue(this.subjectOptions, subject),
        AssuntoDetalhado: this.getLabelByValue(
          this.subjectDetailOptions,
          subjectDetail
        ),
        Nome: name,
        Erro: true,
        Descrição: message,
        'Arquivo em anexo': !!filesFinisheds.length,
      });
      this.errorCrm();
    }

    this.isLoading = false;
  }

  nextStep() {
    this.cdkStepper.next();
  }

  backwardStep() {
    this.cdkStepper.reset();
  }

  contactUsOnChat() {
    this.blipService.openBlip('fale conosco');
  }

  sucessCrm() {
    this.crmModalRef?.close();
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
      windowClass: 'crmModalFinish',
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/success-modal-icon.svg';
    modalRef.componentInstance.title = 'Chamado aberto com sucesso';
    modalRef.componentInstance.description =
      '<strong><span style="color: #D9534F">Atenção:</span></strong> acompanhe diariamente os chamados em andamento para não perder as atualizações.';
    modalRef.componentInstance.buttonLabel = 'OK';

    modalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  errorCrm() {
    this.crmModalRef?.close();
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
      windowClass: 'crmModalFinish',
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Erro ao enviar';
    modalRef.componentInstance.description =
      'Ocorreu um erro ao enviar as informações. Por favor, tente novamente.';
    modalRef.componentInstance.buttonLabel = 'Tentar novamente';

    modalRef.result.then((result) => {
      this.openCRM();
      console.log(`Closed with: ${result}`);
    });
  }

  unsupportedFile(file: any) {
    this.filesList = this.filesList?.filter((el: any) => !el?.metaData?.error);
    this.hideOrShowModalByClass(this.CLASS_NAME_MODAL, true);

    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
      windowClass: 'crmModalFinish',
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Erro no arquivo';
    modalRef.componentInstance.description =
      'O arquivo é inválido. Verifique o formato e o tamanho e tente novamente.';
    modalRef.componentInstance.buttonLabel = 'OK';

    modalRef.result.then((result) => {
      this.hideOrShowModalByClass('crmModalClass', false);
    });
  }

  genericModal() {
    this.modalService.dismissAll();

    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
      windowClass: 'crmModalFinish',
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Erro ao carregar dados';
    modalRef.componentInstance.description =
      'Desculpe. Ocorreu um erro inesperado. Entre em contato com o administrador.';
    modalRef.componentInstance.buttonLabel = 'Tentar novamente';

    modalRef.result.then((result) => {
      this.sideBarService.getSideBarMenu().navigate('/home/homepage');
    });
  }

  closeCrm() {
    this.crmModalRef?.close();
    this.frmStepperOne.reset();
    this.frmStepperTwo.reset();
    this.filesList = [];
  }

  hideOrShowModalByClass(classname: string, hide: boolean) {
    const modalRef = document.getElementsByClassName(
      classname
    )[0] as HTMLElement;
    modalRef.style.visibility = hide ? 'hidden' : '';
  }

  sendMetric() {
    this.mixpanelService.track(MixpanelEvents.VIDEO_PAGE_ACCESS);
  }

  showActiveTab() {
    console.log(this.activeTab);
  }

  listCrmsByCompanyId(page: number, filter?: string) {
    this.isLoadingTable = true;
    this.crmService
      .listCrmsByCompany({
        companyId: this.companyId,
        page,
        offset: this.maxRowsLoaded,
        filter,
      })
      .then(({ crms, crmTotal }: any) => {
        this.crmTotal = crmTotal;
        this.crms = crms;
        this.isLoadingTable = false;
      })
      .catch((err: any) => console.log(err));
  }

  listProtocols() {
    this.listCrmsByCompanyId(1, this.protocolFormGroup.get('protocol')?.value);
    this.searchString = this.protocolFormGroup.get('protocol')?.value;
  }

  pageChanged(event: any) {
    this.listCrmsByCompanyId(
      event.pageNumber,
      this.protocolFormGroup.get('protocol')?.value
    );
    event.cb(event.pageNumber);
  }

  onRowClick(protocol: string) {
    this.crmService
      .getDetailedCrm({
        companyId: this.companyId,
        protocol,
      })
      .then((response) => {
        this.detailedCrmData = response;
      })
      .catch((err) => console.error(err));

    this.isTableView = false;
  }

  backToTableView() {
    this.isTableView = true;
  }

  situationTextColor(situation: string) {
    switch (situation.toLowerCase()) {
      case 'em andamento':
        return '#1ABC77 !important';
      case 'finalizado':
        return '#ADAFB4 !important';
      case 'aberto':
        return '#F2AB27 !important';
      default:
        return '#ADAFB4 !important';
    }
  }

  openChatHistory() {
    const modalRef = this.modalService.open(ModalChatComponent, {
      centered: true,
      windowClass: 'chatModal',
    });
    modalRef.componentInstance.messages = this.detailedCrmData.messages;
  }
}
