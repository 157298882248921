<table class="table table-bordered" fxLayout="column">
  <thead class="custom-header-bordered" [hidden]="!dataSource.length && !loading">
    <tr>
      <th fxFlex="35" fxLayout="column" fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span class="header-title bold p3">Nome e CPF</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="center center">
        <span class="header-title bold p3">Vinculo</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="center center">
        <span class="header-title bold p3">Plano</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="center center">
        <span class="header-title bold p3">Adesão</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="center center">
        <span class="header-title bold p3"></span>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="dataSource.length && !loading; else noResultsFound">
    <tr [ngClass]="{
        'status-cancel-request':
          row.dateCancelRequest && actionMode == collaboratorTableMode.DELETE
            ? true
            : false
      }" *ngFor="let row of dataSource">
      <td class="custom-border-left" fxFlex="35" fxLayout="column" fxLayoutGap="1.5vmin" fxLayoutAlign="start start">
        <span class="name-title semi-bold p3">{{ row.name | titlecase }}</span>
        <span class="cpf-subtitle regular p4">CPF: {{ row.cpf | cpf }}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="center center">
        <span class="regular p3">{{ row.typeBeneficiary }}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="6vmin" fxLayoutAlign="center center">
        <span class="regular p3">{{ row.plan }}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="center center">
        <span class="regular p3">{{ row.dateAdhesion }}</span>
      </td>

      <ng-container *ngIf="row.dateCancelRequest; else elseActionButton">
        <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="center center">
          <div *ngIf="row.dateCancelRequest">
            <tooltip-cancel [text]="labelCancelRequest + ' ' + row.dateCancelRequest" [actionMode]="actionMode">
            </tooltip-cancel>
          </div>
        </td>
      </ng-container>
      <ng-template #elseActionButton>
        <td fxFlex class="custom-border-right no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin"
          fxLayoutAlign="center center">
          <svg-icon [ngClass]="{
              'status-cancel-request-no-visibility':
                row.dateCancelRequest &&
                actionMode == collaboratorTableMode.DELETE
                  ? true
                  : false,
              'btn-icon': actionMode === collaboratorTableMode.DEFAULT,
              'btn-icon-cancel': actionMode === collaboratorTableMode.DELETE
            }" (click)="clickButton(row)" src="{{ icon }}"></svg-icon>
        </td>
      </ng-template>
    </tr>
  </tbody>

  <tbody [hidden]="!loading">
    <tr *ngFor="let i of [].constructor(maxRowsLoaded)">
      <td fxFlex="35" class="custom-border-left" fxLayout="column">
        <ngx-skeleton-loader [theme]="{
            background:
              'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
            'border-radius': '6px',
            width: '236px',
            height: '35px'
          }"></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{
            background:
              'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
            'border-radius': '6px',
            width: '236px',
            height: '15px'
          }"></ngx-skeleton-loader>
      </td>
      <td fxFlex="30" class="no-side-padding"></td>
      <td fxFlex>
        <ngx-skeleton-loader [theme]="{
            background:
              'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
            'border-radius': '6px',
            width: '150px',
            height: '50px'
          }">
        </ngx-skeleton-loader>
      </td>
      <td fxFlex>
        <ngx-skeleton-loader [theme]="{
            background:
              'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
            'border-radius': '6px',
            width: '90px',
            height: '50px'
          }">
        </ngx-skeleton-loader>
      </td>
      <td fxFlex class="custom-border-right">
        <ngx-skeleton-loader [theme]="{
            background:
              'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
            'border-radius': '6px',
            width: '130px',
            height: '50px'
          }">
        </ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #noResultsFound>
  <div class="no-data-found" *ngIf="
      !dataSource.length && searchString && !loading;
      else noDataRegisteredYet
    " fxFlex fxLayoutAlign="start center">
    <p class="label-no-data-found p3 regular">
      Não há resultados para ”<strong>{{ searchString }}</strong>”.
    </p>
  </div>
</ng-template>

<ng-template #noDataRegisteredYet>
  <div *ngIf="!dataSource.length && !searchString && !loading" class="no-data-registered" fxFlex
    fxLayoutAlign="center center">
    <p class="label-no-data-registered p1 bold">
      Ainda não há beneficiários cadastrados na plataforma.
    </p>
  </div>
</ng-template>
