import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DefaultModalReviewComponent } from '../components/modals/modal-reviews/default-modal-review/default-modal-review.component';
import { ModalReviewComponent } from '../components/modals/modal-reviews/modal-review/modal-review.component';
import { ActionsType } from '../enums/action-type';
import { MixpanelEvents } from '../enums/mixpanelEvents';
import { ReviewTypes } from '../enums/review-types';
import { Review } from '../models/review.model';
import { User } from '../models/user.model';
import { ReviewService } from './review.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
  companyId: number;

  constructor(private modalService: NgbModal, private reviewService: ReviewService) {
  }

  openReviewModal(action: ActionsType) {
    switch (action) {
      case ActionsType.INDIVIDUAL_INCLUSION:
        this.openIndividualInclusionReviewModal();
        break;
      case ActionsType.FINISH_COLABORATOR_INDIVIDUAL_CANCEL:
        this.openIndividualCancellationReviewModal();
        break;
      case ActionsType.INCLUDED_BY_SPREADSHEET:
        this.openInclusionBySpreadsheetReviewModal();
        break;
      case ActionsType.PLATFORM_ACCESS:
        this.openCSATReviewModal();
        break;
      case ActionsType.ACCESS_INVOICE_HISTORY_TAB:
        this.openFinancialReviewModal();
        break;
      case ActionsType.CHECK_INVOICE:
        this.openFinancialReviewModal();
        break;
      case ActionsType.DOWNLOAD_BANK_SLIP:
        this.openFinancialReviewModal();
        break;
      default:
        break;
    }
  }

  private openCSATReviewModal() {
    return this.modalService.open(DefaultModalReviewComponent, {
      centered: true,
      windowClass: 'reviewModal',
    })
      .result
      .then((result) => {
        console.log(`Closed with: ${result}`);
      }, (_) => {
        this.insertReview(ReviewTypes.EVALUATION_CSAT);
      });
  }



  private openIndividualInclusionReviewModal() {
    const modalRef = this.modalService.open(ModalReviewComponent, {
      centered: true,
      windowClass: 'reviewModal',
    });

    modalRef.componentInstance.title = 'O quão fácil você achou o <br/>processo de inclusão individual do beneficiário?';
    modalRef.componentInstance.metricName = MixpanelEvents.CES_INDIVIDUAL_HOLDER_INCLUSION;
    modalRef.componentInstance.reviewType = ReviewTypes.EVALUATION_INDIVIDUAL_INCLUSION;
    modalRef
      .result
      .then((result) => {
        console.log(`Closed with: ${result}`);
      }, (_) => {
        this.insertReview(ReviewTypes.EVALUATION_INDIVIDUAL_INCLUSION);
      });
  }

  private openIndividualCancellationReviewModal() {
    const modalRef = this.modalService.open(ModalReviewComponent, {
      centered: true,
      windowClass: 'reviewModal',
    });

    modalRef.componentInstance.title = 'O quão fácil você achou o <br/>processo de cancelamento individual do beneficiário?';
    modalRef.componentInstance.metricName = MixpanelEvents.CES_INDIVIDUAL_HOLDER_CANCELLATION;
    modalRef.componentInstance.reviewType = ReviewTypes.EVALUATION_INDIVIDUAL_CANCELLATION;
    modalRef
      .result
      .then((result) => {
        console.log(`Closed with: ${result}`);
      }, (_) => {
        this.insertReview(ReviewTypes.EVALUATION_INDIVIDUAL_CANCELLATION);
      });
  }

  private openInclusionBySpreadsheetReviewModal() {
    const modalRef = this.modalService.open(ModalReviewComponent, {
      centered: true,
      windowClass: 'reviewModal',
    });
    modalRef.componentInstance.title = 'O quão fácil você achou o <br/>processo de inclusão por planilha do beneficiário?';
    modalRef.componentInstance.metricName = MixpanelEvents.CES_HOLDER_INCLUSION_BY_SPREADSHEET;
    modalRef.componentInstance.reviewType = ReviewTypes.EVALUATION_INCLUSION_BY_SPREADSHEET;
    modalRef
      .result
      .then((result) => {
        console.log(`Closed with: ${result}`);
      }, (_) => {
        this.insertReview(ReviewTypes.EVALUATION_INCLUSION_BY_SPREADSHEET);
      });
  }

  private openFinancialReviewModal() {
    const modalRef = this.modalService.open(ModalReviewComponent, {
      centered: true,
      windowClass: 'reviewModal',
    });
    modalRef.componentInstance.title = 'O quão fácil tem sido utilizar<br/>a funcionalidade Financeiro?';
    modalRef.componentInstance.type = 'RADIO_GROUP';
    modalRef.componentInstance.metricName = MixpanelEvents.CES_FINANCIAL;
    modalRef
      .result
      .then((result) => {
        console.log(`Closed with: ${result}`);
      }, (_) => {
        this.insertReview(ReviewTypes.EVALUATION_FINANCIAL_DOWNLOAD_PAYMENT_SLIP);
        this.insertReview(ReviewTypes.EVALUATION_FINANCIAL_CHECK_INVOICE);
        this.insertReview(ReviewTypes.EVALUATION_FINANCIAL_HISTORIC_INVOICE);
      });
  }

  private insertReview(reviewType: ReviewTypes) {
    try {
      const { companyId } = JSON.parse(localStorage.getItem('user') ?? '') as User;
      this.companyId = companyId
    } catch (error) {
      this.companyId = 0
    }
    const review: Review = {
      companyId: this.companyId,
      comment: '',
      rate: -1,
      reviewType: reviewType,
    }
    this.reviewService.insertReview(review)
  }
}
