import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-cancelled-contract',
  templateUrl: './modal-cancelled-contract.component.html',
  styleUrls: ['./modal-cancelled-contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCancelledContractComponent implements OnInit {
  show: boolean = false;

  constructor(public activeModal: NgbActiveModal) {}
  ngOnInit(): void {}

  toggle() {
    this.show = !this.show;
  }

  onClick() {
    this.activeModal.close();
  }
}
