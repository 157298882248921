import { TooltipComponent } from '../components/tables/tooltip-cancel/tooltip-cancel.component';
import { SubscriberMenuFinanceiro } from './../services/subscriber-menu-financeiro.service';
import { CdkStepperModule } from '@angular/cdk/stepper';
import {
  CommonModule,
  CurrencyPipe,
  registerLocaleData,
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { BannerComponent } from '../components/banner/banner.component';
import { ExtraButtonComponent } from '../components/buttons/extra-button/extra-button.component';
import { HelpButtonComponent } from '../components/buttons/help-button/help-button.component';
import { TertiaryButtonComponent } from '../components/buttons/tertiary-button/tertiary-button.component';
import { CardContactComponent } from '../components/cards/card-contact/card-contact.component';
import { CardInvoiceStatusInfoComponent } from '../components/cards/card-invoice-status-info/card-invoice-status-info.component';
import { DefaultChipComponent } from '../components/chips/default-chip/default-chip.component';
import { DragAndDropFilesComponent } from '../components/drag-and-drop-files/drag-and-drop-files.component';
import { DragAndDropFilesDirective } from '../components/drag-and-drop-files/drag-and-drop-files.directive';
import { DragAndDropUploadComponent } from '../components/drag-and-drop-upload/drag-and-drop-upload.component';
import { DragAndDropUploadDirective } from '../components/drag-and-drop-upload/drag-and-drop-upload.directive';
import { HeaderComponent } from '../components/header/header.component';
import { DefaultDropdownComponent } from '../components/inputs/default-dropdown/default-dropdown.component';
import { DefaultTextAreaComponent } from '../components/inputs/default-text-area/default-text-area.component';
import { SearchInputComponent } from '../components/inputs/search-input/search-input.component';
import { ModalCancelledContractComponent } from '../components/modals/modal-cancelled-contract/modal-cancelled-contract.component';
import { ModalDoNotHaveAccessPermissionComponent } from '../components/modals/modal-do-not-have-access-permission/modal-do-not-have-access-permission.component';
import { ModalFirstAccessComponent } from '../components/modals/modal-first-access/modal-first-access.component';
import { ModalGenericStepComponent } from '../components/modals/modal-generic-step/modal-generic-step.component';
import { ModalWithOneButtonComponent } from '../components/modals/modal-with-one-button/modal-with-one-button.component';
import { DefaultPaginatorComponent } from '../components/paginator/default-paginator/default-paginator.component';
import { DefaultPillComponent } from '../components/pills/default-pill/default-pill.component';
import { QuickAccessItemComponent } from '../components/quick-access-item/quick-access-item.component';
import { QuickAccessComponent } from '../components/quick-access/quick-access.component';
import { RadioGroupComponent } from '../components/radio-groups/radio-group/radio-group.component';
import { ReviewRadioGroupComponent } from '../components/radio-groups/review-radio-group/review-radio-group.component';
import { CardsTableComponent } from '../components/tables/cards-table/cards-table.component';
import { CooperatorTableComponent } from '../components/tables/cooperator-table/cooperator-table.component';
import { FinancialOperationsTableComponent } from '../components/tables/financial-operations-table/financial-operations-table.component';
import { UpdatePasswordComponent } from '../components/update-password/update-password.component';
import { CardsPageComponent } from '../pages/cards-page/cards-page.component';
import { FinancialComponent } from '../pages/financial/financial.component';
import { BirthdayPipe } from '../pipes/birthday.pipe';
import { CpfPipe } from '../pipes/cpf.pipe';
import { DynamicPipe } from '../pipes/dynamic.pipe';
import { PhonePipe } from '../pipes/phone.pipe';
import { DefaultButtonComponent } from './../components/buttons/default-button/default-button.component';
import { QuarternaryLinkButtonComponent } from './../components/buttons/quarternary-link-button/quarternary-link-button.component';
import { WhiteButtonComponent } from './../components/buttons/white-button/white-button.component';
import { ChangePasswordComponent } from './../components/change-password/change-password.component';
import { HeroComponent } from './../components/hero/hero.component';
import { HomepageComponent } from './../components/homepage/homepage.component';
import { DefaultInputComponent } from './../components/inputs/default-input/default-input.component';
import { MessageErrorComponent } from './../components/message-error/message-error.component';
import { ModalBlockedAccessComponent } from './../components/modals/modal-blocked-access/modal-blocked-access.component';
import { ModalDetailedInvoiceComponent } from './../components/modals/modal-detailed-invoice/modal-detailed-invoice.component';
import { ModalLogoutComponent } from './../components/modals/modal-logout/modal-logout.component';
import { MyProfilePageComponent } from './../components/my-profile-page/my-profile-page.component';
import { SpanWarningComponent } from './../components/span-warning/span-warning.component';
import { StepperGenericComponent } from './../components/steppers/stepper-generic/stepper-generic.component';
import { SvgComponent } from './../components/svg/svg.component';
import { DefaultTabsModule } from './../components/tabs/default-tabs/default-tabs.module';
import { ContactUsComponent } from './../pages/contact-us/contact-us.component';
import { CooperatorComponent } from './../pages/cooperator/cooperator.component';

import localePt from '@angular/common/locales/pt';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CardHelpChatComponent } from '../components/cards/card-help-chat/card-help-chat.component';
import { InvoiceLineChartComponent } from '../components/charts/invoice-line-chart/invoice-line-chart.component';
import { ChatInputComponent } from '../components/inputs/chat-input/chat-input.component';
import { ModalChatComponent } from '../components/modals/modal-chat/modal-chat.component';
import { ModalInvoiceCodeComponent } from '../components/modals/modal-invoice-code/modal-invoice-code.component';
import { DefaultModalReviewComponent } from '../components/modals/modal-reviews/default-modal-review/default-modal-review.component';
import { ModalReviewTextAreaComponent } from '../components/modals/modal-reviews/modal-review-text-area/modal-review-text-area.component';
import { ModalReviewComponent } from '../components/modals/modal-reviews/modal-review/modal-review.component';
import { ModalWithTwoButtonsComponent } from '../components/modals/modal-with-two-buttons/modal-with-two-buttons.component';
import { FinancialDetailedInvoicesTableComponent } from '../components/tables/financial-detailed-invoices-table/financial-detailed-invoices-table.component';
import { FinancialInvoicesHistoricTableComponent } from '../components/tables/financial-invoices-historic-table/financial-invoices-historic-table.component';
import { ProtocolTableComponent } from '../components/tables/protocol-table/protocol-table.component';
import { NgbdSortableHeader } from '../components/tables/sortable-header-directive';
import { InfiniteScrollerDirective } from '../directives/infinite-scroller.directive';
import { CardBeneficiaryTypeComponent } from '../components/cards/card-beneficiary-type/card-beneficiary-type.component';
import { RadioInputComponent } from '../components/radio-input/radio-input.component';
import { RadioPlanSelectionComponent } from '../components/radio-input/radio-plan-selection/radio-plan-selection.component';
import { captalizeWordsPipe } from '../pipes/capitalizeWords.pipe';
import { DependentTableComponent } from '../components/tables/dependent-table/dependent-table.component';
import { PricePipe } from '../pipes/price.pipe';
import { BannerOnboardingComponent } from '../components/banner-onboarding/banner-onboarding.component';
import { ModalBenefitsShareComponent } from '../components/modals/modal-benefits-share/modal-benefits-share.component';
import { SubscriberSpreadSheet } from '../services/subscriber-spreadsheet.service';
import { DefaultCheckboxComponent } from '../components/checkboxes/default-checkbox/default-checkbox.component';
import { ModalCompanySwitcherComponent } from '../components/modals/modal-componay-switcher/modal-company-switcher.component';
import { CompanySwitcherTableComponent } from '../components/tables/company-switcher-table/company-switcher-table.component';
import { ModalInformativeComponent } from '../components/modals/modal-informative/modal-informative.component';

registerLocaleData(localePt, 'pt');

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    BannerComponent,
    BirthdayPipe,
    CardContactComponent,
    CardsPageComponent,
    ChangePasswordComponent,
    ContactUsComponent,
    CooperatorComponent,
    CpfPipe,
    DefaultButtonComponent,
    DefaultDropdownComponent,
    DefaultInputComponent,
    DragAndDropFilesComponent,
    DragAndDropFilesDirective,
    DragAndDropUploadComponent,
    DragAndDropUploadDirective,
    DefaultTextAreaComponent,
    DynamicPipe,
    FinancialDetailedInvoicesTableComponent,
    HeaderComponent,
    HeroComponent,
    HomepageComponent,
    MessageErrorComponent,
    ModalBlockedAccessComponent,
    ModalCancelledContractComponent,
    ModalDetailedInvoiceComponent,
    ModalDoNotHaveAccessPermissionComponent,
    ModalFirstAccessComponent,
    ModalGenericStepComponent,
    ModalLogoutComponent,
    ModalWithOneButtonComponent,
    ModalWithTwoButtonsComponent,
    ModalCompanySwitcherComponent,
    ModalInformativeComponent,
    MyProfilePageComponent,
    PhonePipe,
    PricePipe,
    QuarternaryLinkButtonComponent,
    QuickAccessComponent,
    QuickAccessItemComponent,
    WhiteButtonComponent,
    RadioGroupComponent,
    SpanWarningComponent,
    StepperGenericComponent,
    SvgComponent,
    TertiaryButtonComponent,
    UpdatePasswordComponent,
    SearchInputComponent,
    DefaultChipComponent,
    ExtraButtonComponent,
    CardsTableComponent,
    CompanySwitcherTableComponent,
    DefaultPaginatorComponent,
    CooperatorTableComponent,
    DependentTableComponent,
    DefaultPillComponent,
    FinancialComponent,
    CardInvoiceStatusInfoComponent,
    HelpButtonComponent,
    FinancialOperationsTableComponent,
    CardHelpChatComponent,
    InvoiceLineChartComponent,
    NgbdSortableHeader,
    FinancialInvoicesHistoricTableComponent,
    ModalInvoiceCodeComponent,
    InfiniteScrollerDirective,
    ModalReviewComponent,
    ReviewRadioGroupComponent,
    ModalReviewTextAreaComponent,
    DefaultModalReviewComponent,
    ProtocolTableComponent,
    ModalChatComponent,
    ChatInputComponent,
    CardBeneficiaryTypeComponent,
    RadioInputComponent,
    RadioPlanSelectionComponent,
    TooltipComponent,
    captalizeWordsPipe,
    BannerOnboardingComponent,
    ModalBenefitsShareComponent,
    DefaultCheckboxComponent
  ],
  imports: [
    AngularSvgIconModule.forRoot(),
    CdkStepperModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    ReactiveFormsModule,
    DefaultTabsModule,
    NgxChartsModule,
  ],
  exports: [
    AngularSvgIconModule,
    BannerComponent,
    BirthdayPipe,
    CardContactComponent,
    CardsPageComponent,
    ChangePasswordComponent,
    ContactUsComponent,
    CooperatorComponent,
    CpfPipe,
    DefaultButtonComponent,
    DefaultDropdownComponent,
    DefaultInputComponent,
    DragAndDropFilesComponent,
    DragAndDropFilesDirective,
    DragAndDropUploadComponent,
    DragAndDropUploadDirective,
    DefaultTextAreaComponent,
    DynamicPipe,
    FinancialDetailedInvoicesTableComponent,
    FlexLayoutModule,
    FormsModule,
    HeaderComponent,
    HeroComponent,
    HomepageComponent,
    MessageErrorComponent,
    ModalBlockedAccessComponent,
    ModalCancelledContractComponent,
    ModalDetailedInvoiceComponent,
    ModalDoNotHaveAccessPermissionComponent,
    ModalFirstAccessComponent,
    ModalGenericStepComponent,
    ModalLogoutComponent,
    ModalWithOneButtonComponent,
    ModalWithTwoButtonsComponent,
    ModalCompanySwitcherComponent,
    ModalInformativeComponent,
    MyProfilePageComponent,
    NgbModule,
    NgSelectModule,
    NgxMaskModule,
    NgxSkeletonLoaderModule,
    PhonePipe,
    PricePipe,
    QuarternaryLinkButtonComponent,
    QuickAccessComponent,
    QuickAccessItemComponent,
    RadioGroupComponent,
    ReactiveFormsModule,
    SpanWarningComponent,
    StepperGenericComponent,
    SvgComponent,
    TertiaryButtonComponent,
    UpdatePasswordComponent,
    WhiteButtonComponent,
    WhiteButtonComponent,
    SearchInputComponent,
    DefaultChipComponent,
    ExtraButtonComponent,
    CardsTableComponent,
    CompanySwitcherTableComponent,
    DefaultPaginatorComponent,
    CooperatorTableComponent,
    DependentTableComponent,
    DefaultPillComponent,
    FinancialComponent,
    CardInvoiceStatusInfoComponent,
    HelpButtonComponent,
    FinancialOperationsTableComponent,
    CardHelpChatComponent,
    InvoiceLineChartComponent,
    NgbdSortableHeader,
    FinancialInvoicesHistoricTableComponent,
    ModalInvoiceCodeComponent,
    InfiniteScrollerDirective,
    ModalReviewComponent,
    ReviewRadioGroupComponent,
    ModalReviewTextAreaComponent,
    DefaultModalReviewComponent,
    ProtocolTableComponent,
    ModalChatComponent,
    ChatInputComponent,
    CardBeneficiaryTypeComponent,
    RadioInputComponent,
    RadioPlanSelectionComponent,
    TooltipComponent,
    captalizeWordsPipe,
    BannerOnboardingComponent,
    ModalBenefitsShareComponent,
    DefaultCheckboxComponent
  ],
  providers: [
    SubscriberMenuFinanceiro,
    SubscriberSpreadSheet,
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    CurrencyPipe,
  ],
})
export class SharedModule {}
