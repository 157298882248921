import { Observable, firstValueFrom } from 'rxjs';
import { JwtTokenService } from './auth/jwt-token.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NewDependent } from '../models/new-dependent.model';
import { FilterData } from '../utils/filterData';
import {
  getFilterParams
} from 'src/app/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private readonly COMPANY_URL = '/api/companies';
  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) { }

  getAccrualDates(id: number) {
    return this.http
      .get(`${this.COMPANY_URL}/${id}/accrual-dates`, {
        headers: this.jwtTokenService.getHeaderAuth().headers,
      })
      .toPromise();
  }

  createNewDependent(id: number, codAssociate: number, newDependent: NewDependent[]) {
    return firstValueFrom(this.http
      .post(
        `${this.COMPANY_URL}/${id}/add-dependent/codAssociate/${codAssociate}`,
        { dependents: newDependent },
        this.jwtTokenService.getHeaderAuth()
      ))
  }
  checkBankSlip(companyId: number) {
    return firstValueFrom(this.http
      .get(
        `${this.COMPANY_URL}/bank-slip/${companyId}`,
        this.jwtTokenService.getHeaderAuth()
      ))
  }

  listCompaniesByFilter(filterData: FilterData): Observable<any> {
    const params = getFilterParams(filterData);
    return this.http.get(`${this.COMPANY_URL}/s4e`, { params });
  }

}
