import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {

  transform(cpf: string): string {
    let formattedCpf = cpf;

    if (!formattedCpf) {
      return ''
    }

    formattedCpf = cpf?.slice(0, 3) + '.' + cpf?.slice(3, 6) + '.' + cpf?.slice(6, 9) + '-' + cpf?.slice(9, 11);

    return formattedCpf;
  }

}
