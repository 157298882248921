import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'quick-access-item',
  templateUrl: './quick-access-item.component.html',
  styleUrls: ['./quick-access-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickAccessItemComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  constructor() { }

  ngOnInit(): void {
  }

}
