import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DueAndLateInvoicesTableInformation } from 'src/app/models/due-and-late-invoices-table-information.model';
import { FinancialService } from 'src/app/services/financial.service';
import { User } from './../../models/user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalBenefitsShareComponent } from '../modals/modal-benefits-share/modal-benefits-share.component';

@Component({
  selector: 'homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HomepageComponent implements OnInit {
  companyId: number
  hasADebit: boolean
  competence: string
  companyName: string;
  link: string;
  response: DueAndLateInvoicesTableInformation;

  loading: boolean = true;
  constructor(private financialService: FinancialService, private cd: ChangeDetectorRef, private modalService: NgbModal) {

    const { companyId, companyName, cctCompanyInfo } = JSON.parse(localStorage.getItem('user') ?? '') as User;
    this.companyId = companyId;
    this.companyName = companyName;
    this.link = cctCompanyInfo?.urlLandingPageColaborador ?? '';
    this.requestHistoricInvoiceStatus()
  }

  ngOnInit(): void {
  }

  get getCompanyName(): string {
    const { companyName } = JSON.parse(localStorage.getItem('user') ?? '') as User;
    return this.companyName = companyName;
  }

  async requestHistoricInvoiceStatus(): Promise<void> {
    if (this.companyId) {
      this.response = await this.financialService.getDueAndLateInvoicesTableInformation(this.companyId) as DueAndLateInvoicesTableInformation
      const { lateInvoices } = this.response
      const [firstLateInvoice] = lateInvoices

      this.hasADebit = !!lateInvoices.length
      this.competence = firstLateInvoice?.competence
      this.loading = false;
      this.cd.detectChanges()
    }
  }

  openModalBenefitsShare() {

    const modalRef = this.modalService.open(ModalBenefitsShareComponent, {
      centered: true,
    })

    modalRef.componentInstance.link = this.link
    modalRef.componentInstance.message = `Olá, colaborador(a)! Seu plano já está ativo:\nvem saber mais sobre todos os benefícios.\nAcesse o link: ${this.link}`
    modalRef.componentInstance.icon = '../../../../../assets/svgs/modal-icon-share.svg';
  }
}
