<span triggers="click" tooltipClass="my-custom-class" placement="top" container="body" class="d-inline-block">
  <ng-template #tooltipInstance let-tooltip="tooltip">
    <div [ngClass]="{'status-cancel-request': text && actionMode == collaboratorTableMode.DEFAULT, 'tooltip-box': true}"
      *ngIf="text">
      <div class="tooltip-text">
        {{text}}
      </div>
      <div>
        <svg-icon class="icon-due-payment" src="../../../../assets/svgs/icon-close-white.svg"></svg-icon>
      </div>
    </div>
  </ng-template>
</span>
<span triggers="click" [autoClose]="'inside'" tooltipClass="my-custom-class" [ngbTooltip]="tooltipInstance"
  placement="top" container="body" class="d-inline-block">
  <svg-icon class="icon-due-payment" src="../../../../assets/svgs/info-cancell.svg"></svg-icon>
</span>
