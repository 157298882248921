import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'default-chip',
  templateUrl: './default-chip.component.html',
  styleUrls: ['./default-chip.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultChipComponent implements OnInit {

  @Input() label: string = ''
  @Input() canRemove: boolean = true;

  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }


  removeChipClicked(value: string) {
    this.onRemove.emit(value);
  }

}