<table class="table table-bordered" fxLayout="column">
    <thead [hidden]="!dataSource.length && !loading">
        <tr>
            <th fxFlex="50" fxLayout="column" fxLayoutGap="1vmin" fxLayoutAlign="start start">
                <span class="header-title bold p3">Nome e CPF</span>
            </th>
            <th fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="start center">
                <span class="header-title bold p3">Carteirinhas</span>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="dataSource.length && !loading;else noResultsFound">
        <tr *ngFor="let row of dataSource">
            <td class="custom-border-left" fxFlex="50" fxLayout="column" fxLayoutGap="1.5vmin"
                fxLayoutAlign="start start">
                <span class="name-title semi-bold p3">{{row.name | titlecase}}</span>
                <span class="cpf-subtitle regular p4">CPF: {{row.cpf | cpf}}</span>
            </td>
            <td class="custom-border-right" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start center">
                <extra-button *ngFor="let benefit of row.benefits" [label]="benefit.label" [typeStyle]="benefit.code"
                    (onClick)="benefitClicked(row.name, row.cpf, row.codBeneficiary, benefit)"></extra-button>
            </td>
        </tr>
    </tbody>

    <tbody [hidden]="!loading">
        <tr *ngFor="let i of [].constructor(maxRowsLoaded)">
            <td fxFlex="50" class="custom-border-left" fxLayout="column">
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '236px',
                    'height': '35px'
                    }"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '236px',
                    'height': '15px'
                    }"></ngx-skeleton-loader>
            </td>
            <td class="custom-border-right" fxFlex fxLayoutGap="1.5vmin">
                <ngx-skeleton-loader [theme]="{
                'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                'border-radius': '6px',
                'width': '150px',
                'height': '50px'
                }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '90px',
                    'height': '50px'
                    }">
                </ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '130px',
                    'height': '50px'
                    }">
                </ngx-skeleton-loader>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #noResultsFound>
    <div *ngIf="!dataSource.length && searchString && !loading;else noDataRegisteredYet"  class="no-data-found" fxFlex
        fxLayoutAlign="start center">
        <p class="label-no-data-found p3 regular"> Não há resultados para
            ”<strong>{{searchString}}</strong>”.</p>
    </div>
</ng-template>

<ng-template #noDataRegisteredYet>
    <div *ngIf="!dataSource.length && !searchString && !loading" class="no-data-registered" fxFlex
        fxLayoutAlign="center center">
        <p class="label-no-data-registered p1 bold">Ainda não há beneficiários cadastrados na plataforma.</p>
    </div>
</ng-template>
