<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
    <svg-icon src="{{ icon }}"></svg-icon>
</div>
<div class="margin-content">
    <svg-icon (click)="closeModal(false)" class="close-button"
        src="../../../assets/svgs/close-modal-icon.svg"></svg-icon>
    <p class="bold text-center">Materiais sobre os benefícios</p>
    <div class="description-container">
        <p class="p3 regular text-center padding-bottom">
            Escolha o melhor formato e compartilhe
    </div>

    <div class="message-link-container">
        <form [formGroup]="form">
            <div>
                <p class="label-message-link-title p3">Link</p>
                <hr class="my-1">
                <div fxLayout="column" class="link-container" fxLayoutAlign="center normal">
                    <default-input #linkModalBenefitsInput formControlName="link" [disabled]="false"
                        (onSuffixIconClicked)="copy($event, 'LINK')" [activeSuffixState]="hasCopiedLink"
                        placeholder="Link da LP" type="text"
                        activeSuffixIcon="../../../../assets/svgs/copied-clipboard.svg"
                        inactiveSuffixIcon="../../../../assets/svgs/copy-clipboard.svg"></default-input>
                </div>
            </div>
            <div class="mt-2">
                <p class="label-message-link-title p3">Mensagem + Link</p>
                <hr class="my-2">
                <div fxLayout="column" class="message-container" fxLayoutAlign="center normal">
                    <default-text-area #messageModalBenefitsTextArea class="h-100" formControlName="message" [rows]="2"
                        [cols]="50" (onSuffixIconClicked)="copy($event, 'MENSAGEM')" [disabled]="false"
                        [activeSuffixState]="hasCopiedMessage" placeholder="Mensagem e link da LP" type="text"
                        activeSuffixIcon="../../../../assets/svgs/copied-clipboard.svg"
                        inactiveSuffixIcon="../../../../assets/svgs/copy-clipboard.svg"></default-text-area>
                </div>
            </div>
        </form>
    </div>

</div>