import { Permission } from './../../models/permission.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Permissions } from 'src/app/enums/permissions';

@Injectable({
  providedIn: 'root'
})
export class JwtTokenService {

  TOKEN: string = 'access-token-plataforma-rh';
  USER: string = 'user';

  constructor(private route: Router) { }

  public getHeaderAuth() {
    return {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem(this.TOKEN)
      }
    };
  }

  isLogger(): boolean {
    if (localStorage.getItem(this.TOKEN)) {
      return true
    }
    return false
  }

  setToken(jwt: string): void {
    localStorage.setItem(this.TOKEN, jwt);
  }

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN)
  }

  public clearToken(): void {
    localStorage.clear();
  }

  loggout() {
    this.clearToken()
    this.route.navigate(['login']);
  }

  hasPermission(permission: string) {

    if (localStorage.getItem(this.USER)) {
      const permissions = JSON.parse(localStorage.getItem(this.USER) || '').permissions

      if (permissions) {
        return !!permissions.find(
          ({ functionality }: Permission) =>
            functionality.name === Permissions.COLLABORATORS
        )
      } else {
        return false
      }
    }
    return false
  }

  getCompanyId() {
    if (localStorage.getItem(this.USER)) {
      const { companyId } = JSON.parse(localStorage.getItem(this.USER) || '');
      return companyId;
    }
    return null;
  }

}
