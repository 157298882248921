<table class="table table-bordered" fxLayout="column">
  <tbody *ngIf="dataSource.length && !loading; else noDataRegisteredYet">
    <tr [ngClass]="{
        'status-cancel-request':
          row?.dateCancelRequest && actionMode == collaboratorTableMode.DELETE
            ? true
            : false
      }" *ngFor="let row of dataSource">
      <td fxFlex="35" class="no-side-padding" fxLayout="row" fxLayoutAlign="start start">
        <span class="name-title semi-bold p3">{{ row?.nome | titlecase }}</span>
      </td>
      <td class="custom-border-left" fxFlex fxLayout="column" fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span *ngIf="row?.cpf" class="regular p3"><span class="name-title semi-bold p3">CPF:</span>
          {{ row?.cpf | cpf }}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="6vmin" fxLayoutAlign="center center">
        <span class="regular p3"><span class="name-title semi-bold p3">Plano:</span>
          {{ row?.plano }}</span>
      </td>

      <td fxFlex class="custom-border-right no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin"
        fxLayoutAlign="center center">
        <svg-icon [ngClass]="{
            'status-cancel-request-no-visibility':
              row?.dateCancelRequest &&
              actionMode == collaboratorTableMode.DELETE
                ? true
                : false,
            'btn-icon': actionMode === collaboratorTableMode.DEFAULT,
            'btn-icon-cancel': actionMode === collaboratorTableMode.DELETE
          }" (click)="clickButton(row)" src="{{ icon }}"></svg-icon>
      </td>
    </tr>
  </tbody>
</table>
<ng-template #noDataRegisteredYet>
  <div *ngIf="dataSource.length === 0" fxFlex fxLayoutAlign="start start">
    <p class="label-no-data-registered p1 bold">
      Não há dependentes inclusos.
    </p>
  </div>
</ng-template>
