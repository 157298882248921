import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(valor: number | string): string {
    if (!valor) {
      return 'R$ 0,00';
    }

    const valorNumerico = typeof valor === 'string' ? parseFloat(valor) : valor;
    if (isNaN(valorNumerico)) {
      return 'R$ 0,00';
    }
    const valorFormatado = valorNumerico
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return `R$ ${valorFormatado}`;
  }
}
