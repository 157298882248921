import { ActionButtonTypes } from './../../../enums/action-button-modal';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  TemplateRef,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ModalWithTwoButtonsComponent } from '../modal-with-two-buttons/modal-with-two-buttons.component';

@Component({
  selector: 'modal-generic-step',
  templateUrl: './modal-generic-step.component.html',
  styleUrls: ['./modal-generic-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalGenericStepComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() content: TemplateRef<any>;
  @Input() showWarning: boolean = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    ) {}

  closeModal() {
    if(this.showWarning){
      this.openExitWarning();
    } else {
      this.activeModal.close(true);
    }
  }

  openExitWarning() {
    const modalRef = this.modalService.open(ModalWithTwoButtonsComponent, {
      centered: true,
    });
    modalRef.componentInstance.title = 'Deseja realmente abandonar a inclusão?';
    modalRef.componentInstance.description =
      'Ao sair, todos os dados já inseridos serão perdidos';
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.rightButtonLabel = 'Sair';
    modalRef.componentInstance.leftButtonLabel = 'Continuar';

    modalRef.result.then(
      (result: any) => {
        if (result && result === ActionButtonTypes.CLOSE_BUTTON_RIGHT) {
          this.modalService.dismissAll()
        }
      },
    )
  }
}
