<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="../../../../assets/svgs/success-modal-icon.svg"></svg-icon>
</div>
<div class="margin-content">
  <h3 class="text-center">
    Informações de login
  </h3>
  <div>
    <p class="p3 text-center padding-bottom">
      Seu primeiro login precisa ser feito
      com a senha enviada anteriormente
      no e-mail cadastrado.
    </p>
    <p class="p3 text-center padding-bottom">
      Caso tenha perdido esses dados
      de acesso ou precise de alguma ajuda,
      fale com a gente no chat.
    </p>
  </div>
  <default-button (onClick)="this.onClick()" [disabled]="false" label="Voltar para o login"></default-button>
  <app-white-button (onClick)="openBlip()" [disable]="false" label="Falar no chat"></app-white-button>
</div>
