import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { CurrencyPipe, formatCurrency, getCurrencySymbol } from '@angular/common';
import { InvoiceChartModel } from 'src/app/models/invoice-chart-model';
import { AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'invoice-line-chart',
  templateUrl: './invoice-line-chart.component.html',
  styleUrls: ['./invoice-line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InvoiceLineChartComponent implements OnInit, AfterViewInit {
  @Input() data: InvoiceChartModel[] = [];

  // options
  legend: boolean = false;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = false;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = false;
  showGridLines: boolean = true;
  xAxisLabel: string = 'Year';
  yAxisLabel: string = 'Population';
  timeline: boolean = true;

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onActivated: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeactivated: EventEmitter<any> = new EventEmitter<any>();

  colorScheme = { domain: ['#F2AB27'] };

  @ViewChild('containerInvoiceChartRef')
  containerInvoiceChartRef: ElementRef<HTMLDivElement>;

  constructor(@Inject(LOCALE_ID) private locale: string) {}
  ngAfterViewInit(): void {
    const interval = setInterval(() => {
      const allGElements = this.containerInvoiceChartRef?.nativeElement.querySelectorAll('g.tick') as unknown as SVGGElement[];
      if(allGElements?.length > 1) {
        clearInterval(interval);
        allGElements.forEach((gElement: SVGGElement) => this.breakSvgTextIfNecessary(gElement))
      }
    }, 100)
  }

  ngOnInit(): void {}

  onSelect(data: any): void {
    this.onSelected.emit(data);
  }

  onActivate(data: any): void {
    this.onActivated.emit(data);
  }

  onDeactivate(data: any): void {
    this.onDeactivated.emit(data);
  }

  tooltipText(data: any): string {
    return JSON.parse(JSON.stringify(data)).cell.tooltipText;
  }

  xAxisTickFormatting = (label: any) => {
    
    let valueFormatted: string = '';

    const elementFound = this.data[0].series.find((element) => element.name === label)?.value;
    elementFound ? valueFormatted = formatCurrency(elementFound, 'pt',getCurrencySymbol('BRL', 'narrow', 'pt')) : ''
    return `${valueFormatted}` +` `+ `${label}`;
  };


  breakSvgTextIfNecessary(gElement: SVGGElement) {
    const fullText = (gElement?.getElementsByTagName("title") as unknown as HTMLCollectionOf<SVGTitleElement>).item(0)?.textContent?.trim()    
    const actualTextElement = gElement.getElementsByTagName("text").item(0)
    if(actualTextElement) {
      const actualText = actualTextElement?.textContent?.trim()
      // if the text was cut (that happens when we find ... at end of string)
      if (!!fullText && !!actualText) {
        // all words except last one (because it could be broken with ...)
        const untilLastWord = actualText.split(' ').slice(0, -1).join(' ')
        actualTextElement.textContent = untilLastWord
        // rest of the original text
        const fromLastWord = fullText.slice(untilLastWord.length)
        // set this text to be above center
        actualTextElement.setAttribute("dy", "-0.4em")
        // make new text element        
        const newTextElement = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        // copy all properties from original text
        for (const attr of Array.from(actualTextElement.attributes)) {
          newTextElement.setAttribute(attr.name, attr.value)
        }
        // set it to be below center
        newTextElement.setAttribute("dy", "1.1em")        
        // set content of text element
        newTextElement.textContent = fromLastWord
        gElement.appendChild(newTextElement)
      }
    }  
  }
}
