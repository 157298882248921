<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="{{ icon }}"></svg-icon>
</div>
<div class="margin-content">

  <svg-icon (click)="closeModal()" class="close-button" src="../../../assets/svgs/close-modal-icon.svg"></svg-icon>

  <div #bodyContent>
    <ng-container *ngTemplateOutlet="content;"></ng-container>
  </div>

</div>
