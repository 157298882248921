import { BuscarPlanoModel } from 'src/app/models/buscar-plano.model';
import { EstrategiaDeValidacao } from '../../../interfaces/EstrategiaValidacao';

export class EstrategiaDeValidacaoDePlanosDependente
  implements EstrategiaDeValidacao<BuscarPlanoModel>
{
  validar(empresa: BuscarPlanoModel): boolean {
    const { planoDependente } = empresa;
    const isValid = planoDependente.length > 0;

    if (isValid) {
      // TODO: implementar o winston
      // console.log(
      //   'Validação de planos dependente bem-sucedida para a empresa:',
      //   empresa
      // );
    }

    return isValid;
  }
}
