<button fxLayout="row" fxLayoutAlign="start center" class="btn btn-lg semi-bold" [ngClass]="{
    'btn-light-yellow': typeStyle === extraButtonType.LIGHT_YELLOW,
    'btn-light-blue' : typeStyle === extraButtonType.LIGHT_BLUE,
    'btn-light-green' : typeStyle === extraButtonType.LIGHT_GREEN,
    'btn-light-red' : typeStyle === extraButtonType.LIGHT_RED,
    'btn-primary-pure' : typeStyle === extraButtonType.PRIMARY_PURE,
    'btn-grey-outline' : typeStyle === extraButtonType.GREY_OUTLINE}" type="button" (click)="clicked()">
    <span *ngIf="iconOrientation === 'start'">
        <svg-icon class="btn-icon-start" *ngIf="icon" src={{icon}}></svg-icon>
    </span>
    {{label}}
    <span *ngIf="iconOrientation === 'end'">
        <svg-icon class="btn-icon-end" *ngIf="icon" src={{icon}}></svg-icon>
    </span>
</button>