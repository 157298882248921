<div
  class="position-logo"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <svg-icon src="{{ icon }}"></svg-icon>
</div>
<form [formGroup]="formGroup" class="margin-content">
  <p [innerHtml]="title" class="bold text-center"></p>
  <div class="description-container">
   <default-text-area [formControlName]="formControlNameTextArea" placeholder="Digite aqui..."></default-text-area>
  </div>
  <default-button
    class="spacing"
    (onClick)="nextStep()"
    [disabled]="false"
    [label]="buttonLabel"
  ></default-button>
</form>
