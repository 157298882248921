import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'default-text-area',
  templateUrl: './default-text-area.component.html',
  styleUrls: ['./default-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DefaultTextAreaComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';

  @Input() disabled: boolean = false;
  @Input() rows: number = 4;
  @Input() cols: number = 50;

  @Input() value: any = '';
  @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();

  @Input() inactiveSuffixIcon: string = '';
  @Input() activeSuffixIcon: string = '';
  @Input() activeSuffixState: boolean;
  @Output() onSuffixIconClicked: EventEmitter<any> = new EventEmitter<any>();

  onChange: any = () => {
    this.valueChanges.emit(this.value);
  };
  onTouched: any = () => {};

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  iconClicked(event: any) {
    this.onSuffixIconClicked.emit({ event, value: this.value });
  }
}
