import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, Self, SimpleChanges, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ChatInputComponent implements OnInit {
  @Input() disabled: boolean = false;
  @Input() value: any = '';
  @Input() formControlName: string;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadFiles: EventEmitter<any> = new EventEmitter<any>();

  isTextAreaFocused: boolean = false;
  showOutlined: boolean = false;

  onChange: any = () => {
    this.valueChanges.emit(this.value);
  };

  onTouched: any = () => { };

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  chooseFile(): void {
    const fileInput = document.getElementById('uploadFile');
    fileInput?.click();
  }

  selectedFiles(event: any) {
    this.selectFile?.emit(event);
  }

  uploadedFiles(event: any) {
    this.uploadFiles?.emit(event);
    this.setShowOutlined();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled'] && this.ngControl?.control) {
      changes['disabled'].currentValue || this.disabled
        ? this.ngControl?.control?.disable()
        : this.ngControl?.control?.enable();
    }
  }

  ngOnInit(): void {
  }

  adjustTextareaHeight(textarea: HTMLTextAreaElement) {
    const lineHeight = 25;
    const rows = Math.min(Math.floor(textarea.scrollHeight / lineHeight), 5);
    const paddingVertical = 12 * 2
    const textAreaHeight = rows * lineHeight + paddingVertical
    textarea.style.height = `${textAreaHeight}px`;
  }

  setShowOutlined() {
    this.showOutlined = !this.showOutlined;
  }

  onFocusTextArea() {
    this.isTextAreaFocused = true;
  }
  onBlurTextArea() {
    this.isTextAreaFocused = false;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

}
