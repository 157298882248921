import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LogProcess } from '../models/include-spreadsheet';

@Injectable()
export class SubscriberSpreadSheet {
  private dataSubject = new BehaviorSubject<LogProcess[]>([]);
  public data$ = this.dataSubject.asObservable();

  updateData(newData: LogProcess[]) {
    this.dataSubject.next(newData);
  }
}
