import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { MixpanelService } from './../../services/mixpanel.services';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/constants/constants';
import { UserService } from 'src/app/services/users.service';
import { CustomValidators } from './../../validators/validators';
import { ChangePasswordComponent } from './../change-password/change-password.component';
import { ModalWithOneButtonComponent } from './../modals/modal-with-one-button/modal-with-one-button.component';
import { User } from 'src/app/models/user.model';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-my-profile-page',
  templateUrl: './my-profile-page.component.html',
  styleUrls: ['./my-profile-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyProfilePageComponent implements OnInit {
  oldEmail: string;
  oldPhoneNumber: string;

  myProfileFormGroup: FormGroup = new FormGroup({
    companyName: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    cnpj: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      CustomValidators.ValidateCPFCNPJ,
    ]),
    email: new FormControl({ value: '', disabled: true }, [
      Validators.required,
      Validators.email,
    ]),
    phoneNumber: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  disableSaveButton: boolean = true;
  isEditing: boolean = false;
  mixpanelEvents = MixpanelEvents;

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private toastrService: ToastrService,
    private mixpanelService: MixpanelService,
    private cd: ChangeDetectorRef,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.loadUserData()
  }

  ngAfterViewInit(): void {
    this.storageService.changes.subscribe((result) => {
      this.loadUserData()
    });
  }

  loadUserData(){
    const user: User = JSON.parse(localStorage.getItem('user') || '');
    this.myProfileFormGroup.patchValue(user);
    this.oldEmail = user.email;
    this.oldPhoneNumber = user.phoneNumber;
  }

  onEnableButton(): void {
    this.disableSaveButton = false;
  }

  disableEditing() {
    this.isEditing = false;
    Object.keys(this.myProfileFormGroup.controls).forEach((control) => {
      this.myProfileFormGroup.get(control)?.disable();
    });
    this.cd.detectChanges();
  }

  getMask(): string {
    const phoneNumber = this.myProfileFormGroup.get('phoneNumber')?.value
      ? this.myProfileFormGroup.get('phoneNumber')?.value.replace(/\D+/g, '')
      : '';
    if (phoneNumber.length > 10) {
      return '(00) 0 0000-0000';
    } else {
      return '(00) 0000-00009';
    }
  }

  enableEditing() {
    this.isEditing = true;
    this.myProfileFormGroup.get('phoneNumber')?.enable();
    this.myProfileFormGroup.get('email')?.enable();
    this.mixpanelService.track(
      this.mixpanelEvents.CLICK_EDIT_CONTACT_INFORMATION
    );
  }

  editForm() {
    this.enableEditing();
  }

  cancelEditForm() {
    this.disableEditing();
    this.mixpanelService.track(
      this.mixpanelEvents.CANCEL_EDIT_CONTACT_INFORMATION
    );
    this.myProfileFormGroup.patchValue({
      email: this.oldEmail,
      phoneNumber: this.oldPhoneNumber,
    });
  }

  openModal() {
    this.mixpanelService.track(this.mixpanelEvents.START_CHANGE_PASSWORD);
    this.modalService.open(ChangePasswordComponent, {
      centered: true,
    }).componentInstance.onSave = (
      currentPassword: string,
      newPassword: string
    ) => {
      this.userService
        .updatePasswordLogged(currentPassword, newPassword)
        .then(() => {
          this.modalService.dismissAll();
          this.mixpanelService.track(this.mixpanelEvents.CHANGE_PASSWORD, {
            Sucesso: true,
          });
          const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
            centered: true,
          });
          modalRef.componentInstance.title = 'Senha alterada';
          modalRef.componentInstance.description =
            'Sua nova senha foi alterada com sucesso!';
          modalRef.componentInstance.icon =
            '../../../assets/svgs/confirmed-icon.svg';
          modalRef.componentInstance.label = 'OK';
        })
        .catch(({ status }) => {
          this.modalService.dismissAll();
          this.mixpanelService.track(this.mixpanelEvents.CHANGE_PASSWORD, {
            Sucesso: false,
          });
          if (status === Constants.HAS_NO_NETWORK) {
            const modalRef = this.modalService.open(
              ModalWithOneButtonComponent,
              {
                centered: true,
              }
            );
            modalRef.componentInstance.title = 'Sem conexão';
            modalRef.componentInstance.description =
              'Não há conexão com a internet. Por favor, tente novamente.';
            modalRef.componentInstance.icon =
              '../../../assets/svgs/no-internet-modal-icon.svg';
            modalRef.componentInstance.label = 'Tentar novamente';
          } else {
            const modalRef = this.modalService.open(
              ModalWithOneButtonComponent,
              { centered: true }
            );
            modalRef.componentInstance.title = 'Erro ao salvar';
            modalRef.componentInstance.description =
              'Ocorreu um erro ao salvar as alterações. Por favor, tente novamente.';
            modalRef.componentInstance.icon =
              '../../../assets/svgs/attention-modal-icon.svg';
            modalRef.componentInstance.label = 'Tentar novamente';
          }
        });
    };
  }

  updateInformation() {
    this.userService
      .updateInformation(
        this.myProfileFormGroup.get('email')?.value,
        this.myProfileFormGroup.get('phoneNumber')?.value
      )
      .then(() => {
        this.disableEditing();
        this.mixpanelService.track(
          this.mixpanelEvents.FINISH_EDIT_CONTACT_INFORMATION,
          { Sucesso: true }
        );
        this.toastrService.success('Dados salvos com sucesso!');
      })
      .catch(({ status }) => {
        this.mixpanelService.track(
          this.mixpanelEvents.FINISH_EDIT_CONTACT_INFORMATION,
          { Sucesso: false }
        );
        if (status === Constants.HAS_NO_NETWORK) {
          this.toastrService.error('Sem conexão! Tente novamente.');
        } else {
          this.toastrService.error('Erro ao salvar! Tente novamente.');
        }
      });
  }
}
