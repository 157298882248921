<div>
  <p class="bold p1">Acesso rápido</p>
  <div>
    <quick-access-item (click)="navigate('fale-conosco')" icon="../../../assets/svgs/crm-icon.svg" fxFlex="25"
      label="Chamados"></quick-access-item>
    <quick-access-item (click)="navigate('financeiro')" icon="../../../assets/svgs/spreadsheet-icon.svg" fxFlex="25"
      label="Ver fatura atual"></quick-access-item>
    <quick-access-item (click)="navigate('beneficiarios/1')" icon="../../../assets/svgs/square-with-person.svg"
      fxFlex="25" label="Inclusão de beneficiário"></quick-access-item>
    <quick-access-item (click)="navigate('beneficiarios/2')" icon="../../../assets/svgs/close-icon.svg" fxFlex="25"
      label="Solicitar cancelamento"></quick-access-item>
  </div>
</div>
