import { EstrategiaEmpresa } from '../estrategias/EstrategiaEmpresa.factory';
import { EstrategiaDeValidacaoDePlanosDependente } from '../estrategias/EstrategiaValidacaoPlanosDependentes.strategy';
import { EstrategiaDeValidacaoDeStatus } from '../estrategias/EstrategiaValidacaoStatus.strategy';

export class ValidadorDependenteSituacao extends EstrategiaEmpresa {
  constructor() {
    super([
      new EstrategiaDeValidacaoDePlanosDependente(),
      new EstrategiaDeValidacaoDeStatus(),
    ]);
  }
}
