import { Component, OnInit, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalWithOneButtonComponent } from '../../modal-with-one-button/modal-with-one-button.component';
import { MixpanelService } from 'src/app/services/mixpanel.services';
import { Review } from 'src/app/models/review.model';
import { ReviewTypes } from 'src/app/enums/review-types';
import { ReviewService } from 'src/app/services/review.service';
import { User } from 'src/app/models/user.model';

export type ReviewType = 'FACE' | 'RADIO_GROUP'

@Component({
  selector: 'modal-review-text-area',
  templateUrl: './modal-review-text-area.component.html',
  styleUrls: ['./modal-review-text-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalReviewTextAreaComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string = '../../../../assets/svgs/review-icon.svg';
  @Input() buttonLabel: string = 'Enviar resposta';
  @Input() formGroup: FormGroup;
  @Input() formControlNameTextArea: FormControl;
  @Input() type: ReviewType;
  @Input() metricName: string;
  @Input() reviewType: ReviewTypes;

  companyId: number;

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private mixpanelService: MixpanelService, private reviewService: ReviewService) {
    const { companyId } = JSON.parse(localStorage.getItem('user') ?? '') as User;
    this.companyId = companyId;
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close(true);
  }

  nextStep() {
    this.closeModal();
    this.sendEvent();
    this.insertReview();

    if (this.formGroup.get('reviewValue')?.value < 3) {

      const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
        centered: true,
        windowClass: 'reviewModal',
      });
      modalRef.componentInstance.title = 'Sentimos muito!';
      modalRef.componentInstance.description = 'E agradecemos por compartilhar. Ao ouvir você,<br/>conseguimos melhorar a experiência na plataforma.';
      modalRef.componentInstance.icon = '../../../../../assets/svgs/review-icon.svg';
      modalRef.componentInstance.buttonLabel = 'Finalizar';
    }
    else {
      const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
        centered: true,
        windowClass: 'reviewModal',
      });
      modalRef.componentInstance.title = 'Resposta enviada';
      modalRef.componentInstance.description = 'Agradecemos por compartilhar a sua experiência.<br/>A sua avaliação é muito importante para a gente!';
      modalRef.componentInstance.icon = '../../../../../assets/svgs/review-icon.svg';
      modalRef.componentInstance.buttonLabel = 'Finalizar';
    }
  }

  sendEvent() {
    if (this.type === 'FACE') {
      this.mixpanelService.track(this.metricName, {
        'Nota': this.formGroup.get('reviewValue')?.value,
        'Comentário': this.formGroup.get('reviewTextArea')?.value ?? '',
      })
    }
    else {
      this.mixpanelService.track(this.metricName, {
        'Nota baixar boleto': this.formGroup.get('reviewValuePaymentSlipDownload')?.value,
        'Nota checkar fatura': this.formGroup.get('reviewValueCheckInvoice')?.value,
        'Nota ver histórico de faturas': this.formGroup.get('reviewValueInvoiceHistory')?.value,
        'Comentário': this.formGroup.get('reviewTextArea')?.value ?? '',
      })
    }
  }

  insertReview() {
    if (this.formGroup.get('reviewValue')) {
      const review = {
        companyId: this.companyId,
        comment: this.formGroup?.get('reviewTextArea')?.value ?? '',
        rate: this.formGroup?.get('reviewValue')?.value,
      };
      this.reviewService.insertReview({ ...review, reviewType: this.reviewType })
    }
    else {
      const review = {
        companyId: this.companyId,
        comment: this.formGroup?.get('reviewTextArea')?.value ?? '',
      };
      this.reviewService.insertReview({
        ...review,
        reviewType: ReviewTypes.EVALUATION_FINANCIAL_DOWNLOAD_PAYMENT_SLIP,
        rate: this.formGroup.get('reviewValuePaymentSlipDownload')?.value
      })
      this.reviewService.insertReview({
        ...review,
        reviewType: ReviewTypes.EVALUATION_FINANCIAL_CHECK_INVOICE,
        rate: this.formGroup.get('reviewValueCheckInvoice')?.value
      })
      this.reviewService.insertReview({
        ...review,
        reviewType: ReviewTypes.EVALUATION_FINANCIAL_HISTORIC_INVOICE,
        rate: this.formGroup.get('reviewValueInvoiceHistory')?.value
      })
    }
  }
}
