<div fxLayout="row">
  <h1 class="bold title" style="width: 100%;" fxLayoutAlign="space-between center">
    <span *ngIf="label">
      {{label}}
    </span>
    <ngx-skeleton-loader *ngIf="!label" [theme]="{
    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
  'border-radius': '6px',
  'width': '245px',
  'height': '27px'
  }"></ngx-skeleton-loader>
    <ng-content></ng-content>
  </h1>
</div>