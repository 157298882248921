import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'span-warning',
  templateUrl: './span-warning.component.html',
  styleUrls: ['./span-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SpanWarningComponent implements OnInit {
  @Input() disclaimer: String;
  @Input() description: String;
  constructor() { }

  ngOnInit(): void {
  }

}
