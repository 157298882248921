<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="{{ icon }}"></svg-icon>
</div>
<div class="margin-content">
  <p [innerHtml]="title" class="bold text-center"></p>
  <div class="description-container">
    <p [innerHtml]="description" class="p3 regular text-center padding-bottom"></p>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <tertiary-button fxFlex="48" (onClick)="closeModal(actionButtonLeft)" [disabled]="false" [label]="leftButtonLabel">
    </tertiary-button>
    <default-button fxFlex="48" (onClick)="closeModal(actionButtonRight)" [disabled]="false" [label]="rightButtonLabel">
    </default-button>
  </div>
</div>
