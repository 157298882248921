import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'birthday'
})
export class BirthdayPipe implements PipeTransform {

  transform(birthday: number|string): string {

    const isDate = new Date(birthday);

    let formattedBirthday = birthday?.toString();
    if(isDate.getFullYear()){
      formattedBirthday = isDate.toLocaleString('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: undefined,
        minute: undefined,
        second: undefined
      }).replace(/\//g, '/');
    } else {
      formattedBirthday = formattedBirthday?.slice(0, 2) + '/' + formattedBirthday?.slice(2, 4) + '/' + formattedBirthday?.slice(4,8);
    }

    return formattedBirthday;
  }

}
