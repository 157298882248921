export enum InvoiceStatus {
  WAITING_CLOSING = 0,
  INVOICE_CLOSED = 1,
  PAYMENT_NOT_MADE = 2,
  PAYMENT_MADE = 3,
  PAYMENT_DELAYED = 4,
}

export enum InvoiceStatusTranslated {
  'Aguardando Fechamento' = 0,
  'Fatura Fechada' = 1,
  'Pagamento Ainda Não Realizado' = 2,
  'Pagamento Realizado' = 3,
  'Pagamento Atrasado' = 4,
}
