import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit {
  @Input() hide: boolean;
  @Input() control: any;
  @Input() focused: boolean;

  error = '#D9534F';
  grayDark = '#928F8F';
  primary = '#F2AB27';

  constructor() {}

  ngOnInit() {}
}
