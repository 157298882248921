import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { ReviewTypes } from 'src/app/enums/review-types';
import { Review } from 'src/app/models/review.model';
import { User } from 'src/app/models/user.model';
import { MixpanelService } from 'src/app/services/mixpanel.services';
import { ReviewService } from 'src/app/services/review.service';
import { ModalWithOneButtonComponent } from '../../modal-with-one-button/modal-with-one-button.component';

@Component({
  selector: 'default-modal-review',
  templateUrl: './default-modal-review.component.html',
  styleUrls: ['./default-modal-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DefaultModalReviewComponent {
  @Input() title: string = 'Compartilhe a sua experiência';
  @Input() subtitle: string = 'Sua opinião é muito importante para nós!';
  @Input() icon: string = '../../../../assets/svgs/review-icon.svg';
  @Input() buttonLabel: string = 'Enviar resposta';
  readonly options: string[] = ['Péssima', 'Ruim', 'Regular', 'Boa', 'Ótima']
  readonly mixpanelEvents = MixpanelEvents;
  companyId: number;

  starsFormGroup: FormGroup = new FormGroup({
    reviewValue: new FormControl(null, [
      Validators.required,
    ]),
    textAreaValue: new FormControl(''),
  });

  setReviewValue(value: number) {
    this.starsFormGroup?.get('reviewValue')?.setValue(value)
  }

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private reviewService: ReviewService, private mixpanelService: MixpanelService) {
    const { companyId } = JSON.parse(localStorage.getItem('user') ?? '') as User;
    this.companyId = companyId;
  }

  closeModal() {
    this.activeModal.close(true);
  }

  nextStep() {
    this.closeModal();
    const review: Review = {
      companyId: this.companyId,
      comment: this.starsFormGroup?.get('textAreaValue')?.value,
      rate: this.starsFormGroup?.get('reviewValue')?.value,
      reviewType: ReviewTypes.EVALUATION_CSAT,
    };
    this.reviewService.insertReview(review);
    this.mixpanelService.track(this.mixpanelEvents.PLATFORM_CSAT, {
      'Comentário': this.starsFormGroup?.get('textAreaValue')?.value,
      'Nota': this.starsFormGroup?.get('reviewValue')?.value,
    })
    if (this.starsFormGroup.get('reviewValue')?.value < 3) {
      const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
        centered: true,
        windowClass: 'reviewModal',
      });
      modalRef.componentInstance.title = 'Sentimos muito!';
      modalRef.componentInstance.description = 'E agradecemos por compartilhar. Ao ouvir você,<br/>conseguimos melhorar a experiência na plataforma.';
      modalRef.componentInstance.icon = '../../../../../assets/svgs/review-icon.svg';
      modalRef.componentInstance.buttonLabel = 'Finalizar';
    }
    else {
      const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
        centered: true,
        windowClass: 'reviewModal',
      });
      modalRef.componentInstance.title = 'Resposta enviada';
      modalRef.componentInstance.description = 'Agradecemos por compartilhar a sua experiência.<br/>A sua avaliação é muito importante para a gente!';
      modalRef.componentInstance.icon = '../../../../../assets/svgs/review-icon.svg';
      modalRef.componentInstance.buttonLabel = 'Finalizar';
    }
  }
}
