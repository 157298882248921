<div class="form-group">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <p class="label semi-bold p4 spacing" *ngIf="label">{{ label }}</p>
    </div>
    <div class="inner-addon right-addon" fxLayout="row" fxLayoutAlign=" center">

        <input [mask]="mask" class="form-control" [formControl]="ngControl.control" [type]="type" class="form-control"
            placeholder="{{ placeholder }}" #searchInputField (focusout)="changeFocus($event)"
            (focus)="changeFocus($event)" />
        <svg-icon (click)="onSearchIconClicked()" [hidden]="ngControl.control?.value" class="search-icon" [ngClass]="{'search-icon-focused': this.focused}" src="../../../../assets/svgs/search-icon.svg"></svg-icon>
        <svg-icon (click)="clearInputField()" [hidden]="!ngControl.control?.value" class="close-icon" [ngClass]="{'close-icon-focused': this.focused}" src="../../../../assets/svgs/close-grey.svg"></svg-icon>
    </div>
    <div class="results" *ngIf="showResult && showInputReturn">
        <p class="gray-dark-text-color">
            Resultados para
            <span class="gray-dark-text-color bold">
                "{{ngControl.control?.value}}"
            </span>
        </p>
    </div>
</div>