import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, Input } from '@angular/core';
import { CardsTableItem } from 'src/app/models/collaborator-card-table-item.model';

@Component({
  selector: 'cards-table',
  templateUrl: './cards-table.component.html',
  styleUrls: ['./cards-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardsTableComponent implements OnInit {

  @Input() dataSource: CardsTableItem[] = [];
  @Input() searchString: string = '';
  
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBenefitClicked: EventEmitter<any> = new EventEmitter<any>();


  @Input() loading: boolean = true;
  @Input() maxRowsLoaded: number = 5;
  counter:Array<any>;

  constructor() { }

  ngOnInit(): void {
  }

  rowClicked(row: CardsTableItem) {
    
  }

  benefitClicked(name:string, cpf:string, codBeneficiary: number, selectedBenefit: any) {  
    const value = {
      name, cpf, codBeneficiary, selectedBenefit
    }  
    this.onBenefitClicked.emit(value)
  }

  renderSkeletonRow() {
    return ``
  }
}
