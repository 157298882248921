import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  containsNumbers,
  containsUppercase,
  matchValidator,
} from 'src/app/utils/utils';
import { CustomValidators } from './../../validators/validators';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePasswordComponent implements OnInit {
  @Output() sendValuesForm = new EventEmitter<string>();

  updatePasswordForm: FormGroup;
  hideNewPassword: boolean = true;
  hideRePassword: boolean = true;
  errorUpdatePassword: any = {};

  constructor() {}

  ngOnInit(): void {
    this.createUpdatePasswordForm();
  }

  containsNumbers(str: string) {
    return containsNumbers(str);
  }

  containsUppercase(str: string) {
    return containsUppercase(str);
  }

  createUpdatePasswordForm() {
    this.updatePasswordForm = new FormGroup({
      password: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.ValidatePassword,
        ])
      ),
      repassword: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          CustomValidators.ValidatePassword,
        ])
      ),
    });

    this.updatePasswordForm.addValidators(
      matchValidator(
        this.updatePasswordForm.get('password'),
        this.updatePasswordForm.get('repassword')
      )
    );
  }

  updatePasswordFormSubmit() {
    if (this.updatePasswordForm.valid) {
      this.sendValuesForm.emit(this.updatePasswordForm.value.password);
    }
  }
}
