<div fxLayout="row">
  <p class="semi-bold p4 title" *ngIf="title">{{ title }}&nbsp;</p>
  <p class="regular p4 subtitle">{{ subtitle }}</p>
</div>
<div *ngFor="let option of options" class="form-check form-check-inline"
  [ngClass]="{'disabled-radio-group': !option.enabled}">
  <input class="form-check-input" type="radio" [value]="option.value" (change)="selectChanged(option)"
    [checked]="option.checked" [disabled]="!option.enabled" />
  <div>
    <label *ngIf="!option.description" class="form-check-label regular p4">{{
      option.label
      }}</label>
    <label *ngIf="option.description" class="form-check-label regular p4">{{ option.description }} ({{ option.label
      }})</label>
    <p class="regular small">
      {{ option.subdescription }} {{ option.dateCanUsePlan || option.cancellationDate }}
    </p>
  </div>
</div>