import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges, AfterViewInit} from '@angular/core';

@Component({
  selector: 'default-paginator',
  templateUrl: './default-paginator.component.html',
  styleUrls: ['./default-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefaultPaginatorComponent implements OnInit, OnChanges {
  
  totalPages: number; 
  
  @Input() pageNumber: number = 1;
  @Input() maxPerPage: number = 5;
  @Input() minActual: number;
  @Input() maxActual: number;
  @Input() total: number;
  @Input() itemLabel: string = '';

  @Output() onPageChange: EventEmitter<any> = new EventEmitter<any>();  

  disableNext: boolean = false;
  disablePrevious: boolean = true;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    
    if(changes['total']?.currentValue) {
      this.total = changes['total'].currentValue
      this.totalPages = Math.ceil(this.total / this.maxPerPage);
      this.updateMinAndMaxActual()
    }      
  }

  ngOnInit(): void {
    this.minActual = 1;
    this.maxActual = this.total < this.maxPerPage ? this.total : this.maxPerPage;
    this.totalPages = Math.ceil(this.total / this.maxPerPage);    
  }

  pageChanged(pageNumber: number) {   
    
    const cb = (pageNumber: number) => {
      this.pageNumber = pageNumber;
      this.updateMinAndMaxActual()
    };

    this.onPageChange.emit({pageNumber, cb})
  }

  updateMinAndMaxActual() {
    this.minActual = (this.maxPerPage * (this.pageNumber - 1)) + 1;
    this.maxActual = this.total < (this.maxPerPage * this.pageNumber) ? this.total : this.maxPerPage * this.pageNumber;
  }
}
