import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'banner-onboarding',
  templateUrl: './banner-onboarding.component.html',
  styleUrls: ['./banner-onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BannerOnboardingComponent implements OnInit {

  @Input() actionLabel: string;
  @Input() actionDisabled = false;
  @Input() actionIcon: string;

  @Input() content: string;
  @Output() onActionClick:EventEmitter<any> = new EventEmitter<any>();

  @Input() loading:boolean = true;

  constructor() { }

  ngOnInit(): void {
  }  

  actionClickCb() {
    this.onActionClick.emit()  
  }

}
