import { lastValueFrom } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CrmService } from 'src/app/services/crm.service';

export interface Attachment {
  fileName: string;
  urlDownload: string;
}

export interface Message {
  sender: string;
  date: string;
  message?: string;
  attachments?: Attachment[];
}

@Component({
  selector: 'modal-chat',
  templateUrl: './modal-chat.component.html',
  styleUrls: ['./modal-chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalChatComponent implements OnInit {
  @Input() messages: Message[] = [];



  fileFormGroup = new FormGroup({
    files: new FormControl()
  })

  constructor(private crmService: CrmService) { }

  ngOnInit(): void {
  }

  upload() {
    this.fileFormGroup.get('files')?.value.map(async (file: File) => {
      const response: any = await this.crmService.getSignedUrlFile(file?.name);
      await lastValueFrom(this.crmService.uploadFileBySignedUrl(response?.url, response?.novoNomeArquivo, file));
    })
  }

  handleSelectFiles(event: any) {
    const fileList: FileList = event.target?.files;
    const file: File = fileList[0];

    const filesControl = this.fileFormGroup.get('files');

    if(filesControl?.value) {
      const oldFiles = filesControl.value
      const arrayFile = [...oldFiles, file]
      this.fileFormGroup.get('files')?.setValue(arrayFile);
    }
    else {
      this.fileFormGroup.get('files')?.setValue([file]);
    }
  }

  removeThisFile(index: number) {
    const filesControl = this.fileFormGroup.get('files');
    const files = filesControl?.value;
    if(files && files.length > 0) {
      files.splice(index, 1)
    }
    this.fileFormGroup.get('files')?.setValue(files);
  }
}
