<div
  class="position-logo"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <svg-icon src="{{ icon }}"></svg-icon>
</div>
<div class="margin-content">
  <p [innerHtml]="title" class="bold text-center"></p>
  <div class="description-container">
    <p
      [innerHtml]="description"
      class="p3 regular text-center padding-bottom"
    ></p>
  </div>
  <default-button
    (onClick)="closeModal()"
    [disabled]="false"
    [label]="buttonLabel"
  ></default-button>
</div>
