import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { DetailedInvoiceHeaderInformation } from 'src/app/models/detailed-invoice-header-information.model';
import { DetailedInvoiceOperation } from 'src/app/models/detailed-invoice-operation.model';
import { Operations } from 'src/app/models/operations.model';
import { FinancialService } from 'src/app/services/financial.service';
import { MixpanelService } from 'src/app/services/mixpanel.services';

@Component({
  selector: 'modal-detailed-invoice',
  templateUrl: './modal-detailed-invoice.component.html',
  styleUrls: ['./modal-detailed-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalDetailedInvoiceComponent implements OnInit {
  @Input() getDetailedInvoiceSpreadsheet: Function;
  @Input() getDetailedInvoicePDF: Function;
  @Input() isDownloadingPDF: boolean;

  dataSource: DetailedInvoiceOperation[] = [];
  headerInformation: DetailedInvoiceHeaderInformation;
  operations: Operations;
  paginationPage: number = 1;
  companyId: number;
  invoiceId: number;
  private readonly offset = 10;

  constructor(
    private activeModal: NgbActiveModal,
    private financialService: FinancialService,
    private cd: ChangeDetectorRef,
    private mixpanelService: MixpanelService
  ) {
    const localStorageUser: any = localStorage.getItem('user');
    this.companyId = JSON.parse(localStorageUser).companyId;
    // TODO: remoção da paginação pelo scroll
    // this.scrollCallbackToBind = this.scrollCallbackToBind.bind(this);
  }
  ngOnInit(): void {
    this.financialService
      .getDetailedInvoiceHeader(this.companyId, this.invoiceId)
      .then(({ invoice, operations }) => {
        this.headerInformation = invoice;
        this.operations = operations;
        this.cd.detectChanges();
        this.mixpanelService.track(
          MixpanelEvents.DETAILED_INVOICE_HEADER_ENDPOINT_STATUS,
          { Sucesso: true }
        );
      })
      .catch(() =>
        this.mixpanelService.track(
          MixpanelEvents.DETAILED_INVOICE_HEADER_ENDPOINT_STATUS,
          { Sucesso: false }
        )
      );
    this.requestDetailedInvoiceOperations();
  }

  setDownloadingPDFDisable() {
    this.isDownloadingPDF = false;
    this.cd.detectChanges()
  }

  requestDetailedInvoiceOperations() {
    this.financialService
      .getDetailedInvoiceOperations(
        this.companyId,
        {
          page: this.paginationPage,
          offset: this.offset,
        },
        this.invoiceId
      )
      .then((data: any) => {
        this.dataSource = [...this.dataSource, ...data.rows];
        this.cd.detectChanges();
        this.mixpanelService.track(
          MixpanelEvents.DETAILED_INVOICE_OPERATIONS_ENDPOINT_STATUS,
          { Sucesso: true }
        );
      })
      .catch(() =>
        this.mixpanelService.track(
          MixpanelEvents.DETAILED_INVOICE_OPERATIONS_ENDPOINT_STATUS,
          { Sucesso: false }
        )
      );
    this.paginationPage++;
  }
  // TODO: remoção da paginação pelo scroll
  // scrollCallbackToBind(): Observable<any> {
  //   this.requestDetailedInvoiceOperations();
  //   return of(this.dataSource);
  // }

  onClickCloseButton() {
    this.mixpanelService.track(MixpanelEvents.CLOSE_DETAILED_INVOICE_FINANCIAL);
    this.activeModal.close(true);
  }
}
