import { firstValueFrom } from 'rxjs';
import { Crm } from './../models/crm.model';
import { environment } from 'src/environments/environment';
import { JwtTokenService } from 'src/app/services/auth/jwt-token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListCrmsByCompany } from '../models/list-crms-by-company.model';
import { GetDetailedCrm } from '../models/get-detailed-crm.model';

export interface Subject {
  id: number;
  description: string;
}
@Injectable({
  providedIn: 'root',
})
export class CrmService {
  constructor(
    private http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) { }

  getAllSubjects() {
    return this.http
      .get<Subject[]>(
        `${environment.apiUrl}/api/crm/subject`,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  getCancelationReason() {
    return this.http
      .get(
        `${environment.apiUrl}/api/crm/cancellation-reasons`,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  getDetailedSubjects(subjectId: number) {
    return this.http
      .get<Subject[]>(
        `${environment.apiUrl}/api/crm/detailed-subject/subject/${subjectId}`,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  getSignedUrlFile(filename: string) {
    return this.http
      .get(
        `${environment.apiUrl}/api/crm/signed-url-file/${filename}`,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  uploadFileBySignedUrl(signedUrl: string, filename: string, file: File) {

    const formData = new FormData();
    formData.append(filename, file);
    return this.http
      .put(signedUrl, formData.get(filename), {
        reportProgress: true,
        observe: 'events'
      }).pipe();
  }

  deleteFileByName(bucketFilename: string) {
    return this.http
      .delete(
        `${environment.apiUrl}/api/crm/delete-file/${bucketFilename}`,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  createCrm(crm: Crm) {
    return this.http
      .post(
        `${environment.apiUrl}/api/crm`,
        crm,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  sendCancelSolicitation(data: any) {
    return this.http
      .post(
        `${environment.apiUrl}/api/crm/solicitation-cancellation`,
        data,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  listCrmsByCompany(listCrmsByCompanyDTO: ListCrmsByCompany) {
    return firstValueFrom(this.http
      .post(
        `${environment.apiUrl}/api/crm/list`,
        listCrmsByCompanyDTO,
        this.jwtTokenService.getHeaderAuth()
      ))
  }
  getDetailedCrm({protocol, companyId}: GetDetailedCrm) {
    return firstValueFrom(this.http
      .get(
        `${environment.apiUrl}/api/crm/${protocol}/${companyId}`,
        this.jwtTokenService.getHeaderAuth()
      ))
  }
}
