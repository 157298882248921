import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtTokenService } from './auth/jwt-token.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly USER_URL = environment.apiUrl + '/api/users';
  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) {}

  updatePassword(password: string) {
    return this.http
      .put(
        this.USER_URL + '/update-password',
        {
          password,
        },
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  updatePasswordLogged(currentPassword: string, newPassword: string) {
    return this.http
      .put(
        this.USER_URL + '/update-password-logged',
        {
          currentPassword,
          newPassword,
        },
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  requestUserInformation() {
    return this.http
      .get(this.USER_URL + '/me', this.jwtTokenService.getHeaderAuth())
      .toPromise();
  }

  updateInformation(email: string, phoneNumber: string) {
    return this.http
      .put(
        this.USER_URL + '/update-information',
        {
          email,
          phoneNumber,
        },
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }
}
