<div style="width: 100%" fxLayout="row" fxLayoutAlign="space-between end">
  <div class="textarea-container default-border-radius"
    [ngClass]="{'textarea-container-focused': isTextAreaFocused, 'textarea-container': !isTextAreaFocused}" fxFlex="85"
    fxLayoutAlign="space-between end">
    <textarea (focus)="onFocusTextArea()" (blur)="onBlurTextArea()" fxFlex="90" rows="1"
      (input)="adjustTextareaHeight($event.target)" class="custom-textarea default-border-radius"></textarea>
    <svg-icon style="margin-right: 16px; margin-bottom: 8px;"
      src="../../../../assets/svgs/send-attachment-icon.svg" (click)="chooseFile()"></svg-icon>
    <input id="uploadFile" type="file" style="display: none;" (change)="selectedFiles($event)"  />
  </div>
  <svg-icon *ngIf="!showOutlined" (mousedown)="setShowOutlined()"
    src="../../../../assets/svgs/send-message-icon.svg"></svg-icon>
  <svg-icon *ngIf="showOutlined" (mouseup)="uploadedFiles($event)" (mouseleave)="setShowOutlined()"
    src="../../../../assets/svgs/outlined-send-message-icon.svg"></svg-icon>
</div>
