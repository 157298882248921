import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/services/company.service';
import { formatCNPJ } from 'src/app/utils/utils';
import { Company } from 'src/app/models/company-model';
import { CompanyDataSource } from 'src/app/models/company-datasource-model';
import { MixpanelService } from 'src/app/services/mixpanel.services';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';

@Component({
  selector: 'app-modal-company-switcher',
  templateUrl: './modal-company-switcher.component.html',
  styleUrls: ['./modal-company-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalCompanySwitcherComponent implements OnInit {

  companyDataSourceRaw: CompanyDataSource[] = [];
  companyDataSource: CompanyDataSource[] = [];
  isLoading: boolean = true
  selectedItem: any;
  pageNumber: number = 1;
  maxPerPage: number = 5;
  totalRows: number = 10;
  companySearchingFormGroup = new FormGroup({
    searchText: new FormControl({ value: '', disabled: false }),
  });

  constructor(public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private readonly companyService: CompanyService,
    private mixpanelService: MixpanelService,
  ) { }

  ngOnInit(): void {
    this.listCompanies()
  }

  pageChanged(data: any) {
    this.pageNumber = data.pageNumber;
    this.listCompanies(data.cb);
  }

  listCompanies(pageChangedCb?: Function) {

    try {
      if (pageChangedCb) {
        pageChangedCb(this.pageNumber);
      }
      this.companyService.listCompaniesByFilter(
        {
          ...this.companySearchingFormGroup.value,
          pageNumber: this.pageNumber,
          maxPerPage: this.maxPerPage
        }).subscribe(result => {
          this.companyDataSource = result.data
            .map((item: Company, i: number) => ({ id: item.codigoEmpresa, name: item.nomeFantasia, razaoSocial: item.razaoSocial, cnpj: formatCNPJ(item.cnpj), situacao: item.situacao }))
          this.totalRows = result.count
          this.isLoading = false;
          this.cd.detectChanges();
          this.mixpanelService.track(
            MixpanelEvents.COMPANY_LIST_ENDPOINT_STATUS,
            { Sucesso: true }
          );
        })
    } catch (error) {
      this.isLoading = false;
      this.cd.detectChanges();
      this.mixpanelService.track(
        MixpanelEvents.COMPANY_LIST_ENDPOINT_STATUS,
        { Sucesso: false }
      )
    }
  }

  setSelectedItem(itemId: number) {
    this.selectedItem = itemId
  }

  companySelect() {
    this.activeModal.close(this.selectedItem);
  }
}
