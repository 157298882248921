import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PhonePipe } from 'src/app/pipes/phone.pipe';

export interface Contact {
  icon?: string;
  label: string;
}

@Component({
  selector: 'card-contact',
  templateUrl: './card-contact.component.html',
  styleUrls: ['./card-contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardContactComponent implements OnInit {

  protected readonly ICON_NAME: string = '../../../assets/svgs/square-with-person.svg';
  protected readonly ICON_MAIL: string = '../../../assets/svgs/mail-icon.svg';
  protected readonly ICON_PHONE: string = '../../../assets/svgs/phone-icon.svg';

  @Input() title: String = "SAC";
  @Input() sac: String = "SAC 0800 000 000";

  @Input() contacts: Array<Contact> = []

  constructor() { }

  ngOnInit(): void {}

}
