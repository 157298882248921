import { JwtTokenService } from 'src/app/services/auth/jwt-token.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-logout',
  templateUrl: './modal-logout.component.html',
  styleUrls: ['./modal-logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalLogoutComponent implements OnInit {
  show: boolean = false;

  constructor(public activeModal: NgbActiveModal, private jwtTokenService: JwtTokenService) {}

  ngOnInit( ): void {}

  toggle() {
    this.show = !this.show;
  }

  logout() {
    this.toggle();
    this.activeModal.close('Close click');
    this.jwtTokenService.loggout();
  }

  goBack() {
    this.toggle();
    this.activeModal.close('Button click');
  }
}
