<div *ngIf="!activeSuffixIcon && !inactiveSuffixIcon" class="form-group">
  <label class="label-default-text-area semi-bold">{{ label }}</label>
  <textarea [disabled]="disabled" [(ngModel)]="value" [formControl]="ngControl?.control" [placeholder]="placeholder"
    class="form-control" [rows]="rows"></textarea>
</div>

<div *ngIf="activeSuffixIcon || inactiveSuffixIcon" class="input-group">
  <label class="label-default-text-area semi-bold">{{ label }}</label>
  <textarea [disabled]="disabled" [(ngModel)]="value" [formControl]="ngControl?.control" [placeholder]="placeholder"
    class="form-control" [rows]="rows"></textarea>
  <div class="input-group-append">
    <svg-icon *ngIf="!activeSuffixState" class='input-group-text' [ngStyle]="{'cursor': disabled ? '' : 'pointer'}"
      [src]="inactiveSuffixIcon" (click)="iconClicked($event)"></svg-icon>
    <svg-icon *ngIf="activeSuffixState" class='input-group-text' [ngStyle]="{'cursor': 'default'}"
      [src]="activeSuffixIcon"></svg-icon>
  </div>
</div>