<svg-icon
  class="close-button"
  src="../../../assets/svgs/close-modal-icon.svg"
  (click)="this.closeModal()"
></svg-icon>
<div
  class="position-logo"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <svg-icon src="../../../assets/svgs/change-password-icon.svg"></svg-icon>
</div>
<div fxLayout="column" class="margin-form">
  <div class="title-container">
    <p class="bold p2">Alterar a senha</p>
  </div>
  <div>
    <form [formGroup]="updatePasswordForm">
      <div class="form-container">
        <div class="login-container">
          <div class="form-container form-group padding-bottom">
            <div class="old-password-container">
              <default-input
                [type]="!this.hideNewPassword ? 'text' : 'password'"
                formControlName="oldPassword"
                label="Senha atual"
                placeholder="Informe a senha atual"
                type="password"
              >
              </default-input>
            </div>

            <default-input
              [type]="!this.hideNewPassword ? 'text' : 'password'"
              formControlName="password"
              label="Nova senha"
              placeholder="Crie a nova senha"
              type="password"
            >
            </default-input>
            <app-message-error
              [untouch]="updatePasswordForm.get('password')?.touched"
              [valid]="updatePasswordForm.get('password')?.value.length >= 8"
              message="No mínimo 8 caracteres"
            >
            </app-message-error>

            <app-message-error
              [untouch]="updatePasswordForm.get('password')?.touched"
              [valid]="
                containsNumbers(updatePasswordForm.get('password')?.value)
              "
              message="Pelo menos um número"
            >
            </app-message-error>

            <app-message-error
              [untouch]="updatePasswordForm.get('password')?.touched"
              [valid]="
                containsUppercase(updatePasswordForm.get('password')?.value)
              "
              message="Pelo menos uma letra maiúscula"
            >
            </app-message-error>
          </div>
        </div>
        <div>
          <div class="form-group">
            <default-input
              [type]="!this.hideRePassword ? 'text' : 'password'"
              formControlName="repassword"
              label="Confirmar senha"
              placeholder="Confirme a nova senha"
            >
            </default-input>

            <div
              *ngIf="
                updatePasswordForm.get('repassword')?.errors
              "
              class="invalid-feedback"
            >
              <div class="invalid" *ngIf="updatePasswordForm?.errors">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="10px"
                >
                  <svg-icon
                    class="error-icon"
                    src="../../../../assets/svgs/input-error-icon.svg"
                  ></svg-icon>
                  <div class="align-error-text">
                    <p class="is-invalid semi-bold small">
                      As senhas não correspondem
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        [ngClass]="{
          'invalid-none': !this.errorUpdatePassword?.message,
          invalid: this.errorUpdatePassword?.message
        }"
      >
        <div *ngIf="errorUpdatePassword">
          {{ errorUpdatePassword.message }}
        </div>
      </div>
    </form>
  </div>
  <div fxFlex="row" fxLayoutAlign="end" class="button-container padding-top">
    <div fxFlex="50">
      <default-button
        [disabled]="this.updatePasswordForm.invalid"
        (onClick)="updatePasswordFormSubmit()"
        label="Salvar"
      >
      </default-button>
    </div>
  </div>
</div>
