<div fxLayout="column">
  <div>
    <div fxFlex="40"></div>
    <div *ngIf="firstReview" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="60">
      <span class="minimum-width regular small">Muito<br/>difícil</span>
      <span class="minimum-width regular small">Difícil</span>
      <span class="minimum-width regular small">Neutro</span>
      <span class="minimum-width regular small">Fácil</span>
      <span class="minimum-width regular small">Muito<br/>fácil</span>
    </div>
  </div>
  <div class="form-check form-check-inline" [ngClass]="{'disabled-radio-group': !enabled}" fxLayout="row">
    <div fxFlex="40">
      <label class="form-check-label semi-bold small">{{
        label
        }}</label>
    </div>
    <div fxLayout="row" fxFlex="60" fxLayoutAlign="space-between center">
      <div class="minimum-width" fxLayout="column" fxLayoutAlign="space-between center" *ngFor="let value of values">
        <input class="form-check-input" type="radio" [value]="value" (change)="selectChanged(value)"
          [checked]="checked[value]" [disabled]="!enabled" />
      </div>
    </div>
  </div>
</div>
<hr *ngIf="!lastReview">

