import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { actionTypeToReviewType } from '../constants/constants';
import { Action } from '../models/action.model';
import { JwtTokenService } from './auth/jwt-token.service';
import { EvaluationService } from './evaluation.service';


@Injectable({
  providedIn: 'root',
})
export class ActionService {
  private readonly ACTION_URL = environment.apiUrl + '/api/users/action';

  constructor(public http: HttpClient, public jwtTokenService: JwtTokenService, private evaluationService: EvaluationService) { }

  createAction(body: Action) {
    this.actionReview().then((response: any) => {
      const review = response.find((item: any) => item.evaluationType === actionTypeToReviewType[body.action].toString())
      if (review?.showAvailable) {
        this.evaluationService.openReviewModal(body.action)
      }
    })
    return lastValueFrom(this.http
      .post(
        `${this.ACTION_URL}`,
        body,
        this.jwtTokenService.getHeaderAuth()
      ))
  }

  actionReview() {
    return lastValueFrom(this.http
      .get(
        `${this.ACTION_URL}/review`,
        this.jwtTokenService.getHeaderAuth()
      ))
  }
}
