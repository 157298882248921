import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DetailedInvoiceOperation } from 'src/app/models/detailed-invoice-operation.model';

@Component({
  selector: 'financial-detailed-invoices-table',
  templateUrl: './financial-detailed-invoices-table.component.html',
  styleUrls: ['./financial-detailed-invoices-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinancialDetailedInvoicesTableComponent implements OnInit {
  @Input() dataSource: DetailedInvoiceOperation[];
  @Input() loading: boolean = true;
  @Input() maxRowsLoaded: number = 5;
  @Input() scrollCallbackToBind: () => Observable<any>;

  headerNames: string[] = [
    'Nome',
    'CPF',
    'Vínculo',
    'Plano',
    'Tempo de casa',
    'Movimentação',
    'Anterior (R$)',
    'Atual (R$)',
  ];

  scrollCallback: any;
  constructor(private cd: ChangeDetectorRef) {
    // this.scrollCallback = this.callbackToBind.bind(this);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
      this.cd.detectChanges();
    }, 1500);
    this.cd.detectChanges();
  }

  // callbackToBind(): Observable<any> {
  //   return this.scrollCallbackToBind();
  // }
}
