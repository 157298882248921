<div class="card banner-onboarding-container" fxLayout="row" fxLayoutAlign="space-evenly center"
    *ngIf="!loading; else skeletonTemplate">
    <div class="banner-onboarding-content" fxFlex="60">
        <p class="font-weight-semibold p3">{{content}}</p>
    </div>
    <div class="banner-onboarding-action" fxFlex="40" fxLayoutAlign="end">
        <default-button [label]="actionLabel" [disabled]="actionDisabled" [icon]="actionIcon"
            (onClick)="actionClickCb()"></default-button>
    </div>
</div>

<ng-template #skeletonTemplate>
    <div class="card banner-onboarding-container">
        <ngx-skeleton-loader [theme]="{
                'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                'border-radius': '6px',
                'margin': '0',
                'width': '100%',
                'height': '56px'
            }"></ngx-skeleton-loader>
    </div>
</ng-template>