<div class="margin-form">
  <div>
    <form [formGroup]="updatePasswordForm">
      <div class="form-container">
        <div class="login-container ">
          <div class="form-container form-group padding-bottom">
            <default-input [type]="!this.hideNewPassword ? 'text' : 'password'" formControlName="password"
              label="Nova senha" placeholder="Crie a nova senha" type="password">
            </default-input>

            <app-message-error [untouch]="updatePasswordForm.get('password')?.touched"
              [valid]="updatePasswordForm.get('password')?.value.length >= 8" message="No mínimo 8 caracteres">
            </app-message-error>

            <app-message-error [untouch]="updatePasswordForm.get('password')?.touched" [valid]="containsNumbers(
              updatePasswordForm.get('password')?.value
            )" message="Pelo menos um número">
            </app-message-error>

            <app-message-error [untouch]="updatePasswordForm.get('password')?.touched" [valid]="containsUppercase(
                updatePasswordForm.get('password')?.value
              )" message="Pelo menos uma letra maiúscula">
            </app-message-error>

          </div>
        </div>
        <div>
          <div class="form-group">

            <default-input [type]="!this.hideRePassword ? 'text' : 'password'" formControlName="repassword"
              label="Confirme a senha" placeholder="Confirme a nova senha">
            </default-input>

            <div *ngIf="
                updatePasswordForm.get('repassword')?.touched &&
                updatePasswordForm.get('repassword')?.errors
              " class="invalid-feedback">
              <div class="invalid" *ngIf="updatePasswordForm?.errors">

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <svg-icon class="error-icon" src="../../../../assets/svgs/input-error-icon.svg"></svg-icon>
                  <div>
                    <p class="is-invalid semi-bold small">As senhas não correspondem</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div [ngClass]="{
          'invalid-none': !this.errorUpdatePassword?.message,
          invalid: this.errorUpdatePassword?.message
        }">
        <div *ngIf="errorUpdatePassword">
          {{ errorUpdatePassword.message }}
        </div>
      </div>
    </form>
  </div>
  <div class="button-container padding-top">
    <default-button [disabled]="this.updatePasswordForm.invalid" (onClick)="updatePasswordFormSubmit()"
      label="Entrar na plataforma">
    </default-button>
  </div>

</div>
