<section class="container" fxLayout="column">
  <!-- The navbar for the wizard -->
  <div style="width: 100%;" fxLayout="row">
    <div fxFlex fxLayoutAlign="center space-around" class="stepper-ink" [ngClass]="{ 'active-ink': selectedIndex >= i  }" *ngFor="let step of steps; let i = index"></div>
  </div>

  <div class="container-body" fxFlex fxLayout="column">
    <ng-container [ngTemplateOutlet]="selected?.content"></ng-container>
  </div>
</section>
