import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(value: string|number): string {

    if (!value) {
      return ''
    }

    let valorFormatado = value + '';

    switch(valorFormatado.length){
      case 8:
        valorFormatado = valorFormatado
        .replace(/[^0-9]/, '')
        .replace(
            /(\d{4})(\d{4})/,
            '$1-$2'
        );
        break;
      case 9:
        valorFormatado = valorFormatado
        .substr(0, 9)
        .replace(/[^0-9]/, '')
        .replace(
            /(\d{5})(\d{4})/,
            '$1-$2'
        );
        break;
      case 10:
        valorFormatado = valorFormatado
        .substr(0, 10)
        .replace(/[^0-9]/, '')
        .replace(
            /(\d{2})(\d{4})(\d{4})/,
            '($1) $2-$3'
        );
        break;
      case 11:
        valorFormatado = valorFormatado
        .substr(0, 11)
        .replace(/[^0-9]/, '')
        .replace(
            /(\d{2})(\d{5})(\d{4})/,
            '($1) $2-$3'
        );
        break;
      default:break;
    }
    return valorFormatado;
  }

}
