import { ActionButtonTypes } from './../../../enums/action-button-modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
} from '@angular/core';

@Component({
  selector: 'modal-with-two-buttons',
  templateUrl: './modal-with-two-buttons.component.html',
  styleUrls: ['./modal-with-two-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ModalWithTwoButtonsComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() leftButtonLabel: string = 'Voltar';
  @Input() rightButtonLabel: string = 'Continuar';
  actionButtonLeft = ActionButtonTypes.CLOSE_BUTTON_LEFT
  actionButtonRight = ActionButtonTypes.CLOSE_BUTTON_RIGHT

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  closeModal(action: string) {
    this.activeModal.close(action);
  }
}
