import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SubscriberMenuFinanceiro {
  private dataSubject = new BehaviorSubject<number>(0);
  public data$ = this.dataSubject.asObservable();

  updateData(newData: number) {
    this.dataSubject.next(newData);
  }
}
