import { EstrategiaEmpresa } from '../estrategias/EstrategiaEmpresa.factory';
import { EstrategiaDeValidacaoDePlanosTitular } from '../estrategias/EstrategiaValidacaoPlanosTitular.strategy';
import { EstrategiaDeValidacaoDeStatus } from '../estrategias/EstrategiaValidacaoStatus.strategy';

export class ValidadorTitularSituacao extends EstrategiaEmpresa {
  constructor() {
    super([
      new EstrategiaDeValidacaoDePlanosTitular(),
      new EstrategiaDeValidacaoDeStatus(),
    ]);
  }
}
