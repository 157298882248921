<div class="homepage-container">
  <div fxLayout="row">
    <banner *ngIf="hasADebit !== undefined" [competence]="competence" [hasADebit]="hasADebit" fxFlex="100"></banner>
  </div>
  <div class="mt-4" *ngIf="link">
    <banner-onboarding actionIcon="../../../../assets/svgs/share-icon.svg" content="Compartilhe os benefícios com os seus colaboradores!" actionLabel="Compartilhar" (onActionClick)="openModalBenefitsShare()" [loading]="loading"></banner-onboarding>
  </div>
  <div class="quick-access-container">
    <quick-access></quick-access>
    <div class="tip-container" fxLayout="column" fxLayoutGap="1vmin">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1.5vmin">
        <svg-icon src="../../../assets/svgs/tip-icon.svg"></svg-icon>
        <h2 class="bold tip">Dica</h2>
      </div>
      <p class="regular p2 tip-description">
        Para não perder as atualizações, lembre-se de acompanhar diariamente todos os chamados em andamento.
      </p>
    </div>
  </div>
</div>
