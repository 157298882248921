import { DicionarioErrosNegocio } from '../../helpers/dictionary-erros';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CooperatorTab } from 'src/app/enums/tabs';
import {
  calculateAge,
  capitalizeName,
  dateFormattedDDMMYYYY,
  formatDateFromPTBRToENUS,
  isOver18YearsOld,
  paginate,
  validateSpreadsheetFileColumns,
} from 'src/app/utils/utils';
import { CollaboratorTableMode } from '../../enums/collaborator-table-mode';
import { ExtraButtonType } from '../../enums/extra-button-type';
import { DragAndDropFilesComponent } from './../../components/drag-and-drop-files/drag-and-drop-files.component';
import { ModalGenericStepComponent } from './../../components/modals/modal-generic-step/modal-generic-step.component';
import { ModalWithOneButtonComponent } from './../../components/modals/modal-with-one-button/modal-with-one-button.component';
import { StepperGenericComponent } from './../../components/steppers/stepper-generic/stepper-generic.component';
import { AccrualDate } from './../../models/accrualDate.model';
import { BeneficiaryErro } from './../../models/beneficiaryError.model';
import { BlipService } from './../../services/blip.service';
import { CollaboratorsService } from './../../services/collaborators.service';
import { CompanyService } from './../../services/company.service';
import { MixpanelService } from './../../services/mixpanel.services';
import { SubscriberMenuFinanceiro } from './../../services/subscriber-menu-financeiro.service';
import { CustomValidators } from './../../validators/validators';

import { CdkStepper } from '@angular/cdk/stepper';
import { HttpStatusCode } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { CardBeneficiaryTypeComponent } from 'src/app/components/cards/card-beneficiary-type/card-beneficiary-type.component';
import { ModalWithTwoButtonsComponent } from 'src/app/components/modals/modal-with-two-buttons/modal-with-two-buttons.component';
import { RadioPlanSelectionComponent } from 'src/app/components/radio-input/radio-plan-selection/radio-plan-selection.component';
import { ActionsType } from 'src/app/enums/action-type';
import {
  BeneficiaryType,
  BeneficiaryTypeOptions,
} from 'src/app/enums/beneficiary-type';
import { CompanyGroupType } from 'src/app/enums/company-group-type';
import { DegreeOfKinship } from 'src/app/enums/degree-of-kinship';
import { FileType } from 'src/app/enums/file-type';
import { MixpanelEvents } from 'src/app/enums/mixpanelEvents';
import { CctPlan } from 'src/app/models/cct-plan.model';
import { CollaboratorInclusionBySpreadsheet } from 'src/app/models/collaborator-inclusion-by-spreadsheet';
import { CollaboratorRowGeneralView } from 'src/app/models/collaborator-row-general-view.model';
import { LogProcess } from 'src/app/models/include-spreadsheet';
import { Plan } from 'src/app/models/plan.model';
import { User } from 'src/app/models/user.model';
import { ActionService } from 'src/app/services/actions.service';
import { CrmService } from 'src/app/services/crm.service';
import { FinancialService } from 'src/app/services/financial.service';
import { ProcessService } from 'src/app/services/process.service';
import { SubscriberSpreadSheet } from 'src/app/services/subscriber-spreadsheet.service';
import { RadioGroupComponent } from '../../components/radio-groups/radio-group/radio-group.component';
import { ActionButtonTypes } from '../../enums/action-button-modal';
import { S3Object } from '../../models/s3.model';
import { StorageService } from 'src/app/services/storage.service';
import { BuscarPlanoModel } from 'src/app/models/buscar-plano.model';
import { trimmedEmailValidator } from 'src/app/validators/form-validator';
import { CodigosErro } from 'src/app/enums/codigos-erro';
import { ValidadorTitularSituacao } from 'src/app/estrategias-validacao/empresa/manipuladores/ValidadorTitularSituacao.handler';
import { ValidadorDependenteSituacao } from 'src/app/estrategias-validacao/empresa/manipuladores/ValidadorDependenteSituacao.handler';
import { ValidadorSituacao } from 'src/app/estrategias-validacao/empresa/manipuladores/ValidadorSituacao.handler';
import { ICollaboratorCards } from 'src/app/models/collaborator-card.model';
import { Constants } from 'src/app/constants/constants';

@Component({
  selector: 'cooperator',
  templateUrl: './cooperator.component.html',
  styleUrls: ['./cooperator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CooperatorComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$ = new Subject<void>();
  validadorTitularSituacao = new ValidadorTitularSituacao();
  validadorDependenteSituacao = new ValidadorDependenteSituacao();
  validadorSituacao = new ValidadorSituacao();

  numberOfRegisteredBeneficiaries = 0;
  numberOfBeneficiariesWithError = 0;
  beneficiariesWithError: BeneficiaryErro[] = [];
  progressBar = 0;
  colorProgress = '#1ABC77';
  readonly TOOLTIP_TIMER = 4000;
  doneInclusionBeneficiary = false;
  readonly ACCEPT_FILES_EXTENSIONS_CANCELLATION: FileType[] = [
    FileType.CSV,
    FileType.DOCS,
    FileType.JPEG,
    FileType.MP4,
    FileType.PDF,
    FileType.PNG,
    FileType.XLSX,
  ];
  readonly ALLOWED_FILES_EXTENSIONS_INCLUSION = [
    FileType.XLSX,
    FileType.XLS,
    FileType.CSV,
  ];
  readonly ALLOW_FILE_SIZE = 100;

  readonly REQUIRED_COLUMNS_SPREADSHEET_INCLUSION = [
    'MATRICULA',
    'NOME',
    'DATA_NASCIMENTO',
    'CPF',
    'NOME_DA_MAE',
    'SEXO',
    'CELULAR',
    'EMAIL',
    'TITULARIDADE',
    'GRAU_DE_PARENTESCO',
    'CPF_TITULAR_RESPONSAVEL',
    'SALARIO_TITULAR',
  ];

  beneficiaryOptions = [
    {
      label: 'Colaborador',
      value: 'Colaborador',
      checked: false,
      enabled: true,
    },
    {
      label: 'Dependente',
      value: 'Dependente',
      checked: false,
      enabled: true,
    },
  ];

  selectedAccrualDate: AccrualDate;
  chooseBeneficiaryTypeModalRef: NgbModalRef;
  individualInclusionModalRef: NgbModalRef;
  dependentInclusionModalRef: NgbModalRef;
  individualCancelModalRef: NgbModalRef;
  radioButtonGenderOptions = [
    { label: 'Feminino', value: 'Feminino', checked: false, enabled: true },
    { label: 'Masculino', value: 'Masculino', checked: false, enabled: true },
  ];

  showDragAndDropOnCancelationReason: boolean = false;
  radioButtonAccrualDatesOptions: any;
  requiresRegistration: boolean;
  dateCanUsePlan: string;
  cancellationDate: string;
  selectedAccrualDateSpreadsheetInclusion: any = null;
  radioButtonAccrualDatesOptionsSpreadsheetInclusion: any;
  radioButtonAccrualDatesOptionsIndividualCancel: any;
  userInfo: User;
  collaboratorName = '';
  collaboratorSelect: CollaboratorRowGeneralView;
  cancelationReasons: any;
  hasCCTCompany = false;
  holder: Object[];

  degreeOfKinshipOptions = [
    {
      value: DegreeOfKinship.FatherMother,
      label: 'Pai/Mãe',
      id: 8,
    },
    {
      value: DegreeOfKinship.Stepchild,
      label: 'Enteado(a)',
      id: 6,
    },
    {
      value: DegreeOfKinship.Child,
      label: 'Filho(a)',
      id: 4,
    },
    {
      value: DegreeOfKinship.Spouse,
      label: 'Cônjuge/Companheiro(a)',
      id: 3,
    },
    {
      value: DegreeOfKinship.NotDirectRelative,
      label: 'Agregado/Outros',
      id: 10,
    },
  ];

  collaboratorsDependentsInfo: any;
  collaboratorInfoRow: CollaboratorRowGeneralView;

  showHolderPlans: boolean;
  showDependentPlans: boolean;

  operations: ICollaboratorCards[] = [];
  operationsRaw: ICollaboratorCards[] = [];

  @ViewChild('stepper') stepper: ElementRef;
  @ViewChild('cardBeneficiaryTypeRef')
  cardBeneficiaryTypeRef: CardBeneficiaryTypeComponent;
  @ViewChild('gender') genderRadioGrupoComponent: RadioGroupComponent;
  @ViewChild('accrualDate') accrualDateRadioGrupoComponent: RadioGroupComponent;
  @ViewChild('plan') radioPlanSelectionComponent: RadioPlanSelectionComponent;
  @ViewChild('modalChooseBeneficiaryType')
  modalChooseBeneficiaryType: ElementRef;
  @ViewChild('cdkStepper') cdkStepper: StepperGenericComponent;
  @ViewChild('dependentInclusionStepper') dependentInclusionStepper: ElementRef;
  @ViewChild('dependentInclusionCdkStepper')
  dependentInclusionCdkStepper: StepperGenericComponent;
  @ViewChild('secondInclusionStepper') secondInclusionStepper: ElementRef;
  @ViewChild('secondInclusionCdkStepper')
  secondInclusionCdkStepper: StepperGenericComponent;
  @ViewChild('individualCancelStepper') individualCancelStepper: ElementRef;
  @ViewChild('individualCancelCdkStepper')
  individualCancelCdkStepper: StepperGenericComponent;
  @ViewChild('spreadsheetInclusionStepper')
  spreadsheetInclusionStepper: ElementRef;
  @ViewChild('modalCancelConfirmation')
  modalCancelConfirmation: ElementRef;
  @ViewChild('modalCancelFinish')
  modalCancelFinish: ElementRef;
  @ViewChild('spreadsheetInclusionCdkStepper')
  spreadsheetInclusionCdkStepper: StepperGenericComponent;
  @ViewChild('modalEditContact') modalEditContact: ElementRef;
  editContactModalRef: NgbModalRef;

  beneficiaryTypeForm: FormGroup = new FormGroup({
    beneficiaryType: new FormControl(null, [Validators.required]),
  });

  firstStepForm: FormGroup = new FormGroup({
    cpf: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateCPFCNPJ,
    ]),
    fullName: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    birthday: new FormControl('', [
      Validators.required,
      CustomValidators.ageValidator(Constants.MIN_AGE_HOLDER, Constants.MAX_AGE_HOLDER)
    ]),
  });

  secondStepForm: FormGroup = new FormGroup({
    fullMotherName: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    gender: new FormControl('', [Validators.required]),
    salary: new FormControl(null),
    registration: new FormControl(''),
  });

  thirdStepForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, trimmedEmailValidator()]),
    mobilePhoneNumber: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateMobilePhoneNumber,
    ]),
    telephoneNumber: new FormControl(''),
  });

  fourthStepForm: FormGroup = new FormGroup({
    accrualDate: new FormControl('', [Validators.required]),
  });

  choosePlanForm: FormGroup = new FormGroup({
    planName: new FormControl(null, [Validators.required]),
    planPrice: new FormControl(null, [Validators.required]),
    planFrequency: new FormControl(null, [Validators.required]),
    planId: new FormControl(0, [Validators.required]),
  });

  dependentChoosePlanForm: FormGroup = new FormGroup({
    planName: new FormControl(null, [Validators.required]),
    planPrice: new FormControl(null, [Validators.required]),
    planFrequency: new FormControl(null, [Validators.required]),
    planId: new FormControl(0, [Validators.required]),
  });

  dependentInclusionFirstStepForm: FormGroup = new FormGroup({
    holderCpf: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateCPFCNPJ,
    ]),
    holderName: new FormControl('', [Validators.required]),
    holderCodAssociated: new FormControl(null, [Validators.required]),
  });

  dependentInclusionSecondStepForm: FormGroup = new FormGroup({
    holderName: new FormControl('', [Validators.required]),
    degreeOfKinship: new FormControl(null, [Validators.required]),
    birthday: new FormControl('', [Validators.required]),
  });

  dependentInclusionThirdStepForm: FormGroup = new FormGroup({
    cpf: new FormControl(null, [
      Validators.required,
      CustomValidators.ValidateCPFCNPJ,
    ]),
    fullName: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    fullMotherName: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    gender: new FormControl('', [Validators.required]),
  });

  dependentInclusionFourthStepForm: FormGroup = new FormGroup({
    email: new FormControl('', [trimmedEmailValidator()]),
    mobilePhoneNumber: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateMobilePhoneNumber,
    ]),
    telephoneNumber: new FormControl(''),
  });

  individualCancelFirstStepForm: FormGroup = new FormGroup({
    fullName: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    email: new FormControl('', [Validators.required, trimmedEmailValidator()]),
    beneficiaryCpf: new FormControl(null, [Validators.required]),
    codBeneficiary: new FormControl(null, [Validators.required]),
  });

  individualCancelSecondStepForm: FormGroup = new FormGroup({
    accrualDate: new FormControl('', [Validators.required]),
  });

  individualCancelThirdStepForm: FormGroup = new FormGroup({
    cancellationReason: new FormControl(null, [Validators.required]),
  });

  firstStepSpreadsheetInclusionForm: FormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      CustomValidators.ValidateFullName,
    ]),
    email: new FormControl('', [Validators.required, trimmedEmailValidator()]),
    subject: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    subjectDetailed: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
  });

  editContactForm: FormGroup = new FormGroup({
    email: new FormControl('', []),
    mobilePhone: new FormControl('', [
      CustomValidators.ValidateMobilePhoneNumber,
    ]),
    phone: new FormControl('', []),
  });

  requestSubjectObj: any = {
    subject: null,
    subjectDetailed: null,
  };

  requestSubjectIndividualCancellation: any = {
    subject: null,
    subjectDetailed: null,
  };

  secondStepSpreadsheetInclusionForm: FormGroup = new FormGroup({
    cutDate: new FormControl('', [Validators.required]),
  });

  @ViewChild('spreadSheetRadioGroup')
  spreadSheetRadioGroup: RadioGroupComponent;
  @ViewChild('individualCancelRadioGroup')
  individualCancelRadioGroup: RadioGroupComponent;

  @ViewChild('dragAndDropFiles')
  dragAndDropFiles: DragAndDropFilesComponent;

  filesList: any[] = [];
  filesListSpreadsheetInclusion: any[] = [];
  allFilesFinished: boolean = true;
  type = CollaboratorTableMode;
  filteredItems: any[] = [];
  extraButtonType = ExtraButtonType;
  collaboratorSearchingFormGroup = new FormGroup({
    collaborator: new FormControl({ value: '', disabled: false }),
  });
  maxPerPage: number = 5;
  pageNumber: number = 1;
  totalRows: number = 0;
  activeTab = 0;
  errorToastRef: ActiveToast<any>;
  limitDateForMoving: string = '';
  searchHolderErrorMessage: string[];
  hasDependentPlans: boolean = false;
  hasHolderPlans: boolean = false;
  plansInfoLoaded: boolean = false;
  cctPlans: Plan[] = [];
  holderSpreadsheetPlans: Plan[] = [];
  dependentSpreadsheetPlans: Plan[] = [];
  intervalId: any;
  readonly FIVE_SECONDS_IN_MILI_SECONDS = 5000;

  @ViewChild('limitDateTooltip') limitDateTooltip: NgbTooltip;
  onLoading: boolean = true;

  spreadsheetInclusionModalRef: NgbModalRef;
  isTableView: boolean = true;
  collaboratorInfo: any = {};
  benefits: any[] = [];
  private resetMenuSubscription: Subscription;
  private inclusionSpreadsheetSubscription: Subscription;
  private messagemErroInclusaoVidas: string;

  constructor(
    private modalService: NgbModal,
    private companyService: CompanyService,
    private collaboratorsService: CollaboratorsService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private crmService: CrmService,
    private coolaboratorService: CollaboratorsService,
    private mixpanelService: MixpanelService,
    private blipService: BlipService,
    private financialService: FinancialService,
    private route: ActivatedRoute,
    private actionService: ActionService,
    private subscriberMenuFinanceiro: SubscriberMenuFinanceiro,
    private subscriberSpreadSheet: SubscriberSpreadSheet,
    private processService: ProcessService,
    private storageService: StorageService
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('user') || '');
    this.resetMenuSubscription = this.subscriberMenuFinanceiro.data$.subscribe(
      (data) => {
        this.activeTab = data;
      }
    );

    this.inclusionSpreadsheetSubscription =
      this.subscriberSpreadSheet.data$.subscribe((data: LogProcess[]) => {
        if (data.length) {
          this.numberOfBeneficiariesWithError = 0;
          this.numberOfRegisteredBeneficiaries = 0;
          this.beneficiariesWithError = [];

          const [processLogs] = data;
          const { logs } = processLogs;

          for (let index = 0; index < logs.length; index++) {
            const log = logs[index];

            this.progressBar = Math.round(
              Number(((index + 1) / logs.length) * 100)
            );

            if (log.statusHttp === HttpStatusCode.Created) {
              this.numberOfRegisteredBeneficiaries += 1;
            } else {
              this.numberOfBeneficiariesWithError += 1;

              this.beneficiariesWithError.push({
                name: log.request.nome
                  ? log.request.nome
                  : log.request.dependent.NOME,
                errorMessage: log.response.message,
              });
            }
            if (this.progressBar === 100 && !this.doneInclusionBeneficiary) {
              if (this.numberOfBeneficiariesWithError === 0) {
                this.clearSpreadsheetInclusionFormData();
              }
              this.deleteSpreadsheetInclusionFile(
                this.filesListSpreadsheetInclusion[0]
              );
              this.dragAndDropFiles.onClear();
              this.doneInclusionBeneficiary = true;
            }
          }
        }
      });
  }

  get idCompany(): number {
    this.userInfo = JSON.parse(localStorage.getItem('user') || '');
    return this.userInfo.companyId;
  }

  startInterval(): void {
    this.intervalId = setInterval(() => {
      this.loadData();
    }, this.FIVE_SECONDS_IN_MILI_SECONDS);
  }

  stopInterval(): void {
    clearInterval(this.intervalId);
  }

  loadData(): void {
    this.processService
      .getLogProcessByCurrentUser()
      .then((item: LogProcess[]) => {
        this.subscriberSpreadSheet.updateData(item);
        const [lastLogProcess] = item;
        if (lastLogProcess.statusProgress !== 'AGUARDANDO') {
          this.stopInterval();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeBirthdayDependent(value: any) {
    if (isOver18YearsOld(value?.target?.value)) {
      this.dependentInclusionThirdStepForm
        .get('cpf')
        ?.setValidators([
          Validators.required,
          CustomValidators.ValidateCPFCNPJ,
        ]);
      this.dependentInclusionThirdStepForm.updateValueAndValidity();
      this.dependentInclusionThirdStepForm.get('cpf')?.reset();
    } else {
      this.dependentInclusionThirdStepForm.get('cpf')?.setErrors([]);
      this.dependentInclusionThirdStepForm
        .get('cpf')
        ?.setValidators([CustomValidators.ValidateCPFCNPJ]);
      this.dependentInclusionThirdStepForm.updateValueAndValidity();
      this.dependentInclusionThirdStepForm.get('cpf')?.reset();
    }
  }

  ngAfterViewInit(): void {
    this.displayTooltipOnInit();
  }

  ngOnDestroy(): void {
    this.resetMenuSubscription.unsubscribe();
    this.inclusionSpreadsheetSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  async getCancelationReasons() {
    this.cancelationReasons = await this.crmService.getCancelationReason();
  }

  selectPlan(value: any) {
    this.choosePlanForm.get('planId')?.setValue(value.id);
    this.choosePlanForm.get('planName')?.setValue(value.label);
    this.choosePlanForm.get('planPrice')?.setValue(value.price);
    this.choosePlanForm.get('planFrequency')?.setValue(value.frequency);
  }

  selectDependentPlan(value: any) {
    this.dependentChoosePlanForm.get('planId')?.setValue(value.id);
    this.dependentChoosePlanForm.get('planName')?.setValue(value.label);
    this.dependentChoosePlanForm.get('planPrice')?.setValue(value.price);
    this.dependentChoosePlanForm
      .get('planFrequency')
      ?.setValue(value.frequency);
  }

  ngOnInit(): void {
    this.plansInfoLoaded = false;
    this.activeTab = Number(this.route.snapshot.paramMap.get('activeTab')) ?? 0;
    this.getInvoiceInformationData();
    this.getCancelationReasons();
    this.getAccrualDatesData();
    this.getAllSubjectsData();
    this.storageService.changes.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.listCollaboratorsBasedOnTab();
      this.getInvoiceInformationData();
      this.getAccrualDatesData();
      this.activeTab = 0;
      this.operationsRaw = [];
      this.operations = [];
    });
  }

  getAllSubjectsData() {
    this.crmService.getAllSubjects().then((subjects: any) => {
      this.requestSubjectObj.subject = subjects.find(
        (subject: any) => subject.id === 9
      );
      this.crmService
        .getDetailedSubjects(this.requestSubjectObj.subject.id)
        .then((detailedSubjects: any) => {
          this.requestSubjectObj.subjectDetailed = detailedSubjects.find(
            (detailedSubject: any) => detailedSubject.id === 293
          );
        });
    });
  }

  getAccrualDatesData() {
    this.companyService.getAccrualDates(this.idCompany).then((res: any) => {
      const { accrualDates, requiresRegistration } = res;
      this.dateCanUsePlan = accrualDates.find(
        (accrualDate: AccrualDate) => accrualDate.dateCanUsePlan
      )?.dateCanUsePlan;

      this.cancellationDate = accrualDates.find(
        (accrualDate: AccrualDate) => accrualDate.dateCanUsePlan
      )?.accrualDatesCancellation?.cancellationDate;

      this.hasCCTCompany =
        accrualDates.length === CompanyGroupType.COMPANY_GROUP;

      const accrualDatesOptions = accrualDates.map((option: any) => {
        return { ...option, checked: this.hasCCTCompany };
      });

      const accrualDatesCancellationOptions = accrualDates.map(
        (option: any) => {
          return {
            ...option.accrualDatesCancellation,
            checked: this.hasCCTCompany,
          };
        }
      );

      if (this.hasCCTCompany) {
        this.individualCancelSecondStepForm
          .get('accrualDate')
          ?.setValue(accrualDatesOptions[0].value);
        this.fourthStepForm
          .get('accrualDate')
          ?.setValue(accrualDatesOptions[0].value);
        this.changeSelectedAccrualDate(accrualDatesOptions[0]);
        this.selectAccrualDateForSpreadsheetInclusion(accrualDatesOptions[0]);
        this.accrualDateValueChangesSpreadsheetInclusion(
          accrualDatesOptions[0]
        );
      }
      this.radioButtonAccrualDatesOptions = JSON.parse(
        JSON.stringify(accrualDatesOptions)
      );
      this.radioButtonAccrualDatesOptionsSpreadsheetInclusion = JSON.parse(
        JSON.stringify(accrualDatesOptions)
      );
      this.radioButtonAccrualDatesOptionsIndividualCancel = JSON.parse(
        JSON.stringify(accrualDatesCancellationOptions)
      );

      this.requiresRegistration = requiresRegistration;
      if (requiresRegistration) {
        this.firstStepForm
          .get('registration')
          ?.setValidators([Validators.required]);
      }
    });
  }

  getInvoiceInformationData() {
    this.financialService
      .getInvoiceInformationByCompanyId(this.idCompany)
      .then((invoiceInformation: any) => {
        this.limitDateForMoving = invoiceInformation?.dateOperation;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  openChooseBeneficiaryTypeModal() {
    this.chooseBeneficiaryTypeModalRef = this.modalService.open(
      ModalGenericStepComponent,
      {
        centered: true,
        windowClass: 'chooseBeneficiaryTypeModal',
      }
    );
    this.chooseBeneficiaryTypeModalRef.componentInstance.icon =
      '../../../assets/svgs/person-modal-icon.svg';
    this.chooseBeneficiaryTypeModalRef.componentInstance.content =
      this.modalChooseBeneficiaryType;
    this.chooseBeneficiaryTypeModalRef.componentInstance.showWarning = true;

    this.chooseBeneficiaryTypeModalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
        this.chooseBeneficiaryType(null);
        this.resetRadioGroup(this.cardBeneficiaryTypeRef);
      }
    );
  }

  loadingPlan() {
    this.collaboratorsService
      .getCctPlans(this.idCompany, DegreeOfKinship.Child)
      .then((data) => (this.cctPlans = this.mapPlans(data)));
  }

  async getPlanByPlan(beneficiaryType: DegreeOfKinship, age: number) {
    this.collaboratorsService
      .getCctPlans(this.idCompany, beneficiaryType, age)
      .then((data) => (this.cctPlans = this.mapPlans(data)));
  }

  openInclusionModal() {
    if (
      this.beneficiaryTypeForm.get('beneficiaryType')?.value ===
      BeneficiaryType.DEPENDENT
    ) {
      return this.openDependentInclusionModal();
    }
    return this.openIndividualInclusionModal();
  }

  private mapPlans(cctPlans: CctPlan[]) {
    return cctPlans.map((item: CctPlan): Plan => {
      const mappedPlan = {
        label: capitalizeName(item.nome),
        price: item.preco,
        frequency: 'mensal',
        id: item.idPlano,
      };

      return {
        ...mappedPlan,
        value: {
          ...mappedPlan,
        },
        checked: false,
        enabled: true,
      };
    });
  }

  resetForm() {
    this.secondStepForm.reset();
    this.dependentInclusionThirdStepForm.reset();
    this.choosePlanForm.reset();
    this.dependentChoosePlanForm.reset();
    this.fourthStepForm.reset();
    this.resetRadioGroup(this.genderRadioGrupoComponent);
    this.resetRadioGroup(this.accrualDateRadioGrupoComponent);
    this.resetRadioGroup(this.radioPlanSelectionComponent);
  }

  helperOpenIndividualInclusionModal() {
    this.modalService.dismissAll();
    this.mixpanelService.track(MixpanelEvents.CHOOSE_BENEFICIARY_TYPE, {
      'Tipo de beneficiário':
        this.beneficiaryTypeForm.get('beneficiaryType')?.value ??
        'não informado',
    });
    this.openInclusionModal();
  }

  chooseBeneficiaryType(value: BeneficiaryType | null) {
    this.beneficiaryTypeForm.get('beneficiaryType')?.setValue(value);
  }

  chooseHolder(value: any) {
    this.dependentInclusionFirstStepForm
      .get('holderName')
      ?.setValue(value.name);
    this.dependentInclusionSecondStepForm
      .get('holderName')
      ?.setValue(value.name);
    this.dependentInclusionFirstStepForm
      .get('holderCodAssociated')
      ?.setValue(value.codAssociated);
  }

  openDependentInclusionModal() {
    this.dependentInclusionFirstStepForm.reset();

    this.mixpanelService.track(MixpanelEvents.CHOOSE_INCLUSION_FORM, {
      'Tipo de inclusão': 'Individual',
      'Exige matrícula': this.requiresRegistration,
    });
    this.dependentInclusionModalRef = this.modalService.open(
      ModalGenericStepComponent,
      {
        centered: true,
        windowClass: 'individualModalClass',
      }
    );

    this.dependentInclusionModalRef.componentInstance.icon =
      '../../../assets/svgs/person-modal-icon.svg';
    this.dependentInclusionModalRef.componentInstance.content =
      this.dependentInclusionStepper;
    this.dependentInclusionModalRef.componentInstance.showWarning = true;

    this.dependentInclusionModalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
        this.resetForm();
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
        this.resetForm();
      }
    );
  }

  openIndividualInclusionModal() {
    this.mixpanelService.track(MixpanelEvents.CHOOSE_INCLUSION_FORM, {
      'Tipo de inclusão': 'Individual',
      'Exige matrícula': this.requiresRegistration,
    });
    this.individualInclusionModalRef = this.modalService.open(
      ModalGenericStepComponent,
      {
        centered: true,
        windowClass: 'individualModalClass',
      }
    );

    this.individualInclusionModalRef.componentInstance.icon =
      '../../../assets/svgs/person-modal-icon.svg';
    this.individualInclusionModalRef.componentInstance.content = this.stepper;

    this.individualInclusionModalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
        this.resetForm();
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
        this.resetForm();
      }
    );
  }

  resetRadioGroup(viewChild: any) {
    viewChild?.clearSelected();
  }

  nextStep(cdkStepper = this.cdkStepper) {
    const currentStep = Number(cdkStepper?._getFocusIndex());

    this.holder = [];
    this.resetRadioGroup(this.cardBeneficiaryTypeRef);

    cdkStepper?.next();
    switch (currentStep) {
      case 0:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_CPF_AND_REGISTRATION_FIELDS_INDIVIDUAL_INCLUSION
        );
        break;
      case 1:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_GENERAL_DATA_INDIVIDUAL_INCLUSION
        );
        const dateOfBirth =
          this.firstStepForm.get('birthday')?.value ||
          this.dependentInclusionSecondStepForm.get('birthday')?.value;
        const age = calculateAge(dateOfBirth);
        const degreeOfKinship =
          this.dependentInclusionSecondStepForm.get('degreeOfKinship')?.value ||
          DegreeOfKinship.Holder;
        this.getPlanByPlan(degreeOfKinship, age);
        break;
      case 2:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_CONTACT_DATA_INDIVIDUAL_INCLUSION
        );
        break;
      case 3:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_INCLUSION_DATE_INDIVIDUAL_INCLUSION
        );
        break;
      default:
        break;
    }
  }

  previousStep(cdkStepper = this.cdkStepper) {
    cdkStepper?.previous();
  }

  nextStepIndividualCancel() {
    this.individualCancelCdkStepper?.next();
    const currentStep = Number(
      this.individualCancelCdkStepper?._getFocusIndex()
    );
    switch (currentStep) {
      case 1:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_REQUESTER_DATA_INDIVIDUAL_EXCLUSION
        );
        break;
      case 2:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_EXCLUSION_DATE_INDIVIDUAL_EXCLUSION
        );
        break;
      default:
        break;
    }
  }

  previousStepIndividualCancel() {
    this.individualCancelCdkStepper?.previous();
  }

  genderValueChanges(value: any) {
    this.secondStepForm.get('gender')?.setValue(value);
  }

  dependentGenderValueChanges(value: any) {
    this.dependentInclusionThirdStepForm.get('gender')?.setValue(value);
  }

  accrualDateValueChanges(value: any) {
    this.fourthStepForm.get('accrualDate')?.setValue(value);
  }

  accrualDateValueChangesOnIndividualCancel(value: any) {
    this.individualCancelSecondStepForm.get('accrualDate')?.setValue(value);
  }

  changeSelectedAccrualDate(value: any) {
    this.selectedAccrualDate = value;
    this.dateCanUsePlan = value?.dateCanUsePlan;
  }

  saveInclusion() {
    this.onLoading = true;
    const accrualDateFullInfo = this.radioButtonAccrualDatesOptions.find(
      (option: any) =>
        option.value === this.fourthStepForm.get('accrualDate')?.value
    );

    let newCollaborator = {
      idCompany: this.idCompany,
      cpf: this.firstStepForm.get('cpf')?.value,
      name: this.firstStepForm.get('fullName')?.value,
      birthday: formatDateFromPTBRToENUS(
        this.firstStepForm.get('birthday')?.value
      ),
      gender: this.secondStepForm.get('gender')?.value,
      motherName: this.secondStepForm.get('fullMotherName')?.value,
      salary: this.secondStepForm.get('salary')?.value,
      registration: this.secondStepForm.get('registration')?.value || undefined,
      email: this.thirdStepForm.get('email')?.value,
      cellphone: this.thirdStepForm.get('mobilePhoneNumber')?.value,
      phone: this.thirdStepForm.get('telephoneNumber')?.value || undefined,
      requesterEmail: this.userInfo.email,
      accrualDates: accrualDateFullInfo.value,
      dateCanUsePlan: accrualDateFullInfo.dateCanUsePlan,
      referralMonthYear: accrualDateFullInfo.label,
      planId: this.choosePlanForm.get('planId')?.value,
    };

    this.collaboratorsService
      .createNewCollaborator(newCollaborator)
      .then((data: any) => {
        this.beneficiaryTypeForm.reset();
        this.firstStepForm.reset();
        this.secondStepForm.reset();
        this.thirdStepForm.reset();
        this.fourthStepForm.reset();
        this.dependentChoosePlanForm.reset();
        this.dependentInclusionSecondStepForm.reset();
        this.dependentInclusionThirdStepForm.reset();
        this.dependentInclusionFourthStepForm.reset();
        this.resetRadioGroup(this.genderRadioGrupoComponent);
        this.resetRadioGroup(this.accrualDateRadioGrupoComponent);
        this.resetRadioGroup(this.radioPlanSelectionComponent);
        this.fourthStepForm.reset();
        this.dependentInclusionFirstStepForm
          .get('holderCpf')
          ?.setValue(data.titular.associado.cpf);
        this.dependentInclusionFirstStepForm
          .get('holderName')
          ?.setValue(data.titular.associado.nome);
        this.dependentInclusionSecondStepForm
          .get('holderName')
          ?.setValue(data.titular.associado.nome);
        this.dependentInclusionFirstStepForm
          .get('holderCodAssociated')
          ?.setValue(data.titular.associado.id);

        //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
        this.mixpanelService.track(MixpanelEvents.FINISH_INDIVIDUAL_INCLUSION, {
          Colaborador: newCollaborator,
          Titular: true,
          Sucesso: true,
          'Data de inclusão': dateFormattedDDMMYYYY(new Date()),
        });

        this.modalService.dismissAll();
        const modalRef = this.modalService.open(ModalWithTwoButtonsComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = 'Inclusão salva com sucesso';
        modalRef.componentInstance.description =
          'Deseja incluir um novo dependente para este colaborador?';
        modalRef.componentInstance.icon =
          '../../../assets/svgs/confirmed-icon.svg';
        modalRef.componentInstance.rightButtonLabel = 'Novo dependente';
        modalRef.componentInstance.leftButtonLabel = 'Agora não';

        modalRef.result
          .then(
            (result) => {
              if (result && result === ActionButtonTypes.CLOSE_BUTTON_RIGHT) {
                this.clickNewDependent();
              }
            },
            (reason) => {
              console.log(`Dismissed ${reason}`);
            }
          )
          .catch(() => console.log('catch modal inclusao salva'));

        this.actionService.createAction({
          action: ActionsType.INDIVIDUAL_INCLUSION,
          companyIdS4e: this.idCompany,
        });
      })
      .catch((error) => {
        //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
        this.mixpanelService.track(MixpanelEvents.FINISH_INDIVIDUAL_INCLUSION, {
          Colaborador: newCollaborator,
          Titular: true,
          Sucesso: false,
          'Data de inclusão': dateFormattedDDMMYYYY(new Date()),
        });
        this.hideOrShowModalByClass('individualModalClass', true);
        const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = 'Erro ao salvar';
        modalRef.componentInstance.description =
          'Ocorreu um erro ao salvar as informações. Por favor, tente novamente.';
        modalRef.componentInstance.icon =
          '../../../assets/svgs/attention-modal-icon.svg';
        modalRef.componentInstance.buttonLabel = 'Tentar novamente';

        switch (error?.error?.message) {
          case 'Beneficiário já cadastrado':
            modalRef.componentInstance.description =
              'Beneficiário já cadastrado';
            break;
          case 'Vendedor não cadastrado':
            modalRef.componentInstance.description = 'Vendedor não cadastrado';
            break;
          case 'Matricula Obrigatória':
            modalRef.componentInstance.description = 'Matricula Obrigatória';
            break;
          case 'Código de empresa inválido/empresa não está ativa':
            modalRef.componentInstance.description =
              'Código de empresa inválido/empresa não está ativa';
            break;
          case 'Empresa não está ativa':
            modalRef.componentInstance.description = 'Empresa não está ativa';
            break;
          default:
            modalRef.componentInstance.description =
              'Ocorreu um erro ao salvar as informações. Por favor, tente novamente.';
            break;
        }
        modalRef.result.then((result) => {
          this.hideOrShowModalByClass('individualModalClass', false);
        });
      })
      .finally(() => {
        this.onLoading = false;
      });
  }

  saveDependentInclusion() {
    this.onLoading = true;

    let newDependent = {
      name: this.dependentInclusionThirdStepForm.get('fullName')?.value,
      birthDate: formatDateFromPTBRToENUS(
        this.dependentInclusionSecondStepForm.get('birthday')?.value
      ),
      cpf: this.dependentInclusionThirdStepForm.get('cpf')?.value,
      telephone:
        this.dependentInclusionFourthStepForm.get('telephoneNumber')?.value ||
        undefined,
      cellPhone:
        this.dependentInclusionFourthStepForm.get('mobilePhoneNumber')?.value,
      email:
        this.dependentInclusionFourthStepForm.get('email')?.value || undefined,
      motherName:
        this.dependentInclusionThirdStepForm.get('fullMotherName')?.value,
      gender: this.dependentInclusionThirdStepForm.get('gender')?.value,
      degreeOfKinship:
        this.dependentInclusionSecondStepForm.get('degreeOfKinship')?.value,
      codPlan: this.dependentChoosePlanForm.get('planId')?.value,
      currentCompetition: this.fourthStepForm.get('accrualDate')?.value,
    };

    this.companyService
      .createNewDependent(
        this.idCompany,
        this.dependentInclusionFirstStepForm.get('holderCodAssociated')?.value,
        [newDependent]
      )
      .then((data: any) => {
        this.getDependents(
          this.dependentInclusionFirstStepForm.get('holderCodAssociated')?.value
        );
        this.beneficiaryTypeForm.reset();
        this.firstStepForm.reset();
        this.secondStepForm.reset();
        this.thirdStepForm.reset();
        this.fourthStepForm.reset();
        this.dependentChoosePlanForm.reset();
        this.dependentInclusionSecondStepForm.reset();
        this.dependentInclusionThirdStepForm.reset();
        this.dependentInclusionFourthStepForm.reset();
        this.resetRadioGroup(this.genderRadioGrupoComponent);
        this.resetRadioGroup(this.accrualDateRadioGrupoComponent);
        this.resetRadioGroup(this.radioPlanSelectionComponent);
        this.fourthStepForm.reset();
        this.dependentInclusionSecondStepForm
          .get('holderName')
          ?.setValue(
            this.dependentInclusionFirstStepForm.get('holderName')?.value
          );

        //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
        this.mixpanelService.track(MixpanelEvents.FINISH_INDIVIDUAL_INCLUSION, {
          Dependente: newDependent,
          Sucesso: true,
          Titular: false,
          'Data de inclusão': dateFormattedDDMMYYYY(new Date()),
        });

        this.modalService.dismissAll();

        const modalRef = this.modalService.open(ModalWithTwoButtonsComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = 'Inclusão salva com sucesso';
        modalRef.componentInstance.description =
          'Deseja incluir um novo dependente para este colaborador?';
        modalRef.componentInstance.icon =
          '../../../assets/svgs/confirmed-icon.svg';
        modalRef.componentInstance.rightButtonLabel = 'Novo dependente';
        modalRef.componentInstance.leftButtonLabel = 'Agora não';

        modalRef.result
          .then(
            (result) => {
              if (result && result === ActionButtonTypes.CLOSE_BUTTON_RIGHT) {
                this.clickNewDependent();
              }
            },
            (reason) => {
              console.log(`Dismissed ${reason}`);
            }
          )
          .catch(() => console.log('catch modal inclusao salva'));

        this.actionService.createAction({
          action: ActionsType.INDIVIDUAL_INCLUSION,
          companyIdS4e: this.idCompany,
        });
      })
      .catch((error) => {
        //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
        this.mixpanelService.track(MixpanelEvents.FINISH_INDIVIDUAL_INCLUSION, {
          Dependente: newDependent,
          Titular: false,
          Sucesso: false,
          'Data de inclusão': dateFormattedDDMMYYYY(new Date()),
        });
        this.hideOrShowModalByClass('individualModalClass', true);
        const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
          centered: true,
        });
        modalRef.componentInstance.title = 'Erro ao salvar';
        modalRef.componentInstance.description =
          'Ocorreu um erro ao salvar as informações. Por favor, tente novamente.';
        modalRef.componentInstance.icon =
          '../../../assets/svgs/attention-modal-icon.svg';
        modalRef.componentInstance.buttonLabel = 'Tentar novamente';

        switch (error?.error?.message) {
          case 'Beneficiário já cadastrado':
            modalRef.componentInstance.description =
              'Beneficiário já cadastrado';
            break;
          case 'Vendedor não cadastrado':
            modalRef.componentInstance.description = 'Vendedor não cadastrado';
            break;
          case 'Matricula Obrigatória':
            modalRef.componentInstance.description = 'Matricula Obrigatória';
            break;
          case 'Código de empresa inválido/empresa não está ativa':
            modalRef.componentInstance.description =
              'Código de empresa inválido/empresa não está ativa';
            break;
          default:
            modalRef.componentInstance.description =
              'Ocorreu um erro ao salvar as informações. Por favor, tente novamente.';
            break;
        }
        modalRef.result.then((result) => {
          this.hideOrShowModalByClass('individualModalClass', false);
        });
      })
      .finally(() => {
        this.onLoading = false;
      });
  }

  clickNewDependent() {
    this.cd.detectChanges();

    const modalRef = this.modalService.open(ModalGenericStepComponent, {
      centered: true,
      windowClass: 'individualModalClass',
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/person-modal-icon.svg';
    modalRef.componentInstance.content = this.secondInclusionStepper;
    modalRef.componentInstance.showWarning = true;

    this.collaboratorsService
      .getCctPlans(this.idCompany, DegreeOfKinship.Child)
      .then((data) => (this.cctPlans = this.mapPlans(data)));

    modalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  newDependent() {
    this.loadingPlan();
    this.chooseHolder({
      name: this.collaboratorInfo.nome,
      codAssociated: this.collaboratorInfo.codAssociated,
    });
    this.cd.detectChanges();

    const modalRef = this.modalService.open(ModalGenericStepComponent, {
      centered: true,
      windowClass: 'individualModalClass',
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/person-modal-icon.svg';
    modalRef.componentInstance.content = this.secondInclusionStepper;
    modalRef.componentInstance.showWarning = true;

    modalRef.result.then(
      (result) => {
        console.log(`Closed with: ${result}`);
        this.resetForm();
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
        this.resetForm();
      }
    );
  }

  requestCancelBySpreadsheet() {
    this.mixpanelService.track(
      MixpanelEvents.CLICKS_REQUEST_SPREADSHEET_EXCLUSION
    );
  }

  pageChanged(data: any) {
    this.pageNumber = data.pageNumber;
    this.listCollaborators(data.cb);
  }

  backToTableView() {
    this.isTableView = true;
  }

  async loadingCollaboratorSelect(codBeneficiary: number) {
    return await this.collaboratorsService
      .searchCollaboratorsGeneralAndCancelInformationByIdAndFilter(
        this.idCompany,
        codBeneficiary,
        1,
        1
      )
      .then((result: any) => {
        const [beneficiary] = result.rows.map(
          (collaborator: ICollaboratorCards) =>
            this.adaptResultToTableRows(collaborator)
        );
        return beneficiary;
      });
  }

  collaboratorActionClicked(collaborator: CollaboratorRowGeneralView) {
    this.modalService.dismissAll();
    this.collaboratorName = capitalizeName(collaborator.name);
    const {
      codAssociated,
      codBeneficiary,
      cpf,
      typeBeneficiary,
      plan,
      price,
      nextInvoice,
      includeInformation,
    } = collaborator;

    switch (this.activeTab) {
      case CooperatorTab.GENERAL_VIEW:
        this.collaboratorInfo.codAssociated = codAssociated;
        this.collaboratorInfo.codBeneficiary = codBeneficiary;
        this.collaboratorInfo.typeBeneficiary = typeBeneficiary;
        this.collaboratorInfo.plan = plan;
        this.collaboratorInfo.price = price;
        this.collaboratorInfo.nextInvoice = nextInvoice;
        this.collaboratorInfo.includeInformation = includeInformation;
        this.getDependents(codAssociated);
        this.coolaboratorService
          .getCollaboratorInfoByCompanyAndAssociatedAndBeneficiary(
            this.idCompany,
            this.collaboratorInfo.codAssociated,
            this.collaboratorInfo.codBeneficiary
          )
          .then((res: any) => {
            this.mixpanelService.track(
              MixpanelEvents.CLICK_COOPERATOR_INFORMATION_DETAILED,
              {
                cpf,
                'Código do beneficiário': codBeneficiary,
                'Código do Associado': codAssociated,
                Sucesso: true,
              }
            );
            Object.assign(this.collaboratorInfo, { ...res });
            this.benefits = res?.benefits;
          })
          .catch(() => {
            this.mixpanelService.track(
              MixpanelEvents.CLICK_COOPERATOR_INFORMATION_DETAILED,
              {
                cpf,
                'Código do beneficiário': codBeneficiary,
                'Código do Associado': codAssociated,
                Sucesso: false,
              }
            );
          });
        this.isTableView = false;
        this.cd.detectChanges();
        break;
      case CooperatorTab.EXCLUSION:
        this.individualCancelFirstStepForm
          .get('beneficiaryCpf')
          ?.setValue(collaborator.cpf);
        this.individualCancelFirstStepForm
          .get('codBeneficiary')
          ?.setValue(collaborator.codBeneficiary);

        this.mixpanelService.track(MixpanelEvents.START_INDIVIDUAL_EXCLUSION, {
          cpf,
          codAssociated,
          codBeneficiary,
        });
        this.individualCancelModalRef = this.modalService.open(
          ModalGenericStepComponent,
          {
            centered: true,
            windowClass: 'individualCancelModalClass',
          }
        );
        this.individualCancelModalRef.componentInstance.icon =
          '../../../assets/svgs/cancel-modal-icon.svg';
        this.individualCancelModalRef.componentInstance.content =
          this.individualCancelStepper;

        this.individualCancelModalRef.result.then(
          (result) => {
            this.clearIndividualCancelFormData();
            console.log(`Closed with: ${result}`);
          },
          (reason) => {
            console.log(`Dismissed ${reason}`);
          }
        );
    }
  }

  async collaboratorAction(collaboratorSelect: any) {
    const collaborator = await this.loadingCollaboratorSelect(
      collaboratorSelect.codBeneficiario
    );
    if (!collaborator) {
      return;
    }

    this.modalService.dismissAll();
    this.collaboratorName = capitalizeName(collaborator.name);
    const {
      codAssociated,
      codBeneficiary,
      cpf,
      typeBeneficiary,
      plan,
      price,
      nextInvoice,
      includeInformation,
    } = collaborator;

    switch (this.activeTab) {
      case CooperatorTab.GENERAL_VIEW:
        this.collaboratorInfo.codAssociated = codAssociated;
        this.collaboratorInfo.codBeneficiary = codBeneficiary;
        this.collaboratorInfo.typeBeneficiary = typeBeneficiary;
        this.collaboratorInfo.plan = plan;
        this.collaboratorInfo.price = price;
        this.collaboratorInfo.nextInvoice = nextInvoice;
        this.collaboratorInfo.includeInformation = includeInformation;

        this.getDependents(codAssociated);
        this.coolaboratorService
          .getCollaboratorInfoByCompanyAndAssociatedAndBeneficiary(
            this.idCompany,
            this.collaboratorInfo.codAssociated,
            this.collaboratorInfo.codBeneficiary
          )
          .then((res: any) => {
            this.mixpanelService.track(
              MixpanelEvents.CLICK_COOPERATOR_INFORMATION_DETAILED,
              {
                cpf,
                'Código do beneficiário': codBeneficiary,
                'Código do Associado': codAssociated,
                Sucesso: true,
              }
            );
            Object.assign(this.collaboratorInfo, { ...res });
            this.benefits = res?.benefits;
          })
          .catch(() => {
            this.mixpanelService.track(
              MixpanelEvents.CLICK_COOPERATOR_INFORMATION_DETAILED,
              {
                cpf,
                'Código do beneficiário': codBeneficiary,
                'Código do Associado': codAssociated,
                Sucesso: false,
              }
            );
          });
        this.isTableView = false;
        this.cd.detectChanges();
        break;
      case CooperatorTab.EXCLUSION:
        this.individualCancelFirstStepForm
          .get('beneficiaryCpf')
          ?.setValue(collaborator.cpf);
        this.individualCancelFirstStepForm
          .get('codBeneficiary')
          ?.setValue(collaborator.codBeneficiary);

        this.mixpanelService.track(MixpanelEvents.START_INDIVIDUAL_EXCLUSION, {
          cpf,
          codAssociated,
          codBeneficiary,
        });
        this.individualCancelModalRef = this.modalService.open(
          ModalGenericStepComponent,
          {
            centered: true,
            windowClass: 'individualCancelModalClass',
          }
        );
        this.individualCancelModalRef.componentInstance.icon =
          '../../../assets/svgs/cancel-modal-icon.svg';
        this.individualCancelModalRef.componentInstance.content =
          this.individualCancelStepper;

        this.individualCancelModalRef.result.then(
          (result) => {
            this.clearIndividualCancelFormData();
            console.log(`Closed with: ${result}`);
          },
          (reason) => {
            console.log(`Dismissed ${reason}`);
          }
        );
    }
  }

  listCollaboratorsBasedOnTab(sendMetric = true) {
    switch (this.activeTab) {
      case CooperatorTab.GENERAL_VIEW:
        this.onLoading = true;
        this.collaboratorSearchingFormGroup.get('collaborator')?.reset();
        break;
      case CooperatorTab.INCLUSION:
        Promise.all([this.checkPlanValidation()]).then(() => {
          this.plansInfoLoaded = true;
        });
        this.onLoading = false;
        if (sendMetric) {
          //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
          this.mixpanelService.track(
            MixpanelEvents.ACCESS_COOPERATOR_INCLUSION,
            {
              Origem: 'Tab de Colaborador',
            }
          );
        }
        break;
      case CooperatorTab.EXCLUSION:
        this.onLoading = true;
        this.collaboratorSearchingFormGroup.get('collaborator')?.reset();
        if (sendMetric) {
          //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
          this.mixpanelService.track(
            MixpanelEvents.ACCESS_COOPERATOR_EXCLUSION,
            {
              Origem: 'Tab de Colaborador',
            }
          );
        }
        break;
    }
  }

  listCollaborators(cbPageChanged?: Function) {
    this.operations = paginate(
      this.operationsRaw,
      this.maxPerPage,
      this.pageNumber
    );
    if (cbPageChanged) {
      cbPageChanged(this.pageNumber);
    }

    this.onLoading = true;
    let filter: any = this.collaboratorSearchingFormGroup.get('collaborator')
      ?.value
      ? this.collaboratorSearchingFormGroup.get('collaborator')?.value
      : '';

    if (!this.validateCpfToastr(filter)) {
      return;
    }

    this.collaboratorsService
      .searchCollaboratorsGeneralAndCancelInformationByIdAndFilter(
        this.idCompany,
        filter,
        this.pageNumber,
        this.maxPerPage
      )
      .then((result) => {
        this.operationsRaw = result.rows;
        this.totalRows = result.count;
        this.operations = paginate(this.operationsRaw, 5, this.pageNumber);
        this.filteredItems = this.operations.map(
          (collaborator: ICollaboratorCards) =>
            this.adaptResultToTableRows(collaborator)
        );
        this.mixpanelService.track(
          MixpanelEvents.SEARCH_COLLABORATORS_ENDPOINT_STATUS,
          { Sucesso: true, filtro: filter ? filter : 'sem filtro' }
        );
        if (cbPageChanged) {
          cbPageChanged(this.pageNumber);
        }
      })
      .catch((_) =>
        this.mixpanelService.track(
          MixpanelEvents.SEARCH_COLLABORATORS_ENDPOINT_STATUS,
          { Sucesso: false, filtro: filter ? filter : 'sem filtro' }
        )
      )
      .finally(() => {
        this.onLoading = false;
        this.cd.detectChanges();
      });
  }

  adaptResultToTableRows(
    collaborator: ICollaboratorCards
  ): CollaboratorRowGeneralView {
    return {
      name: collaborator.nome,
      cpf: collaborator.cpf,
      codBeneficiary: collaborator.codigoBeneficiario,
      codAssociated: collaborator.codigoAssociado,
      benefits: collaborator.beneficios,
      dateAdhesion: collaborator.dataAdesao,
      dateCancelRequest: collaborator.dataSolicitacaoCancelamento,
      plan: collaborator.plano,
      price: collaborator.preco,
      typeBeneficiary: collaborator.vinculo,
      includeInformation: collaborator.informacoesInclusao,
      nextInvoice: collaborator.proximaFatura,
    };
  }

  displayTooltipOnInit() {
    const firstAccess = !!this.userInfo?.firstAccessColaborador;
    if (!firstAccess) {
      this.userInfo.firstAccessColaborador = true;
      localStorage.setItem('user', JSON.stringify(this.userInfo));

      this.limitDateTooltip.open();
      setTimeout(() => {
        this.limitDateTooltip.close();
      }, this.TOOLTIP_TIMER);
    }
  }

  validateCpfToastr(value: string) {
    value = value?.replace(/\.|\-/g, '');
    const cpfPattern = new RegExp(/\d+/g);

    //TODO: put this on util file
    if (cpfPattern.test(value) && value.length > 11) {
      this.errorToastRef = this.toastrService.findDuplicate(
        '',
        'Informe apenas um CPF Válido (11 dígitos)',
        true,
        false
      );
      if (!this.errorToastRef) {
        this.errorToastRef = this.toastrService.error(
          'Informe apenas um CPF Válido (11 dígitos)',
          ''
        );
      }
      return false;
    }
    return true;
  }

  @HostListener('window:click', ['$event'])
  onWindowClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.matches('.link-default.bold')) {
      this.mixpanelService.track(MixpanelEvents.DOWNLOAD_SPREADSHEET_MODEL, {
        Sucesso: true,
      });
    }
  }

  getDependents(codAssociated: number) {
    this.collaboratorsService
      .getCollaboratorAndDependentsList(codAssociated)
      .then((result: any) => {
        this.collaboratorsDependentsInfo = result;
      });
  }

  openCancelConfirmationModal(collaborator: CollaboratorRowGeneralView) {
    const {
      codAssociated,
      codBeneficiary,
      cpf,
      typeBeneficiary,
      plan,
      price,
      nextInvoice,
      includeInformation,
      name,
    } = collaborator;
    this.collaboratorName = name;

    if (collaborator.typeBeneficiary === BeneficiaryType.COLLABORATOR) {
      this.collaboratorInfoRow = collaborator;
      this.collaboratorsService
        .getCollaboratorAndDependentsList(collaborator.codAssociated)
        .then((result: any) => {
          this.collaboratorsDependentsInfo = result;
        });
      const modalRef = this.modalService.open(ModalGenericStepComponent, {
        centered: true,
        windowClass: 'modalCancelConfirmation',
      });
      modalRef.componentInstance.icon =
        '../../../assets/svgs/attention-modal-icon.svg';
      modalRef.componentInstance.content = this.modalCancelConfirmation;
    } else {
      this.individualCancelFirstStepForm
        .get('beneficiaryCpf')
        ?.setValue(collaborator.cpf);
      this.individualCancelFirstStepForm
        .get('codBeneficiary')
        ?.setValue(collaborator.codBeneficiary);

      this.mixpanelService.track(MixpanelEvents.START_INDIVIDUAL_EXCLUSION, {
        cpf,
        codAssociated,
        codBeneficiary,
      });
      this.individualCancelModalRef = this.modalService.open(
        ModalGenericStepComponent,
        {
          centered: true,
          windowClass: 'individualCancelModalClass',
        }
      );
      this.individualCancelModalRef.componentInstance.icon =
        '../../../assets/svgs/cancel-modal-icon.svg';
      this.individualCancelModalRef.componentInstance.content =
        this.individualCancelStepper;

      this.individualCancelModalRef.result.then(
        (result) => {
          this.clearIndividualCancelFormData();
          console.log(`Closed with: ${result}`);
        },
        (reason) => {
          console.log(`Dismissed ${reason}`);
        }
      );
    }
  }

  openFinishCancelModal() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ModalGenericStepComponent, {
      centered: true,
      windowClass: 'modalCancelFinish',
    });
    modalRef.componentInstance.icon = '../../../assets/svgs/confirmed-icon.svg';
    modalRef.componentInstance.content = this.modalCancelFinish;
  }

  openSpreadsheetInclusionModal() {
    this.mixpanelService.track(MixpanelEvents.CHOOSE_INCLUSION_FORM, {
      'Tipo de inclusão': 'Planilha',
      'Exige matrícula': this.requiresRegistration,
    });
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/spreadsheed-modal-icon.svg';
    const spreadSheetModelURL = `<a target="_self" href="../../assets/spreadsheets/IMPORTAÇÃO.xlsx" download="modelo_planilha_inclusão.xlsx" class="link-default bold">Baixe aqui a planilha modelo</a>`;
    modalRef.componentInstance.title = 'Inclusão por planilha';
    modalRef.componentInstance.description =
      spreadSheetModelURL +
      `\ne preencha todas as informações antes \nde iniciar a solicitação.`;
    modalRef.componentInstance.buttonLabel = 'Iniciar solicitação';
    modalRef.result.then((result) => {
      if (result) {
        this.mixpanelService.track(MixpanelEvents.BEGIN_SPREADSHEET_INCLUSION);
        this.openInfoBeneficiaryPlan();
      }
    });
  }

  openInfoBeneficiaryPlan() {
    this.mixpanelService.track(MixpanelEvents.CHOOSE_INCLUSION_FORM, {
      'Tipo de inclusão': 'Planilha',
      'Exige matrícula': this.requiresRegistration,
    });
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Planos dos beneficiários';
    modalRef.componentInstance.description = `Ao organizar a planilha com os beneficiários,\n todos que são da mesma titularidade (titular ou dependente) precisam ser do mesmo plano.`;
    modalRef.componentInstance.buttonLabel = 'OK';
    modalRef.result.then((result) => {
      if (result) {
        this.mixpanelService.track(
          MixpanelEvents.INFO_PLAN_BENEFICIARY_SPREADSHEET_INCLUSION
        );
        this.openSpreadsheetInclusionStepper();
      }
    });
  }

  openSpreadsheetInclusionStepper() {
    this.firstStepSpreadsheetInclusionForm
      ?.get('subject')
      ?.setValue(this.requestSubjectObj.subject.description);
    this.firstStepSpreadsheetInclusionForm
      ?.get('subjectDetailed')
      ?.setValue(this.requestSubjectObj.subjectDetailed.description);

    this.spreadsheetInclusionModalRef = this.modalService.open(
      ModalGenericStepComponent,
      {
        centered: true,
        windowClass: 'inclusionBySpreadsheetStepperModal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    this.spreadsheetInclusionModalRef.componentInstance.icon =
      '../../../assets/svgs/spreadsheed-modal-icon.svg';
    this.spreadsheetInclusionModalRef.componentInstance.content =
      this.spreadsheetInclusionStepper;
    this.spreadsheetInclusionModalRef.result.then(
      (result) => {
        this.clearSpreadsheetInclusionFormData();
        console.log(`Closed with: ${result}`);
      },
      (reason) => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  changeShowDragAndDropOnCancelationReason(reason: String) {
    if (
      reason === 'Demissão' ||
      reason === 'Óbito' ||
      reason === 'Afastamento'
    ) {
      this.showDragAndDropOnCancelationReason = true;
    } else {
      this.showDragAndDropOnCancelationReason = false;
    }
  }

  upload() {
    return async (file: File) => {
      const response: any = await this.crmService.getSignedUrlFile(file?.name);
      return this.crmService
        .uploadFileBySignedUrl(response?.url, response?.novoNomeArquivo, file)
        .pipe();
    };
  }

  async checkPlanValidation() {
    await this.collaboratorsService
      .getPlansByCompanyS4eId(this.idCompany)
      .then((data: BuscarPlanoModel) => {
        const user = this.storageService.getItem('user');
        data.situacao = user?.situation;

        this.hasHolderPlans = this.validadorTitularSituacao.validar(data);
        this.beneficiaryOptions[BeneficiaryTypeOptions.COLLABORATOR].enabled =
          this.hasHolderPlans;

        this.hasDependentPlans = this.validadorDependenteSituacao.validar(data);
        this.beneficiaryOptions[BeneficiaryTypeOptions.DEPENDENT].enabled =
          this.hasDependentPlans;

        this.messagemErroInclusaoVidas = new DicionarioErrosNegocio(
          CodigosErro.PLANOS_NAO_CADASTRADO
        ).mensagem;

        const situacaoAtiva = this.validadorSituacao.validar(data);

        if (!situacaoAtiva) {
          this.messagemErroInclusaoVidas = new DicionarioErrosNegocio(
            CodigosErro.SITUACAO_DIFERENTE_ATIVO
          ).mensagem;
        }
      });
  }

  canAddBeneficiaryBySituation(situation: number): boolean {
    const ACTIVE_SITUATION = 1;
    const isActive = situation === ACTIVE_SITUATION;
    return isActive;
  }

  async checkHolderCheckbox(event: boolean) {
    if (event) {
      await this.collaboratorsService
        .getCctPlans(this.idCompany, DegreeOfKinship.Holder)
        .then((data) => (this.holderSpreadsheetPlans = this.mapPlans(data)));
      this.showHolderPlans = event;
      return;
    }
    this.choosePlanForm.reset();
    this.holderSpreadsheetPlans = [];
    this.showHolderPlans = event;
  }

  async checkDependentCheckbox(event: boolean) {
    if (event) {
      await this.collaboratorsService
        .getCctPlans(this.idCompany, DegreeOfKinship.Child, 0)
        .then((data) => (this.dependentSpreadsheetPlans = this.mapPlans(data)));
      this.showDependentPlans = event;
      return;
    }
    this.dependentChoosePlanForm.reset();
    this.dependentSpreadsheetPlans = [];
    this.showDependentPlans = event;
  }

  async sendCancelSolicitation() {
    this.onLoading = true;
    await this.checkFiles(this.filesList);

    const filesFinisheds = this.filesList
      ?.filter((el: any) => el?.metaData?.finished)
      ?.map((files: any) => decodeURI(files?.metaData?.filenameBucket));

    if (!filesFinisheds.length && this.showDragAndDropOnCancelationReason) {
      this.errorModalCb('individualCancelModalClass');
      this.mixpanelService.track(
        MixpanelEvents.FINISH_COLABORATOR_INDIVIDUAL_CANCEL,
        {
          SucessoCRM: true,
          SucessoUploadS3: false,
          SucessoUploadInicial: false,
          motivoCancelamento:
            this.individualCancelThirdStepForm.get('cancellationReason')?.value,
        }
      );
      this.onLoading = false;
    } else {
      const data = {
        requesterName:
          this.individualCancelFirstStepForm.get('fullName')?.value,
        cpf: this.individualCancelFirstStepForm.get('beneficiaryCpf')?.value,
        codBeneficiary:
          this.individualCancelFirstStepForm.get('codBeneficiary')?.value,
        requesterMail: this.individualCancelFirstStepForm.get('email')?.value,
        companyId: this.userInfo.companyId,
        nameFiles: filesFinisheds,
        collaboratorName: this.collaboratorName,
        referralMonthYear:
          this.individualCancelSecondStepForm.get('accrualDate')?.value?.label,
        dateCanUsePlan:
          this.individualCancelSecondStepForm.get('accrualDate')?.value
            ?.dateCanUsePlan,
        cancelReason:
          this.individualCancelThirdStepForm.get('cancellationReason')?.value,
      };
      this.crmService
        .sendCancelSolicitation(data)
        .then((res) => {
          this.modalService.dismissAll();
          const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
            centered: true,
          });
          modalRef.componentInstance.title =
            'Solicitação de cancelamento enviada';
          modalRef.componentInstance.description = `<strong><span style="color: #D9534F">Atenção:</span></strong> para acompanhar as atualizações desta solicitação acesse a aba Chamados, em Fale conosco.`;
          modalRef.componentInstance.icon =
            '../../../assets/svgs/confirmed-icon.svg';
          modalRef.componentInstance.label = 'Ir para Chamados';
          this.listCollaboratorsBasedOnTab(false);
          this.mixpanelService.track(
            MixpanelEvents.FINISH_COLABORATOR_INDIVIDUAL_CANCEL,
            {
              SucessoCRM: true,
              SucessoUploadS3: true,
              SucessoUploadInicial: true,
              motivoCancelamento:
                this.individualCancelThirdStepForm.get('cancellationReason')
                  ?.value,
            }
          );
          this.actionService.createAction({
            action: ActionsType.FINISH_COLABORATOR_INDIVIDUAL_CANCEL,
            companyIdS4e: this.idCompany,
          });
          this.clearIndividualCancelFormData();
        })
        .catch(() => {
          this.errorModalCb('individualCancelModalClass');
          this.mixpanelService.track(
            MixpanelEvents.FINISH_COLABORATOR_INDIVIDUAL_CANCEL,
            {
              SucessoCRM: true,
              SucessoUploadS3: true,
              SucessoUploadInicial: true,
              motivoCancelamento:
                this.individualCancelThirdStepForm.get('cancellationReason')
                  ?.value,
            }
          );
        })
        .finally(() => {
          this.onLoading = false;
        });
    }
  }

  nextStepS(stepper: CdkStepper) {
    stepper?.next();
    const currentStep = Number(stepper?._getFocusIndex());
    this.cdkStepper?.next();
    switch (currentStep) {
      case 0:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_CPF_AND_REGISTRATION_FIELDS_INDIVIDUAL_INCLUSION
        );
        break;
      case 1:
        this.mixpanelService.track(
          MixpanelEvents.FORWARD_STEP_INCLUSION_DATE_SPREADSHEET_INCLUSION
        );
        break;
      default:
        break;
    }
  }

  previousStepS(stepper: CdkStepper) {
    stepper?.previous();
  }

  selectAccrualDateForSpreadsheetInclusion(selected: any) {
    this.secondStepSpreadsheetInclusionForm.get('cutDate')?.setValue(selected);
  }

  accrualDateValueChangesSpreadsheetInclusion(value: any) {
    this.selectedAccrualDateSpreadsheetInclusion = value;
  }

  openCrmInclusionSpreadsheet(filesFinished: string[]) {
    const inclusionSpreadsheetObj: CollaboratorInclusionBySpreadsheet = {
      companyId: this.idCompany,
      reasonId: this.requestSubjectObj.subject?.id,
      reason: this.requestSubjectObj.subject?.description,
      reasonDetailedId: this.requestSubjectObj.subjectDetailed.id,
      reasonDetailed: this.requestSubjectObj.subjectDetailed.description,
      nameFiles: filesFinished,
      referralMonthYear:
        this.secondStepSpreadsheetInclusionForm.get('cutDate')?.value?.label,
      dateCanUsePlan:
        this.secondStepSpreadsheetInclusionForm.get('cutDate')?.value
          ?.dateCanUsePlan,
      description: `Data de inclusão: ${new Date().toLocaleDateString()} <br>
        Competência: ${
          this.secondStepSpreadsheetInclusionForm.get('cutDate')?.value?.label
        } <br>
        Motivo: ${this.requestSubjectObj.subject?.description} <br>
        Motivo Detalhado: ${
          this.requestSubjectObj.subjectDetailed.description
        } <br>
        <br>
        `,
    };

    this.collaboratorsService
      .collaboratorInclusionBySpreadsheet(
        this.idCompany,
        inclusionSpreadsheetObj
      )
      .then();
  }

  conclusionInclusion() {
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon = '../../../assets/svgs/confirmed-icon.svg';
    modalRef.componentInstance.title = 'Inclusões finalizadas';
    modalRef.componentInstance.description = `Os beneficiários foram salvos com sucesso!`;
    modalRef.componentInstance.buttonLabel = 'OK';
    this.clearSpreadsheetInclusionFormData();
  }

  async sendRequestInclusionBySpreadsheet(stepper: CdkStepper | null) {
    this.onLoading = true;
    // TODO remover upload de arquivos
    await this.checkFiles(this.filesListSpreadsheetInclusion);

    const filesFinished = this.filesListSpreadsheetInclusion
      ?.filter((el: any) => el?.metaData?.finished)
      ?.map((files: any) => decodeURI(files?.metaData?.filenameBucket));
    if (!filesFinished.length) {
      this.errorModalCb('inclusionBySpreadsheetStepperModal');
      this.mixpanelService.track(MixpanelEvents.FINISH_SPREADSHEET_INCLUSION, {
        SucessoCRM: false,
        SucessoUploadS3: false,
        SucessoUploadInicial: false,
        tipoDeErroUploadInicial: 'tamanhoArquivo/formatoArquivo',
      });
      this.onLoading = false;
    } else {
      this.colorProgress = '#1ABC77';
      this.doneInclusionBeneficiary = false;
      this.progressBar = 0;
      this.numberOfBeneficiariesWithError = 0;
      this.numberOfRegisteredBeneficiaries = 0;
      this.beneficiariesWithError = [];
      const formData = new FormData();
      const cutDate =
        this.secondStepSpreadsheetInclusionForm.get('cutDate')?.value;
      formData.append('anexo', this.filesListSpreadsheetInclusion[0].file);
      formData.append('competence', cutDate?.value);
      formData.append('idCompany', `${this.idCompany}`);
      formData.append(
        'holderPlanId',
        `${this.choosePlanForm.get('planId')?.value}`
      );
      formData.append(
        'dependentPlanId',
        `${this.dependentChoosePlanForm.get('planId')?.value}`
      );

      this.collaboratorsService
        .collaboratorInclusionBySpreadsheetSE4(formData)
        .then((s3: S3Object) => {
          stepper?.next();

          this.mixpanelService.track(
            MixpanelEvents.FINISH_SPREADSHEET_INCLUSION,
            {
              SucessoCRM: true,
              SucessoUploadS3: true,
              SucessoUploadInicial: true,
            }
          );

          this.actionService.createAction({
            action: ActionsType.INCLUDED_BY_SPREADSHEET,
            companyIdS4e: this.idCompany,
          });
          this.openCrmInclusionSpreadsheet([s3?.Key]);
          setTimeout(() => {
            this.startInterval();
          }, this.FIVE_SECONDS_IN_MILI_SECONDS);
        })
        .catch(() => {
          this.errorModalCb('inclusionBySpreadsheetStepperModal');
          this.mixpanelService.track(
            MixpanelEvents.FINISH_SPREADSHEET_INCLUSION,
            {
              SucessoCRM: false,
              SucessoUploadS3: false,
              SucessoUploadInicial: true,
            }
          );
        })
        .finally(() => {
          this.onLoading = false;
        });
    }
  }

  filesIncluded(files: any) {
    files.some((file: any) => {
      validateSpreadsheetFileColumns(
        file.file,
        this.REQUIRED_COLUMNS_SPREADSHEET_INCLUSION,
        'Importação'
      )
        .then()
        .catch((err: Error) => {
          this.filesListSpreadsheetInclusion = files.filter(
            (fileToCheck: any) => fileToCheck.file !== file.file
          );
          this.showSpreadsheetValidationFailedModal(err);
          this.mixpanelService.track(
            MixpanelEvents.FINISH_SPREADSHEET_INCLUSION,
            {
              SucessoCRM: false,
              SucessoUploadS3: false,
              SucessoUploadInicial: false,
              tipoDeErroUploadInicial: 'divergenciaColuna',
            }
          );
        });
    });
  }

  async deleteFile(file: any) {
    this.filesList.splice(this.filesList.indexOf(file), 1);
  }

  async deleteSpreadsheetInclusionFile(file: any) {
    this.filesListSpreadsheetInclusion.splice(
      this.filesListSpreadsheetInclusion.indexOf(file),
      1
    );
  }

  unsupportedFile(errorType: any, modalClassName: string) {
    this.hideOrShowModalByClass(modalClassName, true);

    this.sendMixPanelEventsForCollaboratorFileError(modalClassName, errorType);

    this.filesList = this.filesList?.filter((el: any) => !el?.metaData?.error);
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Erro no arquivo';
    modalRef.componentInstance.description =
      'O arquivo é inválido. Verifique o formato \ne o tamanho e tente novamente.';
    modalRef.componentInstance.buttonLabel = 'OK';
    modalRef.result.then((result) => {
      console.log(`Closed with: ${result}`);
      this.hideOrShowModalByClass(modalClassName, false);
    });
  }

  showSpreadsheetValidationFailedModal(error: Error) {
    this.hideOrShowModalByClass('inclusionBySpreadsheetStepperModal', true);
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = error.name;
    modalRef.componentInstance.description = error.message;
    modalRef.componentInstance.buttonLabel = 'OK';
    modalRef.result.then((result) => {
      console.log(`Closed with: ${result}`);
      this.hideOrShowModalByClass('inclusionBySpreadsheetStepperModal', false);
    });
  }

  clearSpreadsheetInclusionFormData() {
    this.firstStepSpreadsheetInclusionForm?.reset();
    this.secondStepSpreadsheetInclusionForm?.reset();
    this.spreadSheetRadioGroup?.clearSelected();
    this.filesListSpreadsheetInclusion = [];
  }

  clearIndividualCancelFormData() {
    this.individualCancelFirstStepForm?.get('fullName')?.reset();
    this.individualCancelFirstStepForm?.get('email')?.reset();
    this.individualCancelSecondStepForm?.reset();
    this.individualCancelThirdStepForm?.reset();
    this.individualCancelRadioGroup?.clearSelected();
    this.showDragAndDropOnCancelationReason = false;
    this.filesList = [];
  }

  hideOrShowModalByClass(classname: string, hide: boolean) {
    const modalRef = document.getElementsByClassName(
      classname
    )[0] as HTMLElement;
    modalRef.style.visibility = hide ? 'hidden' : '';
  }

  openChat() {
    //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
    this.mixpanelService.track(MixpanelEvents.CHAT_ACCESS, {
      Origem: 'Edição de dados do colaborador',
    });
    //[MIXPANEL] - Não substituir de colaboradores para beneficiário para não afetar a métrica envolvida.
    this.blipService.openBlip('colaboradores');
  }

  openModalEditContact() {
    this.mixpanelService.track(
      MixpanelEvents.CLICK_EDIT_COOPERATOR_INFORMATION
    );
    const subscribeRefPhone = this.editContactForm
      .get('phone')
      ?.valueChanges.subscribe((value: any) => {
        value?.length
          ? this.editContactForm
              .get('phone')
              ?.setValidators([
                Validators.required,
                CustomValidators.ValidateTelephoneNumber,
              ])
          : this.editContactForm.get('phone')?.clearValidators();
        this.editContactForm.get('phone')?.updateValueAndValidity();
      });

    const subscribeRefEmail = this.editContactForm
      .get('email')
      ?.valueChanges.subscribe((value: any) => {
        value?.length
          ? this.editContactForm
              .get('email')
              ?.setValidators([Validators.required, trimmedEmailValidator()])
          : this.editContactForm.get('email')?.clearValidators();
        this.editContactForm.get('email')?.updateValueAndValidity();
      });

    this.editContactModalRef = this.modalService.open(
      ModalGenericStepComponent,
      {
        centered: true,
        windowClass: 'editContactModalClass',
      }
    );
    this.editContactModalRef.componentInstance.icon =
      '../../../assets/svgs/spreadsheed-modal-icon.svg';
    this.editContactModalRef.componentInstance.content = this.modalEditContact;
    this.editContactModalRef.result.finally(() => {
      subscribeRefEmail?.unsubscribe();
      subscribeRefPhone?.unsubscribe();
    });
  }

  sucessEditContact() {
    this.modalService.dismissAll();
    this.mixpanelService.track(
      MixpanelEvents.FINISH_EDITING_COOPERATOR_INFORMATION,
      {
        Sucesso: true,
      }
    );
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/success-modal-icon.svg';
    modalRef.componentInstance.title = 'Contatos salvos';
    modalRef.componentInstance.description =
      'As alterações dos contatos foram salvas com sucesso!';
    modalRef.componentInstance.buttonLabel = 'OK';
  }

  errorEditContact() {
    this.modalService.dismissAll();
    this.mixpanelService.track(
      MixpanelEvents.FINISH_EDITING_COOPERATOR_INFORMATION,
      {
        Sucesso: false,
      }
    );
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });
    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Erro ao salvar';
    modalRef.componentInstance.description =
      'Ocorreu um erro ao salvar as alterações. Por favor, tente novamente.';
    modalRef.componentInstance.buttonLabel = 'Tentar novamente';

    modalRef.result.then(() => {
      this.openModalEditContact();
    });
  }

  editContact() {
    const formatterUndProperty = (value: any) => (value ? value : undefined);
    const bodySender = {
      telefone: formatterUndProperty(this.editContactForm.get('phone')?.value),
      celular: formatterUndProperty(
        this.editContactForm.get('mobilePhone')?.value
      ),
      email: formatterUndProperty(this.editContactForm.get('email')?.value),
    };

    this.collaboratorsService
      .editContactsByAssociated(
        this.collaboratorInfo?.codAssociated,
        bodySender
      )
      .then(() => {
        this.coolaboratorService
          .getCollaboratorInfoByCompanyAndAssociatedAndBeneficiary(
            this.idCompany,
            this.collaboratorInfo.codAssociated,
            this.collaboratorInfo.codBeneficiary
          )
          .then((res: any) => {
            Object.assign(this.collaboratorInfo, { ...res });
            this.benefits = res?.benefits;
          });
        this.sucessEditContact();
        this.editContactForm.reset();
      })
      .catch((_) => {
        this.errorEditContact();
      });
  }

  async checkFiles(files: any[]) {
    for (const key in files) {
      const file = files[key];
      if (!file?.metaData?.finished || !file?.metaData.filenameBucket) {
        try {
          const response: any = await this.crmService.getSignedUrlFile(
            file?.file?.name
          );
          await this.crmService
            .uploadFileBySignedUrl(
              response?.url,
              response?.novoNomeArquivo,
              file?.file
            )
            .toPromise();
          files[key].metaData.filenameBucket = response?.novoNomeArquivo;
          files[key].metaData.finished = true;
          files[key].metaData.error = false;
        } catch (error) {
          files[key].metaData.error = true;
          files[key].metaData.finished = false;
        }
      }
    }
  }

  errorModalCb(modalClass: string) {
    this.hideOrShowModalByClass(modalClass, true);
    const modalRef = this.modalService.open(ModalWithOneButtonComponent, {
      centered: true,
    });

    modalRef.componentInstance.icon =
      '../../../assets/svgs/attention-modal-icon.svg';
    modalRef.componentInstance.title = 'Erro ao enviar';
    modalRef.componentInstance.description = `Ocorreu um erro ao enviar as informações.\nPor favor, tente novamente.`;
    modalRef.componentInstance.buttonLabel = 'Tentar novamente';

    modalRef.result.then((result) => {
      console.log(`Closed with: ${result}`);
      this.hideOrShowModalByClass(modalClass, false);
    });
  }
  getActiveHolders(cpf: string) {
    this.collaboratorsService
      .getActiveHolders(this.idCompany, { cpf })
      .then((data: any) => {
        this.searchHolderErrorMessage = [];
        this.holder = [
          {
            value: {
              name: capitalizeName(data.nome),
              codAssociated: data.codigoAssociado,
              cpf: data.cpf,
            },
            checked: false,
            enabled: true,
            name: capitalizeName(data.nome),
            codAssociated: data.codigoAssociado,
            cpf: data.cpf,
          },
        ];
      })
      .catch(({ error }) => {
        const cpf = '"' + error.message.match(/(\d+)/g)?.join('') + '"';
        const message = error.message.replace(/(\d+)/g, '');
        this.searchHolderErrorMessage = [message, cpf];
      });
  }

  sendMixPanelEventsForCollaboratorFileError(
    classname: string,
    errorType: any
  ) {
    switch (classname) {
      case 'inclusionBySpreadsheetStepperModal':
        this.mixpanelService.track(
          MixpanelEvents.FINISH_SPREADSHEET_INCLUSION,
          {
            SucessoCRM: false,
            SucessoUploadS3: false,
            SucessoUploadInicial: false,
            tipoDeErroUploadInicial: errorType.errorFileExt
              ? 'formatoArquivo'
              : 'tamanhoArquivos',
          }
        );
        break;
      case 'individualCancelModalClass':
        this.mixpanelService.track(
          MixpanelEvents.FINISH_COLABORATOR_INDIVIDUAL_CANCEL,
          {
            SucessoCRM: false,
            SucessoUploadS3: false,
            SucessoUploadInicial: false,
            tipoDeErroUploadInicial: errorType.errorFileExt
              ? 'formatoArquivo'
              : 'tamanhoArquivos',
            motivoCancelamento:
              this.individualCancelThirdStepForm.get('cancellationReason')
                ?.value,
          }
        );
        break;
    }
  }

  getDegreeOfKinshipNameById(id: number) {
    return (
      this.degreeOfKinshipOptions.find((item) => item.id == id)?.label ||
      'não informado'
    );
  }
}
