<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
    <svg-icon src="{{ icon }}"></svg-icon>
</div>
<div class="margin-content">
    <svg-icon (click)="closeModal(false)" class="close-button" src="../../../assets/svgs/close-modal-icon.svg"></svg-icon>
    <p class="bold text-center">Gerar 2ª via do boleto</p>
    <div class="description-container">
        <p class="p3 regular text-center padding-bottom">
            Você será redirecionado ao site do banco e<br/>
            <strong>precisa ter em mãos o número do boleto vencido:</strong><br/>
            copie abaixo antes de continuar.
        </p>
    </div>

    <div fxLayout="column" class="invoice-code-input-container" fxLayoutAlign="center end"
        (click)="copyCodeClipboard()">
        <textarea class="form-control invoice-code-input" class="form-control" [(ngModel)]="code" [disabled]="true"
            [rows]="2" [cols]="50"></textarea>
        <svg-icon *ngIf="!hasCopiedCode" class="invoice-code-input-icon"
            src="../../../../assets/svgs/copy-clipboard.svg"></svg-icon>
        <svg-icon *ngIf="hasCopiedCode" class="invoice-code-input-icon"
            src="../../../../assets/svgs/copied-clipboard.svg"></svg-icon>
    </div>
    <default-button (onClick)="closeModal(true)" [disabled]="!hasCopiedCode" [label]="buttonLabel"></default-button>
</div>