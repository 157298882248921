import { BuscarPlanoModel } from "src/app/models/buscar-plano.model";
import { EstrategiaDeValidacao } from "../../../interfaces/EstrategiaValidacao";

export class EstrategiaDeValidacaoDePlanosTitular
  implements EstrategiaDeValidacao<BuscarPlanoModel>
{
  validar(empresa: BuscarPlanoModel): boolean {
    const { planoTitular } = empresa;
    const isValid = planoTitular?.length > 0;

    if (isValid) {
      // TODO: implementar o winston
      // console.log(
      //   'Validação de planos titular bem-sucedida para a empresa:',
      //   empresa
      // );
    }

    return isValid;
  }
}
