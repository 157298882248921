import { CodigosErro } from "../enums/codigos-erro";

type DicionarioErro = {
  [key in CodigosErro]: string;
};

export class DicionarioErrosNegocio {
  private readonly errorMessages: DicionarioErro = {
    [CodigosErro.PLANOS_NAO_CADASTRADO]:
      'Ocorreu um erro no resgate dos planos da empresa, entre em contato através do Fale conosco. (Erro - 10001).',
    [CodigosErro.SITUACAO_DIFERENTE_ATIVO]:
      'Ocorreu um erro no resgate de informações da empresa, entre em contato através do Fale conosco. (Erro - 10011).',
  };

  private _codigoErro: CodigosErro;
  private _mensagem: string;

  constructor(codigoErro: CodigosErro) {
    this._codigoErro = codigoErro;
    this._mensagem = this.restagarMensagemErro();
  }

  public get mensagem(): string {
    return this._mensagem;
  }

  private restagarMensagemErro(): string {
    return this.errorMessages[this._codigoErro] || 'Erro desconhecido.';
  }
}
