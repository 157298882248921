<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="{{ icon }}"></svg-icon>
</div>
<form [formGroup]="starsFormGroup" class="margin-content">
  <p [innerHtml]="title" class="p2 bold"></p>
  <div class="subtitle-container">
    <p [innerHtml]="subtitle" class="regular p4 secondary-light-text"></p>
  </div>

  <div class="spacing-container default-border-radius">
    <p class="semi-bold small secondary-light-text">Como você avalia a sua experiência geral na plataforma?</p>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="stars-container">
      <div *ngFor="let option of options; let i = index" fxLayout="column" fxLayoutAlign="space-between center">
        <div (click)="setReviewValue(i+1)">
          <svg-icon *ngIf="this.starsFormGroup?.get('reviewValue')?.value < i+1"
            src="../../../../../assets/svgs/empty-star.svg"></svg-icon>
          <svg-icon *ngIf="this.starsFormGroup?.get('reviewValue')?.value >= i+1"
            src="../../../../../assets/svgs/filled-star.svg"></svg-icon>
          <p class="regular small text-center" [ngClass]="{
              'gray-text': this.starsFormGroup?.get('reviewValue')?.value !== i+1,
              'primary-text': this.starsFormGroup?.get('reviewValue')?.value === i+1,
              'bold': this.starsFormGroup?.get('reviewValue')?.value === i+1
          }">{{option}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="spacing-container default-border-radius textarea-container">
    <div>
      <p class="semi-bold small">Algum comentário, sugestão ou reclamação que você gostaria de compartilhar?</p>
      <default-text-area formControlName="textAreaValue" placeholder="Digite aqui..."></default-text-area>
    </div>
  </div>
  <default-button class="spacing test" (onClick)="nextStep()" [disabled]="starsFormGroup?.invalid"
    [label]="buttonLabel"></default-button>
</form>
