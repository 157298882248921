import { Component, OnInit, ViewEncapsulation, EventEmitter, Input, Output, ViewChildren, QueryList } from '@angular/core';
import { CompanyDataSource } from 'src/app/models/company-datasource-model';

@Component({
  selector: 'company-switcher-table',
  templateUrl: './company-switcher-table.component.html',
  styleUrls: ['./company-switcher-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompanySwitcherTableComponent implements OnInit {

  @Input() dataSource: CompanyDataSource[] = [];
  @Input() loading: boolean = true;
  @Input() maxRowsLoaded: number = this.dataSource.length;;
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  selectedItem: CompanyDataSource

  constructor() { }

  ngOnInit(): void {

  }

  rowClicked(selectedRow: any) {
    this.selectedItem = selectedRow
    this.onRowClick.emit(selectedRow)
  }
}
