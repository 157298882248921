import { Injectable } from '@angular/core';
import { SideBarMenuComponent } from '../components/side-bar-menu/side-bar-menu.component';


@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  sidebarMenu: SideBarMenuComponent;

  constructor() { }

  changePages(url: string) {
    console.log(url)
  }

  setSideBarMenu(sidebarMenu: SideBarMenuComponent) {
    this.sidebarMenu = sidebarMenu;
  }

  getSideBarMenu(): SideBarMenuComponent {
    return this.sidebarMenu;
  }
}