import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtTokenService } from './auth/jwt-token.service';

@Injectable({
  providedIn: 'root',
})
export class InformativeService {
  private readonly INFORMATIVE_URL = '/api/news';
  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService,
  ) {}

  getInformativeNotRead(idCompanyS4e: number) {
    return this.http
      .get(`${this.INFORMATIVE_URL}/${idCompanyS4e}/pending`, {
        headers: this.jwtTokenService.getHeaderAuth().headers,
      })
      .toPromise();
  }

  setReadInformative(informativeId: number) {
    return this.http
      .post(`${this.INFORMATIVE_URL}/${informativeId}/read`, {
        headers: this.jwtTokenService.getHeaderAuth().headers,
      })
      .toPromise();
  }

}
