import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'default-tab-body',
  template: '<ng-template><ng-content></ng-content></ng-template>',
  styleUrls: ['./default-tab-body.component.scss']
})
export class DefaultTabBodyComponent {

  @ViewChild(TemplateRef) body: TemplateRef<any>;

  constructor() { }

}
