import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'help-button',
  templateUrl: './help-button.component.html',
  styleUrls: ['./help-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpButtonComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string = '../../../../assets/svgs/chat-icon.svg'

  constructor() { }

  ngOnInit(): void {
  }

}
