export enum MixpanelEvents {
  LOGIN = 'login',
  CHAT_ACCESS = 'acessoChat',
  INFORM_CNPJ_AND_PASSWORD_FOR_ACCESS = 'informarCNPJeSenhaParaAcesso',
  CREATE_NEW_PASSWORD = 'criarNovaSenha',
  ACCESS_RELATIONSHIP = 'acessoPortalDeRelacionamento',
  COLLABORATORS_ACCESS = 'acessoColaboradores',
  FINANCIAL_ACCESS = 'acessoFinanceiro',
  CARDS_ACCESS = 'acessoCarteirinha',
  MY_PROFILE_ACCESS = 'acessoMeuPerfil',
  HOMEPAGE_ACCESS = 'acessoMenuPrincipal',
  BANNERS_CLICK = 'cliquesNoBanner',
  LOGOUT = 'sairDaPlataforma',
  CONTACT_US_ACCESS = 'acessoPortalDeRelacionamento',
  START_CHANGE_PASSWORD = 'iniciarAlteracaoDeSenha',
  CHANGE_PASSWORD = 'alterarSenha',
  CLICK_EDIT_CONTACT_INFORMATION = 'cliquesEditarDadosDeContato',
  FINISH_EDIT_CONTACT_INFORMATION = 'finalizarEdicaoDadosDeContato',
  CANCEL_EDIT_CONTACT_INFORMATION = 'cancelarEdicaoDeDados',
  CLICK_START_CRM_OPEN = 'cliquesIniciarAberturaDeChamado',
  OPEN_CRM = 'abrirChamado',
  CHATS_STARTED = 'chatsIniciados',
  ACCESS_COOPERATOR_INCLUSION = 'acessoInclusaoDeColaborador',
  ACCESS_COOPERATOR_EXCLUSION = 'acessoCancelamentoColaborador',
  PROVIDE_CNPJ = 'informarCNPJ(ES)',
  CLICK_COOPERATOR_INFORMATION_DETAILED = 'cliquesDetalhamentoColaborador',
  CLICK_EDIT_COOPERATOR_INFORMATION = 'cliquesEditarDadosDeColabordador',
  FINISH_EDITING_COOPERATOR_INFORMATION = 'finalizarEdiçãoDeDadosColaborador',
  CHOOSE_INCLUSION_FORM = 'escolherFormaDeInclusao',
  CHOOSE_BENEFICIARY_TYPE = 'escolherTipoDeBeneficiario_InclusaoIndividual',
  FORWARD_STEP_CPF_AND_REGISTRATION_FIELDS_INDIVIDUAL_INCLUSION = 'avancarCamposCPFeMatricula_InclusaoIndividual',
  FORWARD_STEP_GENERAL_DATA_INDIVIDUAL_INCLUSION = 'avancarDadosGeraisColaborador_InclusaoIndividual',
  FORWARD_STEP_CONTACT_DATA_INDIVIDUAL_INCLUSION = 'avancarDadosDeContatoColaborador_InclusaoIndividual',
  FORWARD_STEP_INCLUSION_DATE_INDIVIDUAL_INCLUSION = 'avancarDataDeInclusao_InclusaoIndividual',
  FINISH_INDIVIDUAL_INCLUSION = 'FinalizarInclusao_InclusaoIndividual',
  DOWNLOAD_SPREADSHEET_MODEL = 'baixarPlanilhaModelo_InclusaoPorPlanilha',
  BEGIN_SPREADSHEET_INCLUSION = 'iniciarInclusaoPorPlanilha_InclusaoPorPlanilha',
  INFO_PLAN_BENEFICIARY_SPREADSHEET_INCLUSION = 'informacaoPlanoBeneficiario_InclusaoPorPlanilha',
  FORWARD_STEP_REQUESTER_DATA_SPREADSHEET_INCLUSION = 'avancarDadosSolicitante_InclusaoPorPlanilha',
  FORWARD_STEP_INCLUSION_DATE_SPREADSHEET_INCLUSION = 'avancarDataDeInclusao__InclusaoPorPlanilha',
  FINISH_SPREADSHEET_INCLUSION = 'FinalizarInclusão__InclusaoPorPlanilha',
  START_INDIVIDUAL_EXCLUSION = 'iniciarCancelamentoIndividual',
  FORWARD_STEP_REQUESTER_DATA_INDIVIDUAL_EXCLUSION = 'avancarDadosSolicitante_CancelamentoIndividualIndividual',
  FORWARD_STEP_EXCLUSION_DATE_INDIVIDUAL_EXCLUSION = 'avancarDataDeCancelamento_CancelamentoIndividual',
  CLICKS_REQUEST_SPREADSHEET_EXCLUSION = 'cliquesSolicitarCancelamentoPorPlanilha',
  ACCESS_INVOICE_HISTORIC_FINANCIAL = 'acessoHistoricoDeFaturas_Financeiro',
  ACCESS_SIMULATED_INVOICE_FINANCIAL = 'acessoSimularFatura_Financeiro',
  ACCESS_VIEW_INVOICE_FINANCIAL = 'acessoVisualizarFatura_Financeiro',
  DOWNLOAD_NF_FINANCIAL = 'baixarNotaFiscal_Financeiro',
  DOWNLOAD_PAYMENT_INVOICE_FINANCIAL = 'baixarBoletoFaturaAtual_Financeiro',
  DOWNLOAD_PDF_FINANCIAL = 'baixarFaturaPDF_Financeiro',
  DOWNLOAD_SPREADSHEET_FINANCIAL = 'baixarFaturaExcel_Financeiro',
  CLOSE_DETAILED_INVOICE_FINANCIAL = 'fecharVisualizacaoFatura_Financeiro',
  GENERATE_SECOND_PAYMENT_INVOICE_FINANCIAL = 'gerarSegundaViaBoleto_Financeiro',
  ACCESS_FORGOT_PASSWORD = 'acessoEsqueciSenha(ES)',
  INSERT_VERIFICATION_CODE = 'inserirCodigoDeVerificacao(ES)',
  CREATE_NEW_PASSWORD_IN_FORGOT_PASSWORD = 'criarNovaSenha(ES)',
  SEARCH_CARDS = 'realizarBuscaCarteirinha',
  RETURNS_SOMETHING_CARDS = 'retornouAlgoCarteirinha',
  FINISH_COLABORATOR_INDIVIDUAL_CANCEL = 'FinalizarCancelamento__CancelamentoIndividual',
  PLATFORM_CSAT = 'CSAT_Plataforma',
  CES_INDIVIDUAL_HOLDER_INCLUSION = 'CES_InclusaoDeTitularIndividual',
  CES_HOLDER_INCLUSION_BY_SPREADSHEET = 'CES_InclusaoDeTitularPorPlanilha',
  CES_INDIVIDUAL_HOLDER_CANCELLATION = 'CES_CancelamentoDeTitularIndividual',
  CES_FINANCIAL = 'CES_Financeiro',
  VIDEO_PAGE_ACCESS = 'acessoPaginaComVideos',
  SEARCH_COLLABORATORS_ENDPOINT_STATUS = 'statusEndpointBuscarColaboradores',
  CURRENT_INVOICE_ENDPOINT_STATUS = 'statusEndpointFaturaAtual',
  HISTORIC_INVOICE_ENDPOINT_STATUS = 'statusEndpointHistoricoDasFaturas',
  MOVIMENTATION_LIST_ENDPOINT_STATUS = 'statusEndpointListagemDeMovimentacoes',
  COMPANY_LIST_ENDPOINT_STATUS = 'statusEndpointListagemDeEmpresas',
  DUE_AND_LATE_INVOICES_ENDPOINT_STATUS = 'statusEndpointFaturasEmAbertoEAtrasadas',
  PAID_INVOICES_ENDPOINT_STATUS = 'statusEndpointFaturasPagas',
  COMPARATIVE_CHART_ENDPOINT_STATUS = 'statusEndpointGraficoComparativo',
  USER_INFORMATION_ENDPOINT_STATUS = 'statusEndpointInformacoesDoUsuario',
  DETAILED_INVOICE_HEADER_ENDPOINT_STATUS = 'statusEndpointFaturaDetalhadaCabecalho',
  DETAILED_INVOICE_OPERATIONS_ENDPOINT_STATUS = 'statusEndpointFaturaDetalhadaMovimentacoes',
  CURRENT_INVOICE_ENDPOINT_SUCCESS = 'statusEndpointFaturaAtualSucesso',
}
