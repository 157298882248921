import { DefaultTabBodyComponent } from './../default-tab-body/default-tab-body.component';
import { DefaultTabLabelComponent } from './../default-tab-label/default-tab-label.component';
import { Component, OnInit, Input, ContentChild } from '@angular/core';

@Component({
  selector: 'default-tab-item',
  template: '<ng-content></ng-content>',
  styleUrls: ['./default-tab-item.component.scss']
})
export class DefaultTabItemComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  isActive: boolean;

  @ContentChild(DefaultTabBodyComponent)
  bodyComponent: DefaultTabBodyComponent;

  @ContentChild(DefaultTabLabelComponent)
  labelComponent: DefaultTabLabelComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
