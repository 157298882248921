<div class="card" fxLayout="column" fxLayoutGap="1.5vmin">
    <div class="card-body">

        <div fxLayout="row" fxLayoutAlign="space-between center" class="height-100">

            <!-- STATUS DA FATURA -->

            <div fxLayout="column" fxLayoutGap="1vmin">
                <h3 class="invoice-title bold secondary-light"
                    [ngClass]="{'success': invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_MADE, 'error': invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_DELAYED}">
                    {{invoice?.referralMonthYear}} - {{invoiceStatusLabelByStatus(invoice?.invoiceStatus)}}</h3>
                <p *ngIf="invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_DELAYED"
                    class="invoice-description-info small semi-bold gray-dark">Clique
                    para gerar a 2ª via do boleto e realizar o pagamento</p>
            </div>

            <!-- SEÇÃO DE "VER FATURA" -->

            <div fxLayout="row" fxLayoutGap="1vmin">
                <default-button *ngIf="invoice?.invoiceStatus === invoiceStatusEnum.WAITING_CLOSING"
                    (onClick)="seeInvoice()" icon="../../../../assets/svgs/spreadsheet-icon.svg" label="Simular fatura"
                    class="p4"></default-button>
                <extra-button *ngIf="invoice?.invoiceStatus !== invoiceStatusEnum.WAITING_CLOSING"
                    (onClick)="seeInvoice()" icon="../../../../assets/svgs/spreadsheet-icon.svg" iconOrientation="start"
                    [typeStyle]="extraButtonType.GREY_OUTLINE" label="Ver fatura" class="p4"></extra-button>
            </div>
        </div>
    </div>

    <!-- SEPARADOR -->
    <div class="separator"></div>

    <!-- INFORMAÇÕES SOBRE A FATURA -->
    <div class="card-body">

        <!-- VALOR / VENCIMENTO / DISPONIBILIDADE -->

        <div fxLayout="row" fxLayoutAlign="space-between center" class="height-100">
            <div *ngIf="invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_MADE || invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_NOT_MADE"
                fxLayout="column" class="invoice-due-date-and-availability">
                <p class="invoice-value bold primary"
                    [ngClass]="{'success': invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_MADE}">
                    {{invoice?.totalValue}}
                <p class="invoice-due-date small semi-bold gray-dark">Vencimento em {{invoice?.dueDate}}</p>
            </div>

            <div *ngIf="invoice?.invoiceStatus === invoiceStatusEnum.WAITING_CLOSING || invoice?.invoiceStatus === invoiceStatusEnum.INVOICE_CLOSED"
                fxLayoutGap="0.5vmin" fxLayout="column" class="invoice-due-date-and-availability">
                <p class="invoice-value bold primary">
                    {{invoice?.totalValue}}</p>
                <p class="invoice-due-date small semi-bold gray-dark">Vencimento em {{invoice?.dueDate}}</p>
                <p class="invoice-available-date small semi-bold gray-dark">Boleto disponível a partir de
                    {{invoice?.availableDate}}</p>
            </div>

            <div *ngIf="invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_DELAYED" fxLayout="column"
                class="invoice-value-and-interest">
                <p class="invoice-value bold primary error">
                    {{invoice?.totalValue}} </p>
                <p class="invoice-due-date small semi-bold gray-dark">{{invoice?.valueWithoutInterest}} (vencimento em
                    {{invoice?.dueDate}})</p>
                <p class="invoice-interest-value small semi-bold gray-dark">+{{invoice?.interest}} (acréscimos de
                    juros)</p>
            </div>

            <!-- NOTA FISCAL / BOLETO -->

            <div fxLayout="row" fxLayoutGap="2vmin">
                <default-button
                    *ngIf="invoice?.invoiceStatus === invoiceStatusEnum.PAYMENT_NOT_MADE && invoice?.canDownloadInvoice"
                    (onClick)="downloadPaymentInvoice(invoice?.canDownloadInvoice)" icon="../../../../assets/svgs/download-file.svg"
                    label="Baixar boleto" class="p4"></default-button>
                <extra-button *ngIf="invoice?.invoiceStatus == invoiceStatusEnum.PAYMENT_DELAYED"
                    (onClick)="emitSecondInvoice(invoice?.canDownloadSecondPaymentSlip)" icon="../../../../assets/svgs/export-icon.svg"
                    iconOrientation="start" [typeStyle]="extraButtonType.LIGHT_RED" label="Gerar 2ª via"
                    class="p4"></extra-button>
                <extra-button
                    *ngIf="invoice?.invoiceStatus != invoiceStatusEnum.WAITING_CLOSING && invoice?.invoiceStatus != invoiceStatusEnum.INVOICE_CLOSED && invoice?.canDownloadTaxInvoice"
                    (onClick)="downloadNF(invoice?.canDownloadTaxInvoice)" iconOrientation="start" [typeStyle]="extraButtonType.GREY_OUTLINE"
                    label="Baixar NF" class="p4"></extra-button>
            </div>

        </div>
    </div>
</div>
