<table class="table">
  <tbody>
    <tr *ngFor="let row of dataSource">
      <td>
        <input class="form-check-input" type="radio" name="companySelection" (change)="rowClicked(row)" />
      </td>
      <td class="col-11">
        <span [ngClass]="{'name-title p4': true,'row-selected' : selectedItem && selectedItem.id == row.id}">
          {{row.name | titlecase}}</span>
        <span [ngClass]="{'table-subtitle-row small': true, 
        'row-selected': selectedItem && selectedItem.id == row.id}">
          CNPJ: {{row.cnpj}}
        </span>
      </td>
    </tr>
  </tbody>
  <tbody [hidden]="!loading">
    <tr *ngFor="let i of [].constructor(maxRowsLoaded)">
      <td>
        <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '40px'
                    }"></ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #noDataRegisteredYet>
  <div *ngIf="!dataSource?.length && !loading;" class="no-data-registered" fxFlex fxLayoutAlign="center center">
    <p class="label-no-data-registered p1 bold">Ainda não há movimentações cadastradas na plataforma.</p>
  </div>
</ng-template>