import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'default-checkbox',
  templateUrl: './default-checkbox.component.html',
  styleUrls: ['./default-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultCheckboxComponent implements OnInit {
  @Input() label: string;
  @Input() control: FormControl;
  @Input() disabled: boolean; 


  @Output() checked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  checkedChanged(event: boolean) {
    this.checked.emit(event);
  }

}
