import { MixpanelEvents } from './../enums/mixpanelEvents';
import { MixpanelService } from './mixpanel.services';
import { ForgotPasswordEmail } from './../models/forgot-password-email.model';
import { JwtTokenService } from './auth/jwt-token.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class BlipService {
  blipChatFunction: any
  constructor( private mixpanelService: MixpanelService) {

  }
  openBlip(origem: string) {

    this.mixpanelService.track(
      MixpanelEvents.CHAT_ACCESS,
      { 'Origem': origem }
    );
    if (this.blipChatFunction) {
      this.blipChatFunction.toogleChat();
    } else {
      this.blipChatFunction = (window as any).blipChat();
    }
  }

  checkIfHasBlipChatOpened() {
    return this.blipChatFunction;
  }
}
