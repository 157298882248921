import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import { BeneficiaryType } from 'src/app/enums/beneficiary-type';
import { RadioInputComponent } from '../../radio-input/radio-input.component';

@Component({
  selector: 'card-beneficiary-type',
  templateUrl: './card-beneficiary-type.component.html',
  styleUrls: ['./card-beneficiary-type.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardBeneficiaryTypeComponent implements OnInit {
  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input() options: any = [];
  @Input() label: string;
  @Input() formControlName: any;
  @Input() radioButtonBeneficiaryOptions: any[]
  @ViewChild('radioInput') radioInputRef: RadioInputComponent;

  ngOnInit(): void {

  }

  beneficiaryTypeChanged(value: BeneficiaryType) {
    this.selectChange.emit(value);
  }

  clearSelected(){
    this.radioInputRef.clearSelected()
  }
}
