<header label="Financeiro">
  <default-pill #limitDateTooltip="ngbTooltip" [disabled]="true"
    [label]="'Data limite de movimentação: '+ limitDateForMoving" data-placement="bottom"
    [ngbTooltip]="tooltipContentFinances" class="ngb-tooltip-dark" placement="bottom" tooltipClass="limit-date-tooltip">
  </default-pill>
</header>

<ng-template #tooltipContentFinances>
  <div class="tooltip-finances-container" fxLayout="row" fxLayoutAlign="start center">
    <span>Sua data limite está aqui! Lembre-se sempre dela antes de realizar
      qualquer movimentação.</span>
  </div>
</ng-template>



<div class="financial-page-container" fxLayout="column" fxLayoutGap="2vmin">
  <default-tabs [(activeTab)]="activeTab" (activeTabChange)="reloadMovimentationList()">
    <default-tab-item>
      <default-tab-label>Fatura atual</default-tab-label>
      <default-tab-body>
        <div fxLayout="column" fxLayoutGap="4vmin">
          <div class="header-invoice-information-container" fxLayout="row" fxLayoutAlign="space-between">
            <div fxFlex="78">
              <card-invoice-status-info [(invoice)]="invoice" class="card-invoice-financial"
                (onDownloadNF)="openUrlFilePage($event, openUrlFilePageOrigin.DOWNLOAD_NF_ON_CURRENT_INVOICE)"
                (onDownloadPaymentInvoice)="openUrlFilePage($event, openUrlFilePageOrigin.DOWNLOAD_PAYMENT_INVOICE_ON_CURRENT_INVOICE)"
                (onEmitSecondInvoice)="openUrlFilePage($event, openUrlFilePageOrigin.EMIT_SECOND_INVOICE_ON_CURRENT_INVOICE)"
                (onSeeInvoiceClick)="openDetailedInvoice(this.invoice?.invoiceId)"></card-invoice-status-info>
            </div>
            <div fxFlex="20">
              <card-help-chat helpLabel="Dúvidas sobre a sua fatura?" helpLinkLabel="Clique aqui para falar no chat"
                buttonIcon="../../../../assets/svgs/chat-icon-2.svg" (onButtonClick)="openBlip()"
                (onLinkClick)="openBlip()"></card-help-chat>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="2vmin">
            <h2 class="bold secondary">Movimentações</h2>

            <financial-operations-table [dataSource]="operations" [loading]="isLoading"></financial-operations-table>

            <div *ngIf="operations?.length" fxFlex fxLayoutAlign="end end"
              [ngClass]="{'offset-paginator-blipchat-opened': !!blipService.checkIfHasBlipChatOpened()}">
              <default-paginator itemLabel="movimentações" [(total)]="totalRows" [pageNumber]="pageNumber"
                (onPageChange)="pageChangedOperationsTable($event)" [maxPerPage]="maxPerPage"></default-paginator>
            </div>
          </div>
        </div>
      </default-tab-body>
    </default-tab-item>

    <default-tab-item>
      <default-tab-label>
        <div fxLayoutAlign="start center" fxLayout="row">
          Histórico das faturas
          <svg-icon *ngIf="invoiceHistoricStatus === invoiceHistoricStatusEnum.PAYMENT_LATE" class="icon-late-payment"
            src="../../../../assets/svgs/info-filled.svg"></svg-icon>
          <svg-icon *ngIf="invoiceHistoricStatus === invoiceHistoricStatusEnum.PAYMENT_DUE" class="icon-due-payment"
            src="../../../../assets/svgs/info-filled.svg"></svg-icon>
        </div>
      </default-tab-label>
      <default-tab-body>
        <div class="invoice-historic-container" fxLayout="column" fxLayoutGap="1vmin">
          <h2 class="bold secondary">Gráfico comparativo</h2>
          <invoice-line-chart [data]="invoiceChartDatasource" class="offset-bottom-chart"></invoice-line-chart>
          <div fxLayout="column" fxLayoutGap="4vmin">
            <div *ngIf="lateInvoices.length" fxLayout="column" fxLayoutGap="1vmin">
              <div fxLayoutAlign="start center" fxLayout="row">
                <h2 class="bold secondary">Faturas atrasadas</h2>
                <svg-icon class="icon-late-payment" src="../../../../assets/svgs/info-filled.svg"></svg-icon>
              </div>
              <financial-invoices-historic-table [dataSource]="lateInvoices" [loading]="isLoadingDueAndLateInvoices"
                [tableMode]="invoiceHistoricStatusEnum.PAYMENT_LATE"
                (onDownloadNFeClick)="openUrlFilePage($event, openUrlFilePageOrigin.DOWNLOAD_NF_ON_LATE_INVOICES)"
                (onGetPaymentSlipCopyClick)="openUrlFilePage($event, openUrlFilePageOrigin.GET_PAYMENT_SLIP_ON_LATE_INVOICES)"
                (onCheckInvoice)="openDetailedInvoice($event)" [maxRowsLoaded]="1"></financial-invoices-historic-table>
            </div>
            <div *ngIf="dueInvoices.length" fxLayout="column" fxLayoutGap="2vmin">
              <div fxLayoutAlign="start center" fxLayout="row">
                <h2 class="bold secondary">Faturas em aberto</h2>
                <svg-icon class="icon-due-payment" src="../../../../assets/svgs/info-filled.svg"></svg-icon>
              </div>
              <financial-invoices-historic-table [dataSource]="dueInvoices" [loading]="isLoadingDueAndLateInvoices"
                [tableMode]="invoiceHistoricStatusEnum.PAYMENT_DUE"
                (onDownloadNFeClick)="openUrlFilePage($event, openUrlFilePageOrigin.DOWNLOAD_NF_ON_DUE_INVOICES)"
                (onCreatePaymentSlipClick)="openUrlFilePage($event, openUrlFilePageOrigin.CREATE_PAYMENT_SLIP_ON_DUE_INVOICES)"
                (onCheckInvoice)="openDetailedInvoice($event)" [maxRowsLoaded]="1"></financial-invoices-historic-table>
            </div>
            <div class="paid-invoices-table" fxLayout="column" fxLayoutGap="2vmin">
              <h2 class="bold secondary">Faturas pagas</h2>
              <financial-invoices-historic-table [dataSource]="paidInvoices"
                [tableMode]="invoiceHistoricStatusEnum.PAID"
                (onDownloadNFeClick)="openUrlFilePage($event, openUrlFilePageOrigin.DOWNLOAD_NF_ON_PAID_INVOICES)"
                (onCheckInvoice)="openDetailedInvoice($event)" [loading]="isLoadingPaidInvoices" [maxRowsLoaded]="5">
              </financial-invoices-historic-table>
            </div>
            <div *ngIf="paidInvoices.length" fxFlex fxLayoutAlign="end end"
              [ngClass]="{'offset-paginator-blipchat-opened': !!blipService.checkIfHasBlipChatOpened()}">
              <default-paginator itemLabel="faturas" [(total)]="totalRows" [pageNumber]="pageNumber"
                (onPageChange)="pageChangedInvoicesTable($event)" [maxPerPage]="maxPerPage"></default-paginator>
            </div>
          </div>
        </div>
      </default-tab-body>
    </default-tab-item>
  </default-tabs>
</div>
