import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'captalizewords',
})
export class captalizeWordsPipe implements PipeTransform {
  transform(value: any): string {
    const partes = value?.trim().toLowerCase().split(' ') ?? [];

    const stringFormatada: string = partes
      .filter((item: string) => item)
      .map((parte: string) => {
        return parte[0].toUpperCase() + parte.slice(1, parte.length);
      })
      .join(' ');

    return stringFormatada;
  }
}
