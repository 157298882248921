import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { JwtTokenService } from '../services/auth/jwt-token.service';
import { Permissions } from '../enums/permissions';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(protected router: Router,
    private jwtService: JwtTokenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url === '/home/beneficiarios' && !this.jwtService.hasPermission(Permissions.COLLABORATORS)) {
      this.router.navigate(['/menu/homepage']);
      return false;
    }
    if (state.url === '/home/financeiro' && !this.jwtService.hasPermission(Permissions.FINANCIAL)) {
      this.router.navigate(['/menu/homepage']);
      return false;
    }

    if (state.url === '/home/carteirinhas' && !this.jwtService.hasPermission(Permissions.CARDS)) {
      this.router.navigate(['/menu/homepage']);
      return false;
    }


    if (state.url === '/home/fale-conosco' && !this.jwtService.hasPermission(Permissions.CONTACT_US)) {
      this.router.navigate(['/menu/homepage']);
      return false;
    }


    if (state.url === '/home/meu-perfil' && !this.jwtService.hasPermission(Permissions.MY_PROFILE)) {
      this.router.navigate(['/menu/homepage']);
      return false;
    }

    if (state.url === '/home') {
      this.router.navigate(['/home/homepage']);
      return true;
    }

    return true;
  }
}
