import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ExtraButtonType } from '../../enums/extra-button-type';
import { MixpanelService } from '../../services/mixpanel.services';
import { MixpanelEvents } from '../../enums/mixpanelEvents';
import { CollaboratorsService } from '../../services/collaborators.service';
import { ChangeDetectorRef } from '@angular/core';
import { BenefitTypes } from '../../enums/benefit-types';
import { ICollaboratorCards } from 'src/app/models/collaborator-card.model';
import { CardsTableItem } from '../../models/collaborator-card-table-item.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalWithOneButtonComponent } from '../../components/modals/modal-with-one-button/modal-with-one-button.component';
import { BlipService } from 'src/app/services/blip.service';

@Component({
  selector: 'app-cards-page',
  templateUrl: './cards-page.component.html',
  styleUrls: ['./cards-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CardsPageComponent implements OnInit, OnDestroy {
  cardsFormGroup: FormGroup = new FormGroup({
    colaborator: new FormControl({ value: '', disabled: false }),
    benefits: new FormControl(null),
  });

  errorToastRef: ActiveToast<any>;

  companyId: number;
  user: any;

  selectedBenefits = ['Agiben', 'Clin', 'Api Saúde'];

  collaboratorsList: ICollaboratorCards[];

  filteredCardsTableItems: CardsTableItem[] = [];

  maxPerPage: number = 5;
  pageNumber: number = 1;
  totalRows: number = 0;

  onLoading: boolean = true;

  constructor(
    private toastrService: ToastrService,
    private mixpanelService: MixpanelService,
    private collaboratorsService: CollaboratorsService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private blipService: BlipService
  ) {
    const localStorageUser: any = localStorage.getItem('user');
    this.user = JSON.parse(localStorageUser);
    this.companyId = this.user.companyId;
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.listCollaboratorsWithCards();
  }

  removeBenefitFromSelectedList(selected: string) {
    this.selectedBenefits = this.selectedBenefits.filter(
      (benefit) => benefit !== selected
    );
  }

  getRowBenefitWasClicked(row: any) {
    let type = '';
    switch (row.selectedBenefit.code) {
      case ExtraButtonType.LIGHT_YELLOW:
        type = BenefitTypes.AGIBEN;
        break;
      case ExtraButtonType.LIGHT_GREEN:
        type = BenefitTypes.CLIN;
        break;
      case ExtraButtonType.LIGHT_BLUE:
        type = BenefitTypes.MELHORAI;
        break;
    }

    this.collaboratorsService
      .getCollaboratorCardByType(type, row.codBeneficiary)
      .then((res: any) => {
        const blob = new Blob([res], { type: 'application/pdf' });
        window.open(URL.createObjectURL(blob));
      }).catch(async err => {
        const blob = new Blob([err.error], { type: 'text/plain' });
        const errorObj = JSON.parse(await blob.text())
        const modalRef = this.modalService.open(ModalWithOneButtonComponent, { centered: true, windowClass: "genericErrorModal" });
        modalRef.componentInstance.icon = '../../../assets/svgs/attention-modal-icon.svg';
        modalRef.componentInstance.title = 'Ocorreu um erro';
        modalRef.componentInstance.description = errorObj.error;
        modalRef.componentInstance.buttonLabel = 'OK';
        modalRef.result.then((result) => {}, (reason) => {})});
  }

  adaptResultToTableRows(collaborator: ICollaboratorCards) {
    let benefits: any = [];
    collaborator.beneficios.forEach((e) => {
      switch (e.toLowerCase()) {
        case BenefitTypes.CLIN:
          benefits.push({ code: ExtraButtonType.LIGHT_GREEN, label: 'Clin' });
          break;
        case BenefitTypes.AGIBEN:
          benefits.push({
            code: ExtraButtonType.LIGHT_YELLOW,
            label: 'Agiben',
          });
          break;
        case BenefitTypes.MELHORAI:
          benefits.push({
            code: ExtraButtonType.LIGHT_BLUE,
            label: 'Api Saúde',
          });
          break;
      }
    });

    return {
      name: collaborator.nome,
      cpf: collaborator.cpf,
      codBeneficiary: collaborator.codigoBeneficiario,
      benefits,
    };
  }

  pageChanged(data: any) {
    this.pageNumber = data.pageNumber;
    this.listCollaboratorsWithCards(data.cb);
  }

  listCollaboratorsWithCards(cbPageChanged?:Function) {
    this.onLoading = true;
    let filter = this.cardsFormGroup.get('colaborator')?.value
      ? this.cardsFormGroup.get('colaborator')?.value
      : '';

    filter = filter.replace(/\.|\-/g, '');
    const cpfPattern = new RegExp(/\d+/g);

    this.mixpanelService.track(MixpanelEvents.SEARCH_CARDS, {
      nomeOuCpfPesquisado: filter,
      filtro: null,
    });

    if (!this.validateCpfToastr(filter)) {
      return
    }

    // TODO: reintegrar este endpoint, pois foi alterado na task 16762
    this.collaboratorsService
      .searchCollaboratorsByIdAndFilter(
        this.companyId,
        filter,
        this.pageNumber,
        this.maxPerPage
      )
      .then((result: any) => {
        this.totalRows = result?.count;
        const hasResult = result?.rows?.length;

        this.filteredCardsTableItems = result.rows.map(
          (collaborator: ICollaboratorCards) =>
            this.adaptResultToTableRows(collaborator)
        );

        if(cbPageChanged) {
          cbPageChanged(this.pageNumber)
        }

        this.mixpanelService.track(MixpanelEvents.RETURNS_SOMETHING_CARDS, {
          retornouAlgo: !!hasResult,
        });
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        this.onLoading=false;
        this.cd.detectChanges();
      });
  }

  validateCpfToastr(value: string) {
    value = value?.replace(/\.|\-/g, '');
    const cpfPattern = new RegExp(/\d+/g);

    if (cpfPattern.test(value) && value.length > 11) {
      this.errorToastRef = this.toastrService.findDuplicate(
        '',
        'Informe apenas um CPF Válido (11 dígitos)',
        true,
        false
      );
      if (!this.errorToastRef) {
        this.errorToastRef = this.toastrService.error(
          'Informe apenas um CPF Válido (11 dígitos)',
          ''
        );
      }
      return false;
    }

    return true;
  }
}
