import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export type InputType = 'email' | 'password' | 'text';
@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: InputType = 'text';
  @Input() formControlName: string = '';
  @Input() disabled: boolean = false;
  @Input() value: any = '';
  @Input() mask: string = '';
  @Input() errorMessage: string = '';
  @Input() timeToDebounce: number;
  @Input() showInputReturn: boolean = true;

  @Output() valueChanges: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('searchInputField')
  searchInputField?: ElementRef<HTMLInputElement>;

  hide: boolean;
  capsLockMessage: string | undefined;
  showCapsLockInfo: boolean;
  focused: boolean = false;
  showResult: boolean = false;

  onChange: any = () => {
    this.valueChanges.emit(this.value);
  };
  onTouched: any = () => {};

  subject: Subject<any> = new Subject();

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdRef: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    if (this.timeToDebounce) {
      this.ngControl.control?.valueChanges
        .pipe(debounceTime(this.timeToDebounce))
        .subscribe((value) => {
          this.valueChanges.emit(value);
          if (this.ngControl.control?.value) {
            this.showResult = true;
          } else {
            this.showResult = false;
          }
        });
    }
  }

  ngAfterViewInit() {
    const eventHandler = (event: KeyboardEvent | MouseEvent) => {
      if (event?.type === 'keyup' && !(event instanceof KeyboardEvent)) {
        return;
      }

      if (event instanceof KeyboardEvent && event?.code === 'Enter') {
        this.valueChanges.emit(this.ngControl.control?.value);
        if (this.ngControl.control?.value) {
          this.showResult = true;
        } else {
          this.showResult = false;
        }
        return;
      }

      this.cdRef.markForCheck();
    };

    this.searchInputField?.nativeElement?.addEventListener(
      'keyup',
      eventHandler
    );
    this.searchInputField?.nativeElement?.addEventListener(
      'mousedown',
      eventHandler
    );
  }

  ngOnDestroy() {
    this.searchInputField?.nativeElement?.removeAllListeners?.('keyup');
    this.searchInputField?.nativeElement?.removeAllListeners?.('mousedown');
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeFocus({ type }: FocusEvent) {
    this.focused = type === 'focus';
  }

  clearInputField() {
    this.value = '';
    this.ngControl.control?.setValue('');
  }

  onSearchIconClicked() {
    this.valueChanges.emit(this.ngControl.control?.value);
    if (this.ngControl.control?.value) {
      this.showResult = true;
    } else {
      this.showResult = false;
    }
  }
}
