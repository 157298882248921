<table class="table table-bordered" fxLayout="column">
  <thead class="custom-header-bordered" [hidden]="!dataSource?.length && !loading">
    <tr>
      <th fxFlex="15" sortable="dateOperation" (sort)="onSort($event)" fxLayout="column" fxLayoutGap="1vmin"
        fxLayoutAlign="start start">
        <span class="header-title bold p3">Data</span>
      </th>
      <th class="no-side-padding" fxFlex="30" sortable="nameCpf" (sort)="onSort($event)" fxLayout="column"
        fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span class="header-title bold p3">Nome e CPF</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" sortable="bond" (sort)="onSort($event)" fxLayoutGap="1vmin"
        fxLayoutAlign="start start">
        <span class="header-title bold p3">Vínculo</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" sortable="type" (sort)="onSort($event)" fxLayoutGap="1vmin"
        fxLayoutAlign="start start">
        <span class="header-title bold p3">Tipo</span>
      </th>
      <th class="no-side-padding" fxFlex="15" fxLayout="row" sortable="value" (sort)="onSort($event)"
        fxLayoutGap="1vmin" fxLayoutAlign="center center">
        <span class="header-title bold p3">Atual (R$)</span>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="dataSource?.length && !loading;else noDataRegisteredYet">
    <tr *ngFor="let row of dataSource">
      <td class="custom-border-left" fxFlex="15" fxLayoutAlign="start center">
        <span class="name-title semi-bold p3">{{row.dateOperation}}</span>
      </td>
      <td class="no-side-padding" fxFlex="30" fxLayout="row" fxLayoutGap="1.5vmin" fxLayoutAlign="start center">
        <div>
          <div>
            <p3 class="name-title semi-bold p3">{{row.name | titlecase}}</p3>
          </div>
          <div>
            <p4 class="table-subtitle-row"> CPF: {{row.cpf | cpf}}</p4>
          </div>
        </div>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1.5vmin" fxLayoutAlign="start center">
        <span class="name-title regular p3">{{row.bond}}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start center">
        <span class="regular p3">{{row.type | titlecase}}</span>
      </td>
      <td class="no-side-padding custom-border-right" fxFlex="15" fxLayout="row" fxLayoutGap="3.65vmin"
        fxLayoutAlign="center center">
        <span class="semi-bold p3" [ngClass]="{'negative': row.type === 'Cancelamento'}">{{row.type ===
          'Cancelamento' ? '- ' : '+ '}} {{ row.value }}</span>
      </td>
    </tr>
  </tbody>

  <tbody [hidden]="!loading">
    <tr *ngFor="let i of [].constructor(maxRowsLoaded)">
      <td fxFlex="15" class="custom-border-left" fxLayout="column">
        <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }"></ngx-skeleton-loader>
      </td>
      <td fxFlex="30" class="no-side-padding">
        <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }">
        </ngx-skeleton-loader>
      </td>
      <td fxFlex class="no-side-padding">
        <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }">
        </ngx-skeleton-loader>
      </td>
      <td fxFlex class="no-side-padding">
        <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '70%',
                    'height': '68px'
                    }">
        </ngx-skeleton-loader>
      </td>
      <td fxFlex="15" class="custom-border-right">
        <ngx-skeleton-loader [theme]="{
                    'background': 'linear-gradient(125.67deg, #E3E3E3 -10.32%, rgba(220, 219, 219, 0) 97.42%)',
                    'border-radius': '6px',
                    'width': '100%',
                    'height': '68px'
                    }">
        </ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #noDataRegisteredYet>
  <div *ngIf="!dataSource?.length && !loading;" class="no-data-registered" fxFlex fxLayoutAlign="center center">
    <p class="label-no-data-registered p1 bold">Ainda não há movimentações cadastradas na plataforma.</p>
  </div>
</ng-template>
