import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

const LIMIT_MESSAGE_LENGTH = 150;
@Component({
  selector: 'modal-benefits-share',
  templateUrl: './modal-benefits-share.component.html',
  styleUrls: ['./modal-benefits-share.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [Clipboard]
})
export class ModalBenefitsShareComponent implements OnInit, AfterViewInit {
  @Input() icon: string = '';
  @Input() link: string = '';
  @Input() message: string = '';
  hasCopiedLink: boolean = false;
  hasCopiedMessage: boolean = false;

  @ViewChild('messageModalBenefitsTextArea', { static: false, read: ElementRef }) messageModalBenefitsTextArea: ElementRef;  
  @ViewChild('linkModalBenefitsInput', { static: false, read: ElementRef }) linkModalBenefitsInput: ElementRef;  

  form = new FormGroup({
    link: new FormControl({ value: this.link, disabled: true }, []),
    message: new FormControl({ value: this.message, disabled: true }, []),
  });

  constructor(
    private activeModal: NgbActiveModal,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.form.setValue({
      link: this.link,
      message: this.message,
    });

    this.dynamicallyAdjustTextAreaFontSize()
  }

  copy(object: any, location: string) {    
    const {event, value} = object
    this.setActualCopiedField(location);
    if(navigator.clipboard) {
      navigator.clipboard.writeText(value)
    }
    this.cd.detectChanges();
  }

  setActualCopiedField(location: string) {
    switch (location) {
      case 'MENSAGEM':
        this.hasCopiedLink = false;
        this.hasCopiedMessage = true;
        break;
      case 'LINK':
        this.hasCopiedLink = true;
        this.hasCopiedMessage = false;
        break;
    }
  }

  closeModal(redirectTo: boolean) {
    this.activeModal.close(redirectTo);
  }

  dynamicallyAdjustTextAreaFontSize() {
    const messageLength = this.message.length
    if(messageLength > LIMIT_MESSAGE_LENGTH) {
      this.renderer.addClass(this.messageModalBenefitsTextArea.nativeElement, 'reduced-font-size')
      this.renderer.addClass(this.linkModalBenefitsInput.nativeElement, 'reduced-font-size')
    }
  }
}
