<div class="position-logo" fxLayout="column" fxLayoutAlign="space-around center">
  <svg-icon src="../../../../assets/svgs/chat-modal-icon.svg"></svg-icon>
</div>
<div class="margin-content">
  <p class="bold">Histórico de atualizações</p>
  <div class="messages-container">
    <div *ngFor="let message of messages">
      <div *ngIf="message.sender === 'Administrativo'" class="admin-response-box default-border-radius">
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p class="responsible-text bold small">{{message.sender}}</p>
            <p class="semi-bold small date-text">{{message.date}}</p>
          </div>
          <div *ngIf="message?.message" class="description-container">
            <p class="regular small">{{message.message}}</p>
          </div>
        </div>
        <div *ngIf="message?.attachments?.length">
          <hr>
          <div fxLayout="row" fxLayoutAlign="stretch center">
            <svg-icon src="../../../../assets/svgs/attachment-icon.svg"></svg-icon>
            <p class="semi-bold small gray-dark-text-color" style="margin-left: 8px;">Anexos</p>
          </div>
          <div fxLayout="row" class="files-container">
            <div fxFlex="25" *ngFor="let attachment of message?.attachments let i = index">
              <a class="semi-bold small secondary-light-text-color" [href]="attachment.urlDownload">{{attachment.fileName}}</a>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="message.sender !== 'Administrativo'" class="user-response-box default-border-radius">
        <div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p class="responsible-text bold small">{{message.sender}}</p>
            <p class="semi-bold small date-text">{{message.date}}</p>
          </div>
          <div *ngIf="message?.message" class="description-container">
            <p class="regular small">{{message.message}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form style="padding-top: 2.6vmin" [formGroup]="fileFormGroup">
    <chat-input (selectFile)="handleSelectFiles($event)" [formControlName]="fileFormGroup.get('files')"></chat-input>
  </form>
  <div class="files-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1vmin">
    <div class="file-container default-border-radius"
      *ngFor="let file of fileFormGroup.get('files')?.value; let i=index" fxLayoutAlign="space-between center"
      fxLayout="row" fxLayoutGap="1vmin">
      <p class="regular small secondary-light-text-color">{{file.name}}</p>
      <svg-icon src="../../../../assets/svgs/close-grey-chat-input-icon.svg" style="height: 18px"
        (click)="removeThisFile(i)"></svg-icon>
    </div>
  </div>
</div>
