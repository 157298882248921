import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'quarternary-link-button',
  templateUrl: './quarternary-link-button.component.html',
  styleUrls: ['./quarternary-link-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class QuarternaryLinkButtonComponent {

  @Input() label: string = '';
  @Input() icon: string | undefined = undefined;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  click(){
    this.onClick.emit();
  }

}
