<div *ngFor="let option of options" class="form-check form-check-inline d-flex">
  <div [ngClass]="{'card-beneficiary': true , 'disable' : !option.enabled}">
    <div>
      <div *ngIf="!option.cpf">
        <p class="p3">
          {{ option.label }}
        </p>
      </div>
      <div *ngIf="option.cpf">
        <p class="p3 semi-bold secondary-light-text-color">{{ option.name }}</p>
      </div>
      <div *ngIf="option.cpf">
        <p class="semi-bold p4 gray-dark-text-color">CPF: {{ option.cpf | cpf }}</p>
      </div>
    </div>
    <input
      class="form-check-input"
      type="radio"
      [value]="option.value"
      (change)="selectChanged(option)"
      [checked]="option.checked"
      [disabled]="!option.enabled"
    />
  </div>
</div>
