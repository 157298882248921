import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.url.includes(environment.apiUrl) && !request.url.startsWith('../') && !request.url.startsWith('https://s3')) {
      request = request.clone({ url: environment.apiUrl + request.url });
    }
    return next.handle(request);
  }
}
