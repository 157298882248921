export enum OpenUrlFilePageOrigin {
  DOWNLOAD_NF_ON_CURRENT_INVOICE = 0,
  DOWNLOAD_PAYMENT_INVOICE_ON_CURRENT_INVOICE = 1,
  EMIT_SECOND_INVOICE_ON_CURRENT_INVOICE = 2,
  DOWNLOAD_NF_ON_LATE_INVOICES = 3,
  GET_PAYMENT_SLIP_ON_LATE_INVOICES = 4,
  DOWNLOAD_NF_ON_DUE_INVOICES = 5,
  CREATE_PAYMENT_SLIP_ON_DUE_INVOICES = 6,
  DOWNLOAD_NF_ON_PAID_INVOICES = 7,
}
