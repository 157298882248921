<div class="fileUploadWrapper">
  <form [formGroup]="form" class="form-group">
    <div class="column">

      <p class="alert-top-title" [innerHtml]="alert"></p>

      <div fxLayout="column">
        <div fxLayout="row">
          <label class="semi-bold spacing title">{{ label }}</label>

          <label *ngIf="isOptional" class="semi-bold spacing title optional"> (opcional)</label>
        </div>

        <p class="small grey-scale-normal">{{ description }}</p>
      </div>

      <!--Input and drag to upload files-->
      <div class="fileupload">
        <div
          dragAndDropUpload
          (click)="fileField.click()"
          (fileDropped)="upload($event)"
          fxLayout="column"
          fxLayoutAlign="center center"
          class="retangle-drag-and-drop"
        >
          <svg-icon src="../../../assets/svgs/cloud-icon.svg"></svg-icon>

          <p class="upload-label" [innerHtml]="uploadLabel"></p>
        </div>

        <input
          formControlName="files"
          type="file"
          name="avatars"
          #fileField
          (change)="upload($any($event).target.files)"
          hidden
          multiple
        />
      </div>

      <!--List of files to upload-->
      <div class="image-list" *ngFor="let file of files; let i = index">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div *ngIf="!file?.metaData?.error; else errorIcon" class="svg-icon-left">
              <svg-icon src="../../../assets/svgs/file-icon.svg"></svg-icon>
            </div>
            <ng-template #errorIcon>
              <div class="svg-icon-left">
                <svg-icon src="../../../assets/svgs/error-file-icon.svg"></svg-icon>
              </div>
            </ng-template>

            <p class="filename-label">{{ file?.file?.name }}</p>
          </div>

          <div
            class="error-label-upload"
            *ngIf="file?.metaData?.error"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <p class="filename-label error-label-upload">Falha ao enviar</p>

            <div class="svg-icon-right">
              <svg-icon style="cursor: pointer" (click)="deleteClicked(file)" src="../../../assets/svgs/delete-icon-red.svg"></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
