import { HttpEvent, HttpEventType, HttpStatusCode } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { bytesToMegaBytes } from 'src/app/utils/utils';
@Component({
  selector: 'drag-and-drop-upload',
  templateUrl: './drag-and-drop-upload.component.html',
  styleUrls: ['./drag-and-drop-upload.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DragAndDropUploadComponent implements OnInit {
  @Input() alert: string = '';
  @Input() label: string = '';
  @Input() description: string = '';
  @Input() isOptional: boolean = true;

  @Input()
  uploadLabel: string = `Arraste e solte o arquivo aqui<br /><strong><u>ou procure no computador</u></strong>`;

  @Input() promise: any = null;

  @Input() allFilesFinished: boolean = true;
  @Output() allFilesFinishedChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() onSentEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onHeaderReceivedEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadOnProgressEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() onResponseReceivedEvent: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReload: EventEmitter<any> = new EventEmitter<any>();
  @Output() onError: EventEmitter<any> = new EventEmitter<any>();
  @Output() unsupportedFile: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFilesNotAllowed: EventEmitter<any> = new EventEmitter<any>();

  @Input() files: any[] = [];
  @Input() allowedFilesExtension: string[] = [];
  @Input() allowedFileSizeInMB: number = 100;
  @Output() filesChange: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;

  constructor(public fb: FormBuilder) {
    this.form = this.fb.group({
      files: [null],
    });
  }
  ngOnInit() { }

  setIfAllFilesFinished() {
    const filesNotFinished = this.files?.filter((el) => !el?.metaData?.finished && !el?.metaData?.error);
    this.allFilesFinished = !!filesNotFinished.length;
    this.allFilesFinishedChange.emit(this.allFilesFinished);
  }

  async upload(e: any) {

    this.setIfAllFilesFinished();

    const fileListAsArray: any[] = Array.from(e);

    for await (const item of fileListAsArray) {
      const elementToInsert = Object.assign({
        file: item,
        metaData: { finished: false, progress: 0, error: false },
      });
      const filenameParts = item.name.split('.')
      const extFile = filenameParts[filenameParts.length - 1]
      if (!this.allowedFilesExtension.includes(extFile) || bytesToMegaBytes(item.size) > this.allowedFileSizeInMB) {
        this.onFilesNotAllowed.emit(filenameParts[0])
        return;
      }
      this.files.push(elementToInsert);
    }
    this.filesChange.emit(this.files)

    this.form.patchValue({
      files: this.files,
    });
    this.form.get('files')?.updateValueAndValidity();

    const filesToUpload = this.files?.filter((el) => !el?.metaData?.finished);

    for await (const file of filesToUpload) {
      try {
        if (this.promise) {
          (await this.promise(file?.file)).subscribe(
            (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  this.onSentEvent.emit(true);
                  break;
                case HttpEventType.ResponseHeader:
                  this.onHeaderReceivedEvent.emit();
                  break;
                case HttpEventType.UploadProgress:
                  file.metaData.progress = Math.round(
                    (event.loaded / (event.total || 0)) * 100
                  );
                  this.uploadOnProgressEvent.emit(file.metaData.progress);
                  break;
                case HttpEventType.Response:
                  const fileName = event?.url?.substring(event?.url?.lastIndexOf('/') + 1, event?.url?.indexOf('?'));
                  file.metaData.filenameBucket = fileName;
                  setTimeout(() => {
                    this.setIfAllFilesFinished();
                    file.metaData.finished = true;
                    this.onResponseReceivedEvent.emit();
                  }, 3000);
              }
            },
            (err: any) => {
              console.log(err);
              this.setIfAllFilesFinished();
              file.metaData.finished = false;
              file.metaData.progress = 0;
              file.metaData.error = true;
              this.onError.emit(err);
            }
          );
        }
      } catch (error: any) {
        this.setIfAllFilesFinished();
        file.metaData.finished = false;
        file.metaData.progress = 0;
        file.metaData.error = true;
        this.onError.emit(error);
        if (error?.status == HttpStatusCode.UnsupportedMediaType) {
          this.unsupportedFile.emit(file);
        }
      }
    }
  }

  async uploadSpecificFile(file: any) {

    this.setIfAllFilesFinished();

    const fileFilter = this.files?.findIndex(
      (elFile: any) => elFile?.file?.name == file?.file?.name
    );
    if (fileFilter !== -1) {
      this.files[fileFilter].metaData = {
        finished: false,
        progress: 0,
        error: false,
      };

      try {
        if (this.promise) {
          (await this.promise(file?.file)).subscribe(
            (event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Sent:
                  this.onSentEvent.emit(true);
                  break;
                case HttpEventType.ResponseHeader:
                  this.onHeaderReceivedEvent.emit();
                  break;
                case HttpEventType.UploadProgress:
                  this.files[fileFilter].metaData.progress = Math.round(
                    (event.loaded / (event.total || 0)) * 100
                  );
                  this.uploadOnProgressEvent.emit(this.files[fileFilter].metaData.progress);
                  break;
                case HttpEventType.Response:
                  const fileName = event?.url?.substring(event?.url?.lastIndexOf('/') + 1, event?.url?.indexOf('?'));
                  file.metaData.filenameBucket = fileName;
                  setTimeout(() => {
                    this.setIfAllFilesFinished();
                    file.metaData.finished = true;
                    this.onResponseReceivedEvent.emit();
                  }, 3000);
              }
            },
            (err: any) => {
              this.setIfAllFilesFinished();
              this.files[fileFilter].metaData.finished = false;
              this.files[fileFilter].metaData.progress = 0;
              this.files[fileFilter].metaData.error = true;
              this.onError.emit(err);
            }
          );
        }
      } catch (error) {
        this.setIfAllFilesFinished();
        this.files[fileFilter].metaData.finished = false;
        this.files[fileFilter].metaData.progress = 0;
        this.files[fileFilter].metaData.error = true;
        this.onError.emit(error);
      }
    }
  }

  deleteClicked(file: any) {
    this.onDelete.emit(file);
  }

  reloadClicked(file: any) {
    this.uploadSpecificFile(file);
    this.onReload.emit(file);
  }
}
