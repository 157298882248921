<div fxLayout="row" fxFlex fxLayoutGap="2vmin" fxLayoutAlign="space-between center" class="banner-container">

  <div fxFlexOffset="5vmin" fxFlex="fill">
    <h2 *ngIf="!hasADebit" class="bold banner-component-title">Boas vindas à plataforma RH!</h2>

    <h2 *ngIf="hasADebit" class="bold banner-component-title-has-a-debit">Sua fatura está atrasada!</h2>


    <p *ngIf="!hasADebit" class="regular p2 banner-component-message">Garanta mais praticidade nas suas atividades
      diárias e no
      gerenciamento dos seus beneficiários.
    </p>

    <p *ngIf="hasADebit" class="regular p2 banner-component-message">Ainda não identificamos o pagamento da fatura
      referente à {{competence}}.
    <p *ngIf="hasADebit" class="regular p2 banner-component-message">Acesse o <span class="bold">Financeiro</span> para
      gerar a 2ª via do boleto.</p>
  </div>


  <div fxFlex="40" fxLayoutAlign="end">
    <svg-icon *ngIf="!hasADebit" src="../../../assets/svgs/welcome-rh.svg"></svg-icon>
    <svg-icon *ngIf="hasADebit" src="../../../assets/svgs/banner-problem.svg"></svg-icon>
  </div>

</div>
