import { DefaultTabLabelComponent } from './default-tab-label/default-tab-label.component';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DefaultTabItemComponent } from './default-tab-item/default-tab-item.component';
import { DefaultTabBodyComponent } from './default-tab-body/default-tab-body.component';
import { DefaultTabsComponent } from './default-tabs/default-tabs.component';

@NgModule({
  declarations: [
    DefaultTabsComponent,
    DefaultTabItemComponent,
    DefaultTabLabelComponent,
    DefaultTabBodyComponent,
  ],
  imports: [CommonModule],
  exports: [
    DefaultTabsComponent,
    DefaultTabItemComponent,
    DefaultTabLabelComponent,
    DefaultTabBodyComponent,
  ],
})
export class DefaultTabsModule {}
