import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'tertiary-button',
  templateUrl: './tertiary-button.component.html',
  styleUrls: ['./tertiary-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TertiaryButtonComponent implements OnInit {
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() icon?: string = '';

  @Output() onClick?: EventEmitter<any> = new EventEmitter();

  protected eventEmit() {
    this.onClick?.emit();
  }

  constructor() {}

  ngOnInit(): void {}
}
