import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-white-button',
  templateUrl: './white-button.component.html',
  styleUrls: ['./white-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhiteButtonComponent implements OnInit {
  @Input() label: string;
  @Input() onClick: () => void;
  @Input() disable: boolean;
  constructor() { }

  ngOnInit(): void {
  }


}
