<div class="card border-card text-white mb-3">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="card-header" [ngClass]="{ 'w-body': (contacts && contacts.length) }" >
        <p class="semi-bold">{{ title }}</p>
        <p class="semi-bold">{{ sac }}</p>
    </div>
    <div *ngIf="contacts && contacts.length" fxLayout="column" class="body-card-contact">

        <div *ngFor="let contact of contacts" fxLayout="column" class="card-body">

          <div *ngIf="contact.name" class="repeat-contact-list" fxLayout="row">
            <svg-icon [src]="ICON_NAME"></svg-icon>
            <p class="label-contact">{{ contact.name | phone }}</p>
          </div>

          <div *ngIf="contact.email" class="repeat-contact-list" fxLayout="row">
            <svg-icon [src]="ICON_MAIL"></svg-icon>
            <p class="label-contact">{{ contact.email }}</p>
          </div>

          <div *ngIf="contact.phone" class="repeat-contact-list" fxLayout="row">
            <svg-icon [src]="ICON_PHONE"></svg-icon>
            <p class="label-contact">{{ contact.phone | phone }}</p>
          </div>

        </div>

    </div>
  </div>
