import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';

@Injectable()
export class CompanyIntercetor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const userInfo = JSON.parse(localStorage.getItem('user') || 'null');

    if (userInfo && (userInfo.companyContextId || userInfo.companyId)) {
      request = request.clone({
        setHeaders: {
          'x-company': userInfo.companyContextId ? userInfo.companyContextId.toString() : userInfo.companyId.toString(),
        },
      });
    }

    return next.handle(request);
  }
}
