import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'default-tab-label',
  template: "<ng-template><div class='tab-label-head-container'><ng-content></ng-content></div></ng-template>",
  styleUrls: ['./default-tab-label.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultTabLabelComponent {

  @ViewChild(TemplateRef) label: TemplateRef<any>;

  constructor() { }
}
