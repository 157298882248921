<div *ngFor="let option of options" class="form-check form-check-inline d-flex">
  <div class="card-beneficiary">
    <div>
      <div class="d-flex">
        <p class="plan-label">
          {{ option.label }}
        </p>
        <p class="frequency">
          /{{ option.frequency }}
        </p>
      </div>
      <div>
        <p class="price">
          {{ option.price }}&nbsp;
        </p>
      </div>
    </div>
    <input class="form-check-input" type="radio" [value]="option.value" (change)="selectChanged(option)"
      [checked]="option.checked" [disabled]="!option.enabled" />
  </div>
</div>
