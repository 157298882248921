import { HttpParams } from '@angular/common/http';
import { ValidatorFn } from '@angular/forms';
import * as XLSX from 'xlsx';

export function containsNumbers(str: string) {
  return /\d/.test(str);
}

export function isOver18YearsOld(dataNascimento: string) {
  const partesData = dataNascimento.split('/');
  if (partesData.length !== 3) {
    return true;
  }
  const diaNascimento = parseInt(partesData[0]);
  const mesNascimento = parseInt(partesData[1]);
  const anoNascimento = parseInt(partesData[2]);

  const dataNascimentoObj = new Date(
    anoNascimento,
    mesNascimento - 1,
    diaNascimento
  );

  const dataAtual = new Date();

  const diffEmMilissegundos =
    dataAtual.getTime() - dataNascimentoObj.getTime();

  const anos = diffEmMilissegundos / (1000 * 60 * 60 * 24 * 365);

  return anos >= 18;
}

export function containsUppercase(str: string) {
  return /[A-Z]/.test(str);
}

export function matchValidator(control: any, controlTwo: any): ValidatorFn {
  return () => {
    if (control.value !== controlTwo.value)
      return { match_error: 'As senhas não correspondem' };
    return null;
  };
}

export function formatCNPJ(cnpj: string): string {
  const cnpjOnlyDigits = cnpj.replace(/\D/g, '');
  return cnpjOnlyDigits.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  );
}

export function convertDateFromBrToUsPattern(date: string): string {
  let usDate;
  if (date.includes('/')) {
    usDate = date.split('/');
    usDate = usDate[2] + '-' + usDate[1] + '-' + usDate[0];
  }

  if (date.includes('-')) {
    usDate = date.split('-');
    usDate = usDate[2] + '-' + usDate[1] + '-' + usDate[0];
  }
  return usDate || '';
}

export function capitalizeName(fullName: string): string {
  return fullName
    .split(' ')
    .map((name) => {
      name = name.toLowerCase();
      name = name.charAt(0).toUpperCase() + name.slice(1);
      return name;
    })
    .join()
    .replace(/,/g, ' ');
}
export function validateSpreadsheetFileColumns(
  spreadsheet: File,
  REQUIRED_COLUMNS: string[],
  sheetNameToCheck: string = 'IMPORTAÇÃO'
): Promise<void> {
  const promise = new Promise<void>((resolve, reject) => {
    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(spreadsheet);
    reader.onload = (e: any) => {
      try {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        let wsname: string = '';
        /* selected the first sheet */
        for (let index = 0; index < wb.SheetNames.length; index++) {
          const sn = wb.SheetNames[index];
          if (sn === sheetNameToCheck) {
            wsname = sn;
          }
        }

        if (!wsname) {
          throw new Error;
        }

        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        var all_row = XLSX.utils.sheet_to_json(ws, {
          header: 1,
          blankrows: true,
        });

        const header_row_columns: string[] = [];
        const header: any = all_row[0]
        for (const key in header) {
          if (Object.prototype.hasOwnProperty.call(header, key)) {
            header_row_columns.push(header[key]);
          }
        }
        if (
          !REQUIRED_COLUMNS.every((column: string) => {
            return header_row_columns.includes(column);
          })
        ) {
          throw new Error;
        }
        resolve();
      } catch (err) {
        const defaultErr = new Error(`Por favor, verifique se os títulos das colunas da \nsua planilha conferem com os da planilha modelo \ne envie novamente.`);
        defaultErr.name = 'Planilha com divergência';
        reject(defaultErr);
      }
    };
  });
  return promise;
}

export function bytesToMegaBytes(bytes: number): number {
  return bytes / (1024 * 1024);
}

export function dateFormattedDDMMYYYY(date: Date) {

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${day}/${month}/${year}`;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function formatDateFromPTBRToENUS(date: string) {
  return date.split('/').reverse().join('-');
}

export function paginate(array: any[], page_size: number, page_number: number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function calculateAge(dateOfBirth: string) {
  const [day, month, year] = dateOfBirth.split('/').map(Number);

  const formattedDateOfBirth = new Date(year, month - 1, day);
  const today = new Date();
  const ageDifference = today.getFullYear() - formattedDateOfBirth.getFullYear();


  const hasBirthdayOccurred = today.getMonth() < formattedDateOfBirth.getMonth()
    || (today.getMonth() === formattedDateOfBirth.getMonth()
      && today.getDate() < formattedDateOfBirth.getDate());

  return hasBirthdayOccurred ? ageDifference - 1 : ageDifference;
}

export function getFilterParams(filterOptions: any) {
  let params = new HttpParams();
  Object.keys(filterOptions).forEach(option => {
    const item = filterOptions[option];
    if (item != null && item != undefined && item != 'null' && item != 'undefined') {
      params = params.set(option, option == 'filter' || item instanceof Array ? JSON.stringify(item) : item);
    }
  });

  return params;
}



