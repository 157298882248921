<table class="table table-bordered" fxLayout="column">
  <thead class="custom-header-bordered" [hidden]="!dataSource.length && !loading">
    <tr>
      <th fxFlex="30" fxLayout="column" fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span class="header-title bold p3">Protocolo</span>
      </th>
      <th class="no-side-padding" fxFlex="30" fxLayout="column" fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span class="header-title bold p3">Assunto</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span class="header-title bold p3">Aberto em</span>
      </th>
      <th class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="1vmin" fxLayoutAlign="start start">
        <span class="header-title bold p3">Situação</span>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="dataSource.length && !loading;else noResultsFound">
    <tr class="loaded-table-row"
      [ngClass]="{'status-cancel-request': row.dateCancelRequest && actionMode == collaboratorTableMode.DELETE ? true : false}"
      *ngFor="let row of dataSource; let i = index" (click)="rowClicked(row.protocol)">
      <td class="custom-border-left" fxFlex="30" fxLayout="column" fxLayoutGap="1.5vmin" fxLayoutAlign="start start">
        <span class="name-title semi-bold p3">{{row.protocol | titlecase}}</span>
      </td>
      <td class="no-side-padding" fxFlex="30" fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start start">
        <span class="regular p3">{{row.description}}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start start">
        <span class="regular p3">{{row.registrationDate}}</span>
      </td>
      <td class="no-side-padding" fxFlex fxLayout="row" fxLayoutGap="3.65vmin" fxLayoutAlign="start start">
        <span class="regular p3" [style.color]="situationTextColor(row.situation)">{{row.situation}}</span>
      </td>
    </tr>
  </tbody>
  <tbody [hidden]="!loading">
    <tr *ngFor="let i of [].constructor(maxRowsLoaded)">
      <td fxFlex="30" class="custom-border-left" fxLayout="column">
        <ngx-skeleton-loader [theme]="skeletonTheme"></ngx-skeleton-loader>
      </td>
      <td fxFlex="30" class="no-side-padding">
        <ngx-skeleton-loader [theme]="skeletonTheme"></ngx-skeleton-loader>
      </td>
      <td fxFlex>
        <ngx-skeleton-loader [theme]="skeletonTheme"></ngx-skeleton-loader>

      </td>
      <td fxFlex>
        <ngx-skeleton-loader [theme]="skeletonTheme"></ngx-skeleton-loader>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #noResultsFound>
  <div class="no-data-found" *ngIf="!dataSource.length && searchString && !loading;else noDataRegisteredYet" fxFlex
    fxLayoutAlign="start center">
    <p class="p3 regular label-no-data-found"> Não há resultados para
      ”<strong>{{searchString}}</strong>”.</p>
  </div>
</ng-template>

<ng-template #noDataRegisteredYet>
  <div *ngIf="!dataSource.length && !searchString && !loading;" class="no-data-registered" fxFlex
    fxLayoutAlign="center center">
    <p class="label-no-data-registered p2 semi-bold">Ainda não há chamados abertos, em andamento ou finalizados.</p>
  </div>
</ng-template>
