import { EstrategiaDeValidacao } from '../../../interfaces/EstrategiaValidacao';
import { BuscarPlanoModel } from '../../../models/buscar-plano.model';

export class EstrategiaEmpresa {
  private estrategias: EstrategiaDeValidacao<BuscarPlanoModel>[];

  constructor(estrategias: EstrategiaDeValidacao<BuscarPlanoModel>[]) {
    this.estrategias = estrategias;
  }

  validar(empresa: BuscarPlanoModel): boolean {
    return this.estrategias.every((estrategia) => estrategia.validar(empresa));
  }
}
