<div
  class="position-logo"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <svg-icon src="../../../../assets/svgs/attention-modal-icon.svg"></svg-icon>
</div>
<div class="margin-content">
  <p class="bold text-center">Aviso</p>
  <div class="description-container pt-3">
    <div class="p3 regular padding-bottom text-center">
      <div *ngFor="let line of message" class="line">
        {{ line }}
      </div>
    </div>
  </div>
  <default-button
    (onClick)="onClick()"
    [disabled]="false"
    label="Marcar como lido"
  ></default-button>
</div>
