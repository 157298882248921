import { paginate } from 'src/app/utils/utils';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DegreeOfKinship } from '../enums/degree-of-kinship';
import { CctPlan } from '../models/cct-plan.model';
import { CollaboratorInclusionBySpreadsheet } from '../models/collaborator-inclusion-by-spreadsheet';
import { S3Object } from '../models/s3.model';
import { JwtTokenService } from './auth/jwt-token.service';
import { BuscarPlanoModel } from '../models/buscar-plano.model';
import { BeneficiaryType } from '../enums/beneficiary-type';
import { ICollaboratorCards } from '../models/collaborator-card.model';

export interface NewCollaborator {
  idCompany: number;
  cpf: string;
  registration?: string;
  name: string;
  motherName: string;
  birthday: string;
  gender: string;
  email: string;
  cellphone: string;
  phone?: string;
  accrualDates: Date;
}

export interface Paginacao<T>{
  count: number,
  rows: T[]
}

@Injectable({
  providedIn: 'root',
})
export class CollaboratorsService {
  private readonly COLLABORATOR_URL = environment.apiUrl + '/api/collaborators';

  constructor(
    public http: HttpClient,
    public jwtTokenService: JwtTokenService
  ) {}

  // TODO: Necessita reintegrar esse endpoint.
  searchCollaboratorsByIdAndFilter(
    companyId: number,
    filter: string,
    page: number,
    offset: number
  ) {
    return this.http
      .post(
        this.COLLABORATOR_URL + '/company/' + companyId + '/cards',
        {
          filter,
          page,
          offset,
        },
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  getCollaboratorCardByType(type: string, codBeneficiary: string) {
    return this.http
      .get(
        this.COLLABORATOR_URL + '/cards/' + type + '/code/' + codBeneficiary,
        {
          responseType: 'arraybuffer',
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
      .toPromise();
  }

  searchCollaboratorsGeneralAndCancelInformationByIdAndFilter(
    companyId: number,
    filter: any,
    page: number,
    offset: number
  ) {
    return firstValueFrom(
      this.http.post<Paginacao<ICollaboratorCards>>(
        this.COLLABORATOR_URL +
          '/company/' +
          companyId +
          '/general-information',
        {
          filter,
          page,
          offset,
        },
        this.jwtTokenService.getHeaderAuth()
      )
    );
  }

  createNewCollaborator(newCollaborator: NewCollaborator) {
    return this.http
      .post(
        this.COLLABORATOR_URL,
        newCollaborator,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  getCollaboratorInfoByCompanyAndAssociatedAndBeneficiary(
    companyId: number,
    associatedId: number,
    beneficiaryId: number
  ) {
    return this.http
      .get(
        `${this.COLLABORATOR_URL}/company/${companyId}/associated/${associatedId}/beneficiary/${beneficiaryId}`,
        {
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
      .toPromise();
  }

  editContactsByAssociated(associatedId: number, contactBody: any) {
    return this.http
      .put(
        `${this.COLLABORATOR_URL}/edit-contact/associated/${associatedId}`,
        contactBody,
        this.jwtTokenService.getHeaderAuth()
      )
      .toPromise();
  }

  collaboratorInclusionBySpreadsheet(
    companyId: number,
    body: CollaboratorInclusionBySpreadsheet
  ) {
    return firstValueFrom(
      this.http.post(
        `${this.COLLABORATOR_URL}/company/${companyId}/spreadsheet-inclusion`,
        body,
        this.jwtTokenService.getHeaderAuth()
      )
    );
  }

  collaboratorInclusionBySpreadsheetSE4(body: FormData) {
    return firstValueFrom(
      this.http.post<S3Object>(
        `${this.COLLABORATOR_URL}/registration-by-spreadsheet`,
        body,
        this.jwtTokenService.getHeaderAuth()
      )
    );
  }

  getActiveHolders(companyId: number, body: any) {
    return lastValueFrom(
      this.http.post(
        `${this.COLLABORATOR_URL}/company/${companyId}/active-holders`,
        body,
        this.jwtTokenService.getHeaderAuth()
      )
    );
  }

  getCctPlans(id: number, degreeOfKinship: DegreeOfKinship, age = 0) {
    const bond =
      degreeOfKinship == DegreeOfKinship.Holder
        ? BeneficiaryType.HOLDER.toLocaleLowerCase()
        : BeneficiaryType.DEPENDENT.toLocaleLowerCase();

    return firstValueFrom(
      this.http.get<CctPlan[]>(
        `${this.COLLABORATOR_URL}/company/${id}/plans/${bond}/${age}`,
        {
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
    );
  }

  getPlansByCompanyS4eId(companyId: number) {
    return firstValueFrom(
      this.http.get<BuscarPlanoModel>(
        `/api/collaborators/buscar-plano/${companyId}`
      )
    );
  }

  getCollaboratorAndDependentsList(collaboratorId: any) {
    return firstValueFrom(
      this.http.get(
        `${this.COLLABORATOR_URL}/company/dependents/${collaboratorId}`,
        {
          headers: this.jwtTokenService.getHeaderAuth().headers,
        }
      )
    );
  }
}
