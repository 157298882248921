import { BlipService } from './../../../services/blip.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-first-access',
  templateUrl: './modal-first-access.component.html',
  styleUrls: ['./modal-first-access.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFirstAccessComponent implements OnInit {

  mostrar: boolean = false;

  constructor(public activeModal: NgbActiveModal, private route: Router, private blipService: BlipService) {}

  ngOnInit(): void {
  }

  toggle() {
    this.mostrar = !this.mostrar;
  }
  onClick() {
    this.route.navigate(['login']);
    this.activeModal.close('Close click')
  }
  openBlip() {
    this.blipService.openBlip('Modal Primeiro Acesso')
    this.activeModal.close('Close click')
  }

}
