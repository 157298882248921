import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { ExtraButtonType } from '../../../enums/extra-button-type';
import { InvoiceStatus } from '../../../enums/invoice-status';
import { InvoiceInfo } from '../../../models/invoice-info.model';

@Component({
  selector: 'card-invoice-status-info',
  templateUrl: './card-invoice-status-info.component.html',
  styleUrls: ['./card-invoice-status-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardInvoiceStatusInfoComponent implements OnInit {
  readonly invoiceStatusEnum = InvoiceStatus;
  extraButtonType = ExtraButtonType;

  @Input() invoice: InvoiceInfo;  

  @Output() onSeeInvoiceClick:EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadNF:EventEmitter<any> = new EventEmitter<any>();
  @Output() onDownloadPaymentInvoice:EventEmitter<any> = new EventEmitter<any>();
  @Output() onEmitSecondInvoice:EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {}

  seeInvoice() {
    this.onSeeInvoiceClick.emit()
  }

  emitSecondInvoice(url: string) {
    this.onEmitSecondInvoice.emit(url)
  }

  downloadNF(url: string) {
    this.onDownloadNF.emit(url)
  }

  downloadPaymentInvoice(url : string) {
    this.onDownloadPaymentInvoice.emit(url)
  }

  invoiceStatusLabelByStatus(status: InvoiceStatus) {

    let label;
    switch (status) {
      case InvoiceStatus.WAITING_CLOSING:
        label = 'Aguardando fechamento';
        break;
      case InvoiceStatus.INVOICE_CLOSED:
        label = 'Fatura fechada';
        break;
      case InvoiceStatus.PAYMENT_NOT_MADE:
        label = 'Pagamento ainda não realizado';
        break;
      case InvoiceStatus.PAYMENT_MADE:
        label = 'Pagamento realizado';
        break;
      case InvoiceStatus.PAYMENT_DELAYED:
        label = 'Pagamento atrasado';
        break;
    }

    return label;
  }
}
