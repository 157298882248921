import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReviewTextAreaComponent } from '../modal-review-text-area/modal-review-text-area.component';
import { ReviewTypes } from 'src/app/enums/review-types';

export type ReviewType = 'FACE' | 'RADIO_GROUP'

@Component({
  selector: 'modal-review',
  templateUrl: './modal-review.component.html',
  styleUrls: ['./modal-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ModalReviewComponent implements OnInit {
  @Input() title: string;
  @Input() type: ReviewType = 'FACE';
  @Input() metricName: string;
  @Input() reviewType: ReviewTypes;

  reviewEmojiFormGroup: FormGroup = new FormGroup({
    reviewValue: new FormControl(null, [
      Validators.required,
    ]),
    reviewTextArea: new FormControl(null)
  });

  reviewRadioFormGroup: FormGroup = new FormGroup({
    reviewValuePaymentSlipDownload: new FormControl(null, [
      Validators.required,
    ]),
    reviewValueCheckInvoice: new FormControl(null, [
      Validators.required,
    ]),
    reviewValueInvoiceHistory: new FormControl(null, [
      Validators.required,
    ]),
    reviewTextArea: new FormControl(null)
  });

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  setReviewRadioValue(value: number, control: string) {
    this.reviewRadioFormGroup.get(control)?.setValue(value)
  }

  nextStep() {
    this.closeModal()
    if(this.type === 'FACE'){
      const modalRef = this.modalService.open(ModalReviewTextAreaComponent, {
        centered: true,
        windowClass: 'reviewModal',
      });
      modalRef.componentInstance.title = 'Algum comentário, sugestão ou reclamação<br />que você gostaria de compartilhar?';
      modalRef.componentInstance.formGroup = this.reviewEmojiFormGroup;
      modalRef.componentInstance.formControlNameTextArea = 'reviewTextArea';
      modalRef.componentInstance.type = 'FACE';
      modalRef.componentInstance.metricName = this.metricName;
      modalRef.componentInstance.reviewType = this.reviewType;
    }
    else {
      const modalRef = this.modalService.open(ModalReviewTextAreaComponent, {
        centered: true,
        windowClass: 'reviewModal',
      });
      modalRef.componentInstance.title = 'Algum comentário, sugestão ou reclamação<br />que você gostaria de compartilhar?';
      modalRef.componentInstance.formGroup = this.reviewRadioFormGroup;
      modalRef.componentInstance.formControlNameTextArea = 'reviewTextArea';
      modalRef.componentInstance.type = 'RADIO_GROUP';
      modalRef.componentInstance.metricName = this.metricName;
      modalRef.componentInstance.reviewType = this.reviewType;
    }
  }

  closeModal() {
    this.activeModal.close(true);
  }

  setReviewValue(value: number) {
    this.reviewEmojiFormGroup?.get('reviewValue')?.setValue(value)
  }
}
