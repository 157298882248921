<div class="nav nav-tabs nav-responsive-xl">
  <div
    class="nav-item"
    (click)="selectTab(item)"
    [class.active]="activeTabItem === item"
    [class.unselect]="activeTabItem !== item"
    *ngFor="let item of tabItems$ | async"
  >
    <ng-container *ngIf="item.labelComponent">
      <ng-container *ngTemplateOutlet="item.labelComponent.label"></ng-container>
    </ng-container>

    <ng-container *ngIf="!item.labelComponent">
      {{ item.label }}
    </ng-container>

    <div class="ink-bottom-tab active" [class.active]="activeTabItem === item"></div>

  </div>
</div>
<div class="tabs-body">
  <ng-container *ngIf="activeTabItem && activeTabItem.bodyComponent">
    <ng-container *ngTemplateOutlet="activeTabItem.bodyComponent.body">
    </ng-container>
  </ng-container>
</div>
